export interface IBudgetForecast {
  id?: number
  industry: string
  channel: string
  cpc: number
  ctr: number
  conversion_rate: number
  conversion_value: number
  conversion_number: number
  cart_value: number
  active: boolean
}

export const LIST_FILTERS = [
  {value: 'sort=industry', label: 'BUDGET_FORECASTS.FILTER.INDUSTRY_UP'},
  {value: 'sort=-industry', label: 'BUDGET_FORECASTS.FILTER.INDUSTRY_DOWN'},
]
