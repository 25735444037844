import { put, takeLatest, select } from 'redux-saga/effects'
import { createCountry, fetchCountries } from './CountryCRUD'
import { ICountry } from "../models/Country"
import { RootState } from "../../../../setup"
import axios from 'axios'
export const COUNTRY_REQUEST = 'COUNTRY_REQUEST'
export const COUNTRY_REQUEST_SUCCESS = 'COUNTRY_REQUEST_SUCCESS'
export const COUNTRY_REQUEST_FAIL = 'COUNTRY_REQUEST_FAIL'

export const COUNTRY_CREATE_REQUEST = 'COUNTRY_CREATE_REQUEST'
export const COUNTRY_CREATE_SUCCESS = 'COUNTRY_CREATE_SUCCESS'
export const COUNTRY_CREATE_FAIL = 'COUNTRY_CREATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const RESET_COUNTRY_ERROR = 'RESET_COUNTRY_ERROR'
export const RESET_COUNTRY_CREATION = 'RESET_COUNTRY_CREATION'

export interface ICountryState {
    countries?: ICountry[]
    countriesLoading: boolean
    countriesError: boolean
    createCountryLoading: boolean
    createCountryError: boolean
    createCountryErrorMessage?: string
    countryIsCreated: boolean
    currentPage: number
    perPage: number
}

const initialCountryState: ICountryState = {
    countries: [],
    countriesLoading: false,
    countriesError: false,
    createCountryLoading: false,
    createCountryError: false,
    countryIsCreated: false,
    currentPage: 1,
    perPage: 10,
    createCountryErrorMessage: undefined,
}

export const reducer = (
    state: ICountryState = initialCountryState,
    action: any
): ICountryState => {
    switch (action.type) {
        case RESET_COUNTRY_ERROR:
            return {
                ...state,
                createCountryError: false,
                createCountryErrorMessage: undefined,
            }
        case COUNTRY_REQUEST:
            return { ...state, countriesLoading: true }
        case COUNTRY_REQUEST_SUCCESS:
            return {
                ...state,
                countries: action.payload?.countries || [],
                countriesLoading: false,
                countriesError: false
            }
        case COUNTRY_REQUEST_FAIL:
            return { ...state, countriesLoading: false, countriesError: true }
        case COUNTRY_CREATE_REQUEST:
            return { ...state, createCountryLoading: true, createCountryError: false, createCountryErrorMessage: undefined }
        case COUNTRY_CREATE_SUCCESS:
            return { ...state, countryIsCreated: true, createCountryLoading: false }
        case RESET_COUNTRY_CREATION:
            return { ...state, countryIsCreated: false, createCountryError: false, createCountryLoading: false }
        case COUNTRY_CREATE_FAIL:
            return {
                ...state,
                createCountryLoading: false,
                createCountryError: true,
                createCountryErrorMessage: action.payload.errorMessage
            }
        case CHANGE_CURRENT_PAGE:
            return { ...state, currentPage: action.payload }
        case CHANGE_LIMIT_PER_PAGE:
            return { ...state, perPage: action.payload }
        default:
            return state
    }
}

export const actions = {
    requestCountries: () => ({ type: COUNTRY_REQUEST }),
    requestCountriesSuccess: (countries: ICountry[]) => ({
        type: COUNTRY_REQUEST_SUCCESS,
        payload: { countries },
    }),
    requestCountriesFail: () => ({ type: COUNTRY_REQUEST_FAIL }),
    createCountryRequest: (country: ICountry) => ({
        type: COUNTRY_CREATE_REQUEST,
        payload: { country },
    }),
    createCountrySuccess: () => ({ type: COUNTRY_CREATE_SUCCESS }),
    createCountryFail: (errorMessage: string) => ({
        type: COUNTRY_CREATE_FAIL,
        payload: { errorMessage },
    }),
    changeCurrentPage: (newPage: number) => ({
        type: CHANGE_CURRENT_PAGE,
        payload: newPage,
    }),
    changeLimitPerPage: (newLimit: number) => ({
        type: CHANGE_LIMIT_PER_PAGE,
        payload: newLimit,
    }),
    resetCountryCreation: () => ({ type: RESET_COUNTRY_CREATION }),
}

export const selectors = {
    getCountries: (state: RootState) => state.countries,
    getCurrentPage: (state: RootState) => state.countries.currentPage,
    getLimitPerPage: (state: RootState) => state.countries.perPage
}

function* listUpdate() {
    const page: number = yield select(selectors.getCurrentPage)
    const limit: number = yield select(selectors.getLimitPerPage)
    const { data } = yield fetchCountries(page, limit)
    return { data }
}

export function* saga() {
    yield takeLatest(COUNTRY_REQUEST, function* fetchCountriesSaga() {
        try {
            const { data } = yield listUpdate()
            yield put(actions.requestCountriesSuccess(data))
        } catch (error) {
            yield put(actions.requestCountriesFail())
        }
    })

    yield takeLatest(COUNTRY_CREATE_REQUEST, function* createCountrySaga(action: any) {
        try {
            yield createCountry(action.payload.country)
            yield put(actions.createCountrySuccess())
            yield put(actions.requestCountries())
        } catch (error: unknown) {
            let errorMessage = 'Unknown error occurred while adding country.'
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.message || error.message
            }
            yield put(actions.createCountryFail(errorMessage))
        }
    })
}
