import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IBudgetForecast} from '../models/BudgetForecast'
import {
  createBudgetForecast,
  deleteBudgetForecast,
  getBudgetForecastById,
  getBudgetForecastsList,
  updateBudgetForecast,
} from './BudgetForecastsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const BUDGET_FORECASTS_REQUEST = 'BUDGET_FORECASTS_REQUEST'
export const BUDGET_FORECASTS_REQUEST_SUCCESS = 'BUDGET_FORECASTS_REQUEST_SUCCESS'
export const BUDGET_FORECASTS_REQUEST_FAIL = 'BUDGET_FORECASTS_REQUEST_FAIL'

export const BUDGET_FORECAST_DELETE = 'BUDGET_FORECAST_DELETE'
export const BUDGET_FORECAST_DELETE_SUCCESS = 'BUDGET_FORECAST_DELETE_SUCCESS'
export const BUDGET_FORECAST_DELETE_FAIL = 'BUDGET_FORECAST_DELETE_ERROR'

export const BUDGET_FORECAST_CREATE_REQUEST = 'BUDGET_FORECAST_CREATE_REQUEST'
export const BUDGET_FORECAST_CREATE_SUCCESS = 'BUDGET_FORECAST_CREATE_SUCCESS'
export const BUDGET_FORECAST_CREATE_FAIL = 'BUDGET_FORECAST_CREATE_FAIL'

export const GET_BUDGET_FORECAST_TO_UPDATE = 'GET_BUDGET_FORECAST_TO_UPDATE'
export const GET_BUDGET_FORECAST_TO_UPDATE_REQUEST = 'GET_BUDGET_FORECAST_TO_UPDATE_REQUEST'
export const GET_BUDGET_FORECAST_TO_UPDATE_FAIL = 'GET_BUDGET_FORECAST_TO_UPDATE_FAIL'

export const PUT_BUDGET_FORECAST_UPDATE_REQUEST = 'PUT_BUDGET_FORECAST_UPDATE_REQUEST'
export const PUT_BUDGET_FORECAST_UPDATE_SUCCESS = 'PUT_BUDGET_FORECAST_UPDATE_SUCCESS'
export const PUT_BUDGET_FORECAST_UPDATE_FAIL = 'PUT_BUDGET_FORECAST_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IBudgetForecastsState {
  getBudgetForecasts?: IBudgetForecast[]
  getBudgetForecastsLoading: boolean
  getBudgetForecastsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create budgetForecast
  createNewBudgetForecast?: IBudgetForecast
  createBudgetForecastLoading: boolean
  createBudgetForecastError: boolean
  budgetForecastIsCreate: boolean
  createBudgetForecastErrorMessage?: string[][]
  //get budgetForecast to update
  getBudgetForecastLoading: boolean
  getBudgetForecastError: boolean
  getBudgetForecastToUpdateId?: number
  getBudgetForecastToUpdateData?: IBudgetForecast
  //budgetForecast update
  budgetForecastUpdateData?: IBudgetForecast
  budgetForecastUpdateLoading: boolean
  budgetForecastUpdateError: boolean
  budgetForecastIsUpdate: boolean
  budgetForecastUpdateErrorMessage?: string[][]
  //delete budgetForecast
  budgetForecastIdToDelete?: number
  budgetForecastDeleteError: boolean
}

const initialBudgetForecastsState: IBudgetForecastsState = {
  getBudgetForecasts: [],
  getBudgetForecastsLoading: false,
  getBudgetForecastsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  createNewBudgetForecast: undefined,
  createBudgetForecastLoading: false,
  createBudgetForecastError: false,
  budgetForecastIsCreate: false,
  createBudgetForecastErrorMessage: undefined,

  getBudgetForecastLoading: false,
  getBudgetForecastError: false,
  getBudgetForecastToUpdateId: undefined,
  getBudgetForecastToUpdateData: undefined,

  budgetForecastUpdateData: undefined,
  budgetForecastUpdateLoading: false,
  budgetForecastUpdateError: false,
  budgetForecastIsUpdate: false,
  budgetForecastUpdateErrorMessage: undefined,

  budgetForecastIdToDelete: undefined,
  budgetForecastDeleteError: false,
}

export const reducer = (
  state: IBudgetForecastsState = initialBudgetForecastsState,
  action: ActionWithPayload<IBudgetForecastsState>
) => {
  switch (action.type) {
    case BUDGET_FORECASTS_REQUEST:
      return {...state, getBudgetForecastsLoading: true}
    case BUDGET_FORECASTS_REQUEST_SUCCESS:
      const budgetForecast = action.payload?.getBudgetForecasts
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getBudgetForecasts: budgetForecast,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getBudgetForecastsLoading: false,
        getBudgetForecastsError: false,
      }
    case BUDGET_FORECASTS_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const changeFilter = action.payload?.filter
      return {...state, filter: changeFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //budgetForecast delete
    case BUDGET_FORECAST_DELETE:
      const budgetForecastIdToDelete = action.payload?.budgetForecastIdToDelete
      return {...state, budgetForecastIdToDelete}
    case BUDGET_FORECAST_DELETE_SUCCESS:
      const listAfterDel = state.getBudgetForecasts?.filter(
        (item) => item.id !== action.payload?.budgetForecastIdToDelete
      )
      return {...state, getBudgetForecasts: listAfterDel}
    case BUDGET_FORECAST_DELETE_FAIL:
      return {...state, budgetForecastDeleteError: true}

    //budgetForecast create
    case BUDGET_FORECAST_CREATE_REQUEST:
      const createNewBudgetForecast = action.payload?.createNewBudgetForecast
      return {
        ...state,
        createBudgetForecastLoading: true,
        createNewBudgetForecast: createNewBudgetForecast,
        budgetForecastIsCreate: false,
        createBudgetForecastError: false,
      }
    case BUDGET_FORECAST_CREATE_SUCCESS:
      return {...state, createBudgetForecastLoading: false, budgetForecastIsCreate: true}
    case BUDGET_FORECAST_CREATE_FAIL:
      const budgetForecastErrorMessage = action.payload?.createBudgetForecastErrorMessage
      return {
        ...state,
        createBudgetForecastErrorMessage: budgetForecastErrorMessage,
        createBudgetForecastError: true,
        createBudgetForecastLoading: false,
        budgetForecastIsCreate: false,
      }
    //get budgetForecast to update
    case GET_BUDGET_FORECAST_TO_UPDATE:
      const budgetForecastIdToUpdate = action.payload?.getBudgetForecastToUpdateId
      return {
        ...state,
        getBudgetForecastToUpdateId: budgetForecastIdToUpdate,
        getBudgetForecastToUpdateData: undefined,
        getBudgetForecastLoading: true,
      }
    case GET_BUDGET_FORECAST_TO_UPDATE_REQUEST:
      const budgetForecastToUpdateData = action.payload?.getBudgetForecastToUpdateData
      return {
        ...state,
        getBudgetForecastToUpdateData: budgetForecastToUpdateData,
        getBudgetForecastLoading: false,
      }
    case GET_BUDGET_FORECAST_TO_UPDATE_FAIL:
      return {
        ...state,
        getBudgetForecastLoading: false,
        getBudgetForecastError: true,
      }
    //put budgetForecast update
    case PUT_BUDGET_FORECAST_UPDATE_REQUEST:
      const budgetForecastDataUpd = action.payload?.budgetForecastUpdateData
      return {
        ...state,
        budgetForecastUpdateLoading: true,
        budgetForecastUpdateData: budgetForecastDataUpd,
        budgetForecastIsUpdate: false,
        budgetForecastUpdateError: false,
      }
    case PUT_BUDGET_FORECAST_UPDATE_SUCCESS:
      return {
        ...state,
        budgetForecastUpdateLoading: false,
        budgetForecastIsUpdate: true,
      }
    case PUT_BUDGET_FORECAST_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.budgetForecastUpdateErrorMessage
      return {
        ...state,
        budgetForecastUpdateLoading: false,
        budgetForecastUpdateError: true,
        budgetForecastIsUpdate: false,
        budgetForecastUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestBudgetForecasts: () => ({type: BUDGET_FORECASTS_REQUEST}),
  requestBudgetForecastsSuccess: (
    budgetForecast: IBudgetForecast[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: BUDGET_FORECASTS_REQUEST_SUCCESS,
    payload: {getBudgetForecasts: budgetForecast, currentPage, totalPages, perPage, totalItem},
  }),

  requestBudgetForecastFail: () => ({type: BUDGET_FORECASTS_REQUEST_FAIL}),

  createBudgetForecastRequest: (createBudgetForecast: IBudgetForecast) => ({
    type: BUDGET_FORECAST_CREATE_REQUEST,
    payload: {createNewBudgetForecast: createBudgetForecast},
  }),

  createNewBudgetForecast: () => ({
    type: BUDGET_FORECAST_CREATE_SUCCESS,
  }),

  createBudgetForecastFail: (err: any) => ({
    type: BUDGET_FORECAST_CREATE_FAIL,
    payload: {createBudgetForecastErrorMessage: err},
  }),

  changeBudgetForecastToUpdateId: (budgetForecastId: number) => ({
    type: GET_BUDGET_FORECAST_TO_UPDATE,
    payload: {getBudgetForecastToUpdateId: budgetForecastId},
  }),

  requestBudgetForecastToUpdateData: (getBudgetForecastToUpdateData: IBudgetForecast) => ({
    type: GET_BUDGET_FORECAST_TO_UPDATE_REQUEST,
    payload: {getBudgetForecastToUpdateData},
  }),

  requestBudgetForecastToUpdateDataFail: () => ({
    type: GET_BUDGET_FORECAST_TO_UPDATE_FAIL,
  }),

  updateBudgetForecastData: (budgetForecastData: IBudgetForecast) => ({
    type: PUT_BUDGET_FORECAST_UPDATE_REQUEST,
    payload: {budgetForecastUpdateData: budgetForecastData},
  }),

  updateBudgetForecastDataSuccess: () => ({
    type: PUT_BUDGET_FORECAST_UPDATE_SUCCESS,
  }),

  requestBudgetForecastToUpdateFail: (err: any) => ({
    type: PUT_BUDGET_FORECAST_UPDATE_FAIL,
    payload: {budgetForecastUpdateErrorMessage: err},
  }),

  budgetForecastToDelete: (budgetForecastToDelete: number) => ({
    type: BUDGET_FORECAST_DELETE,
    payload: {budgetForecastIdToDelete: budgetForecastToDelete},
  }),

  budgetForecastToDeleteSuccces: (budgetForecastIdToDelete: number) => ({
    type: BUDGET_FORECAST_DELETE_SUCCESS,
    payload: {budgetForecastIdToDelete},
  }),

  budgetForecastToDeleteFail: () => ({type: BUDGET_FORECAST_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getBudgetForecasts: (state: RootState) => state.budgetForecast,
  getCurrentPage: (state: RootState) => state.budgetForecast.currentPage,
  getLimitPerPage: (state: RootState) => state.budgetForecast.perPage,
  getFilter: (state: RootState) => state.budgetForecast.filter,
  getTotalItem: (state: RootState) => state.budgetForecast.totalItem,
  getSearchText: (state: RootState) => state.budgetForecast.searchText,
  getBudgetForecastIDToDelete: (state: RootState) => state.budgetForecast.budgetForecastIdToDelete,
  getBudgetForecastToCreate: (state: RootState) => state.budgetForecast.createNewBudgetForecast,
  getCreateBudgetForecastData: (state: RootState) => {
    const {
      createBudgetForecastLoading,
      createBudgetForecastError,
      createBudgetForecastErrorMessage,
      budgetForecastIsCreate,
    } = state.budgetForecast
    return {
      createBudgetForecastLoading,
      createBudgetForecastError,
      createBudgetForecastErrorMessage,
      budgetForecastIsCreate,
    }
  },
  getUpdateBudgetForecastData: (state: RootState) => {
    const {
      budgetForecastUpdateLoading,
      budgetForecastUpdateError,
      budgetForecastUpdateErrorMessage,
      budgetForecastIsUpdate,
    } = state.budgetForecast
    return {
      budgetForecastUpdateLoading,
      budgetForecastUpdateError,
      budgetForecastUpdateErrorMessage,
      budgetForecastIsUpdate,
    }
  },
  getBudgetForecastToUpdateId: (state: RootState) =>
    state.budgetForecast.getBudgetForecastToUpdateId,
  getBudgetForecastToUpdateData: (state: RootState) =>
    state.budgetForecast.getBudgetForecastToUpdateData,
  getBudgetForecastDataToUpdate: (state: RootState) =>
    state.budgetForecast.budgetForecastUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getBudgetForecastsList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(BUDGET_FORECASTS_REQUEST, function* getBudgetForecastsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestBudgetForecastsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestBudgetForecastFail())
    }
  })

  yield takeLatest(BUDGET_FORECAST_DELETE, function* deleteBudgetForecastSaga() {
    try {
      const delBudgetForecast: number = yield select(selectors.getBudgetForecastIDToDelete)
      yield deleteBudgetForecast(delBudgetForecast)
      yield put(actions.budgetForecastToDeleteSuccces(delBudgetForecast))
    } catch (error) {
      yield put(actions.budgetForecastToDeleteFail())
    }
  })

  yield takeLatest(BUDGET_FORECAST_CREATE_REQUEST, function* createBudgetForecastSaga() {
    const newBudgetForecast: IBudgetForecast = yield select(selectors.getBudgetForecastToCreate)
    const {res, err} = yield createBudgetForecast(newBudgetForecast)
    if (res) {
      yield put(actions.createNewBudgetForecast())
      const {data} = yield listUpdate()
      yield put(
        actions.requestBudgetForecastsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createBudgetForecastFail(errResArray))
      } else {
        yield put(actions.createBudgetForecastFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_BUDGET_FORECAST_TO_UPDATE, function* getUpdateBudgetForecastSaga() {
    try {
      const updBudgetForecastId: number = yield select(selectors.getBudgetForecastToUpdateId)
      const {data} = yield getBudgetForecastById(updBudgetForecastId)
      yield put(actions.requestBudgetForecastToUpdateData(data))
    } catch (err) {
      yield put(actions.requestBudgetForecastToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_BUDGET_FORECAST_UPDATE_REQUEST, function* updateBudgetForecastSaga() {
    const budgetForecast: IBudgetForecast = yield select(selectors.getBudgetForecastDataToUpdate)
    const {res, err} = yield updateBudgetForecast(budgetForecast)
    if (res) {
      yield put(actions.updateBudgetForecastDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestBudgetForecastsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestBudgetForecastToUpdateFail(errResArray))
      } else {
        yield put(actions.requestBudgetForecastToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
