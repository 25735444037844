import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {ActionWithPayload, RootState} from '../../../../setup/redux/RootReducer'
import {login} from './AuthCRUD'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT = 'LOGOUT'
export const USER_LOAD = 'USER_LOAD'

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  loading: false,
  error: false,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  loading: boolean
  error: boolean
}

export const reducer = persistReducer(
  {storage, key: 'auth-token-data', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case LOGIN_REQUEST:
        return {...state, loading: true}

      case LOGIN_SUCCESS:
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        return {...state, loading: false, accessToken, user}

      case LOGIN_FAIL:
        return {...state, loading: false, error: true}

      case LOGOUT: {
        return {
          user: undefined,
          token: undefined,
          loading: false,
          error: false,
        }
      }

      case USER_LOAD: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const selectors = {
  getAuthState: (state: RootState) => state.auth,
  getUser: (state: RootState) => state.auth.user,
}

export const actions = {
  login: (email: string, password: string) => ({type: LOGIN_REQUEST, payload: {email, password}}),

  loginSuccess: (accessToken: string, user: UserModel) => ({
    type: LOGIN_SUCCESS,
    payload: {accessToken, user},
  }),

  loginFail: () => ({type: LOGIN_FAIL}),

  logout: () => ({type: LOGOUT}),

  fulfillUser: (user: UserModel) => ({type: USER_LOAD, payload: {user}}),
}

interface LoginActionType {
  type: string
  payload: {
    email: string
    password: string
  }
}

export function* saga() {
  yield takeLatest(LOGIN_REQUEST, function* loginSaga(reducer: LoginActionType) {
    try {
      const {email, password} = reducer.payload
      const {
        data: {token, user},
      } = yield login(email, password)
      yield put(actions.loginSuccess(token, user))
    } catch (err) {
      yield put(actions.loginFail())
    }
  })
}
