import {put, select, takeLatest} from '@redux-saga/core/effects'
import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IUser, IUserWithPassword} from '../models/User'
import {deleteUser, getUsersList, createUser, getUserById, updateUser} from '../redux/UsersCRUD'

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_REQUEST_SUCCESS = 'USERS_REQUEST_SUCCESS'
export const USERS_REQUEST_FAIL = 'USERS_REQUEST_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export const USER_DELETE = 'USER_DELETE'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_ERROR'

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'

export const GET_USER_TO_UPDATE = 'GET_USER_TO_UPDATE'
export const GET_USER_TO_UPDATE_REQUEST = 'GET_USER_TO_UPDATE_REQUEST'
export const GET_USER_TO_UPDATE_FAIL = 'GET_USER_TO_UPDATE_FAIL'

export const PUT_USER_UPDATE_REQUEST = 'PUT_USER_UPDATE_REQUEST'
export const PUT_USER_UPDATE_SUCCESS = 'PUT_USER_UPDATE_SUCCESS'
export const PUT_USER_UPDATE_FAIL = 'PUT_USER_UPDATE_FAIL'

export const SET_SEARCH_TEXT_SUCCESS = 'SET_SEARCH_TEXT_SUCCESS'
export const SET_SEARCH_TEXT_FAIL = 'SET_SEARCH_TEXT_FAIL'

export interface IUsersState {
  //get all users
  getUsers?: IUser[]
  getUsersLoading: boolean
  getUsersError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create user
  createNewUser?: IUserWithPassword
  createUserLoading: boolean
  createUserError: boolean
  userIsCreate: boolean
  createUserErrorMessage?: string[][]
  //delete user
  userIdToDelete?: number
  userDeleteError: boolean
  //get user to update
  getUserLoading: boolean
  getUserError: boolean
  getUserToUpdateId?: number
  getUserToUpdateData?: IUserWithPassword
  //user update
  userUpdateData?: IUserWithPassword
  userUpdateLoading: boolean
  userUpdateError: boolean
  userIsUpdate: boolean
  userUpdateErrorMessage?: string[][]
}

const initialUsersState: IUsersState = {
  getUsers: [],
  getUsersLoading: false,
  getUsersError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'last_name',
  totalItem: 0,
  searchText: undefined,

  createNewUser: undefined,
  createUserLoading: false,
  createUserError: false,
  userIsCreate: false,
  createUserErrorMessage: undefined,

  userIdToDelete: undefined,
  userDeleteError: false,

  getUserLoading: false,
  getUserError: false,
  getUserToUpdateId: undefined,
  getUserToUpdateData: undefined,

  userUpdateData: undefined,
  userUpdateLoading: false,
  userUpdateError: false,
  userIsUpdate: false,
  userUpdateErrorMessage: undefined,
}

export const reducer = (
  state: IUsersState = initialUsersState,
  action: ActionWithPayload<IUsersState>
) => {
  switch (action.type) {
    //get user list
    case USERS_REQUEST:
      return {...state, getUsersLoading: true}
    case USERS_REQUEST_SUCCESS:
      const users = action.payload?.getUsers
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getUsers: users,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getUsersLoading: false,
        getUsersError: false,
        userIsCreate: false,
        userIsUpdate: false,
        userUpdateError: false,
      }
    case USERS_REQUEST_FAIL:
      return {...state, error: true}
    // user list filters
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //user delete
    case USER_DELETE:
      const userIdToDelete = action.payload?.userIdToDelete
      return {...state, userIdToDelete}
    case USER_DELETE_SUCCESS:
      const listAfterDel = state.getUsers?.filter(
        (item) => item.id !== action.payload?.userIdToDelete
      )
      return {...state, getUsers: listAfterDel}
    case USER_DELETE_FAIL:
      return {...state, userDeleteError: true}
    //user create
    case USER_CREATE_REQUEST:
      const createNewUser = action.payload?.createNewUser
      return {
        ...state,
        createUserLoading: true,
        createNewUser: createNewUser,
        userIsCreate: false,
        createUserError: false,
      }
    case USER_CREATE_SUCCESS:
      return {...state, createUserLoading: false, userIsCreate: true}
    case USER_CREATE_FAIL:
      const userErrorMessage = action.payload?.createUserErrorMessage
      return {
        ...state,
        createUserErrorMessage: userErrorMessage,
        createUserError: true,
        createUserLoading: false,
        userIsCreate: false,
      }
    //get user to update
    case GET_USER_TO_UPDATE:
      const userIdToUpdate = action.payload?.getUserToUpdateId
      return {
        ...state,
        getUserToUpdateId: userIdToUpdate,
        getUserToUpdateData: undefined,
        getUserLoading: true,
      }
    case GET_USER_TO_UPDATE_REQUEST:
      const userToUpdateData = action.payload?.getUserToUpdateData
      return {
        ...state,
        getUserToUpdateData: userToUpdateData,
        getUserLoading: false,
      }
    case GET_USER_TO_UPDATE_FAIL:
      return {
        ...state,
        getUserLoading: false,
        getUserError: true,
      }
    //put user update
    case PUT_USER_UPDATE_REQUEST:
      const userDataUpd = action.payload?.userUpdateData
      return {
        ...state,
        userUpdateLoading: true,
        userUpdateData: userDataUpd,
        userIsUpdate: false,
        userUpdateError: false,
      }
    case PUT_USER_UPDATE_SUCCESS:
      return {
        ...state,
        userUpdateLoading: false,
        userIsUpdate: true,
      }
    case PUT_USER_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.userUpdateErrorMessage
      return {
        ...state,
        userUpdateLoading: false,
        userUpdateError: true,
        userIsUpdate: false,
        userUpdateErrorMessage: errorUpdMessage,
      }
    default:
      return state
  }
}

export const actions = {
  requestUsers: () => ({type: USERS_REQUEST}),

  requestUsersSuccess: (
    users: IUser[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: USERS_REQUEST_SUCCESS,
    payload: {getUsers: users, currentPage, totalPages, perPage, totalItem},
  }),

  requestUserFail: () => ({type: USERS_REQUEST_FAIL}),

  userToDelete: (userToDelete: number) => ({
    type: USER_DELETE,
    payload: {userIdToDelete: userToDelete},
  }),

  userToDeleteSuccces: (userIdToDelete: number) => ({
    type: USER_DELETE_SUCCESS,
    payload: {userIdToDelete},
  }),

  userToDeleteFail: () => ({type: USER_DELETE_FAIL}),

  createUserRequest: (createUser: IUserWithPassword) => ({
    type: USER_CREATE_REQUEST,
    payload: {createNewUser: createUser},
  }),

  createNewUser: () => ({
    type: USER_CREATE_SUCCESS,
  }),

  createUserFail: (err: any) => ({type: USER_CREATE_FAIL, payload: {createUserErrorMessage: err}}),

  changeUserToUpdateId: (userId: number) => ({
    type: GET_USER_TO_UPDATE,
    payload: {getUserToUpdateId: userId},
  }),

  requestUserToUpdateData: (getUserToUpdateData: IUserWithPassword) => ({
    type: GET_USER_TO_UPDATE_REQUEST,
    payload: {getUserToUpdateData},
  }),

  requestUserToUpdateDataFail: () => ({
    type: GET_USER_TO_UPDATE_FAIL,
  }),

  updateUserData: (userData: IUserWithPassword) => ({
    type: PUT_USER_UPDATE_REQUEST,
    payload: {userUpdateData: userData},
  }),

  updateUserDataSuccess: () => ({
    type: PUT_USER_UPDATE_SUCCESS,
  }),

  requestUserToUpdateFail: (err: any) => ({
    type: PUT_USER_UPDATE_FAIL,
    payload: {userUpdateErrorMessage: err},
  }),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getUsers: (state: RootState) => state.users,
  getCurrentPage: (state: RootState) => state.users.currentPage,
  getLimitPerPage: (state: RootState) => state.users.perPage,
  getFilter: (state: RootState) => state.users.filter,
  getTotalItem: (state: RootState) => state.users.totalItem,
  getSearchText: (state: RootState) => state.users.searchText,
  getUserIDToDelete: (state: RootState) => state.users.userIdToDelete,
  getUserToCreate: (state: RootState) => state.users.createNewUser,
  getCreateUserData: (state: RootState) => {
    const {createUserLoading, createUserError, createUserErrorMessage, userIsCreate} = state.users
    return {createUserLoading, createUserError, createUserErrorMessage, userIsCreate}
  },
  getUpdateUserData: (state: RootState) => {
    const {userUpdateLoading, userUpdateError, userUpdateErrorMessage, userIsUpdate} = state.users
    return {userUpdateLoading, userUpdateError, userUpdateErrorMessage, userIsUpdate}
  },
  getUserToUpdateId: (state: RootState) => state.users.getUserToUpdateId,
  getUserToUpdateData: (state: RootState) => state.users.getUserToUpdateData,
  getUserDataToUpdate: (state: RootState) => state.users.userUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getUsersList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(USERS_REQUEST, function* getUsersSaga() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestUsersSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestUserFail())
    }
  })

  yield takeLatest(USER_DELETE, function* deleteUserSaga() {
    try {
      const delUser: number = yield select(selectors.getUserIDToDelete)
      yield deleteUser(delUser)
      yield put(actions.userToDeleteSuccces(delUser))
    } catch (error) {
      yield put(actions.userToDeleteFail())
    }
  })

  yield takeLatest(USER_CREATE_REQUEST, function* createUserSaga() {
    const newUser: IUserWithPassword = yield select(selectors.getUserToCreate)
    const {res, err} = yield createUser(newUser)
    if (res) {
      yield put(actions.createNewUser())
      const {data} = yield listUpdate()
      yield put(
        actions.requestUsersSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createUserFail(errResArray))
      } else {
        yield put(actions.createUserFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_USER_TO_UPDATE, function* getUpdateUserSaga() {
    try {
      const updUserId: number = yield select(selectors.getUserToUpdateId)
      const {data} = yield getUserById(updUserId)
      yield put(actions.requestUserToUpdateData(data))
    } catch (err) {
      yield put(actions.requestUserToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_USER_UPDATE_REQUEST, function* updateUserSaga() {
    const user: IUserWithPassword = yield select(selectors.getUserDataToUpdate)
    const {res, err} = yield updateUser(user)
    if (res) {
      yield put(actions.updateUserDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestUsersSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestUserToUpdateFail(errResArray))
      } else {
        yield put(actions.requestUserToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
