import React, {useContext, useEffect, useState} from 'react'
import {Formik, FormikValues} from 'formik'
import {IBudgetForecast} from '../models/BudgetForecast'
import {validationEditSchema, initialValues} from '../models/BudgetForecastFormEdit'
import {useDispatch, useSelector} from 'react-redux'
import {actions, selectors} from '../redux/BudgetForecastsRedux'
import {useIntl} from 'react-intl'
import {AlertContext} from '../../../../_metronic/layout/core/AlertContext'

interface EditBudgetForecastFormProps {
  closeModal: () => void
  budgetForecastId: number
}

const EditBudgetForecastForm: React.FC<EditBudgetForecastFormProps> = ({
                                                                         closeModal,
                                                                         budgetForecastId,
                                                                       }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {showAlert} = useContext(AlertContext)
  const budgetForecastToUpdate = useSelector(selectors.getBudgetForecastToUpdateData)
  const {
    budgetForecastUpdateLoading,
    budgetForecastUpdateError,
    budgetForecastUpdateErrorMessage,
    budgetForecastIsUpdate,
  } = useSelector(selectors.getUpdateBudgetForecastData)
  const [data, setData] = useState<IBudgetForecast>(initialValues)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [isAlert, setIsAlert] = useState<boolean>(false)

  useEffect(() => {
    dispatch(actions.changeBudgetForecastToUpdateId(budgetForecastId))
  }, [budgetForecastId, dispatch])

  useEffect(() => {
    if (budgetForecastToUpdate) {
      const updateData = {...initialValues, ...budgetForecastToUpdate}
      updateData.active = Boolean(updateData.active)
      setData(updateData)
      setLoadingData(true)
    }
  }, [budgetForecastToUpdate])

  const onSubmit = (values: FormikValues) => {
    const updatedData = {...data, ...values}
    setData(updatedData)
    dispatch(actions.updateBudgetForecastData(updatedData))
    setIsAlert(true)
  }

  useEffect(() => {
    if (budgetForecastUpdateError && isAlert) {
      if (budgetForecastUpdateErrorMessage)
        showAlert(budgetForecastUpdateErrorMessage, 'danger', true)
      else showAlert([['Error']], 'danger', true)
      setIsAlert(false)
    } else if (!budgetForecastUpdateError && budgetForecastIsUpdate && isAlert) {
      showAlert([[]], 'success', true)
      closeModal()
    }
  }, [
    budgetForecastUpdateError,
    budgetForecastIsUpdate,
    isAlert,
    showAlert,
    closeModal,
    budgetForecastUpdateErrorMessage,
  ])

  return (
      <>
        {loadingData && (
            <Formik initialValues={data} validationSchema={validationEditSchema} onSubmit={onSubmit}>
              {({handleSubmit, getFieldProps, errors, touched}) => (
                  <div className='card mb-5 mb-xl-10'>
                    <div id='kt_account_profile_details' className='collapse show'>
                      <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.INDUSTRY'})}
                            </label>
                            <div className='col-lg-8'>
                              <div className='row'>
                                <div className='col-lg-6 fv-row'>
                                  <input
                                      type='text'
                                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                      placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.INDUSTRY'})}
                                      {...getFieldProps('industry')}
                                  />
                                  {touched.industry && errors.industry && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          {intl.formatMessage({id: errors.industry})}
                                        </div>
                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL'})}
                            </label>
                            <div className='col-lg-6 fv-row'>
                              <select
                                  className='form-select form-select-solid form-select-lg fw-bold'
                                  {...getFieldProps('channel')}
                              >
                                <option value=''>
                                  {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL'})}
                                </option>
                                <option value='google'>
                                  {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL.GOOGLE'})}
                                </option>
                                <option value='bing'>
                                  {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL.BING'})}
                                </option>
                              </select>
                              {touched.channel && errors.channel && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.channel})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CPC'})}
                            </label>
                            <div className='col-lg-2 fv-row'>
                              <input
                                  type='number'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CPC'})}
                                  {...getFieldProps('cpc')}
                              />
                              {touched.cpc && errors.cpc && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.cpc})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CTR'})}
                            </label>
                            <div className='col-lg-2 fv-row'>
                              <input
                                  type='number'
                                  step='0.01'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CTR'})}
                                  {...getFieldProps('ctr')}
                              />
                              {touched.ctr && errors.ctr && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.ctr})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_RATE'})}
                            </label>
                            <div className='col-lg-2 fv-row'>
                              <input
                                  type='number'
                                  step='0.01'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder={intl.formatMessage({
                                    id: 'BUDGET_FORECASTS.CONVERSION_RATE',
                                  })}
                                  {...getFieldProps('conversion_rate')}
                              />
                              {touched.conversion_rate && errors.conversion_rate && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.conversion_rate})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_VALUE'})}
                            </label>
                            <div className='col-lg-2 fv-row'>
                              <input
                                  type='number'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder={intl.formatMessage({
                                    id: 'BUDGET_FORECASTS.CONVERSION_VALUE',
                                  })}
                                  {...getFieldProps('conversion_value')}
                              />
                              {touched.conversion_value && errors.conversion_value && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.conversion_value})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_NUMBER'})}
                            </label>
                            <div className='col-lg-2 fv-row'>
                              <input
                                  type='number'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder={intl.formatMessage({
                                    id: 'BUDGET_FORECASTS.CONVERSION_NUMBER',
                                  })}
                                  {...getFieldProps('conversion_number')}
                              />
                              {touched.conversion_number && errors.conversion_number && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.conversion_number})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                              {intl.formatMessage({id: 'BUDGET_FORECASTS.CART_VALUE'})}
                            </label>
                            <div className='col-lg-2 fv-row'>
                              <input
                                  type='number'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CART_VALUE'})}
                                  {...getFieldProps('cart_value')}
                              />
                              {touched.cart_value && errors.cart_value && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {intl.formatMessage({id: errors.cart_value})}
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                          <button
                              type='submit'
                              className='btn btn-primary me-4'
                              disabled={budgetForecastUpdateLoading}
                          >
                            {!budgetForecastUpdateLoading && intl.formatMessage({id: 'EDIT'})}
                            {budgetForecastUpdateLoading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'WAIT'})}{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                            )}
                          </button>
                          <button
                              type='button'
                              className='btn btn-secondary'
                              onClick={() => {
                                closeModal()
                              }}
                          >
                            {intl.formatMessage({id: 'CLOSE'})}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
              )}
            </Formik>
        )}
      </>
  )
}

export default EditBudgetForecastForm
