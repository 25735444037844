import { IAttribute } from '../../models/Attribute'
import { ActionWithPayload, RootState } from '../../../../../setup'
import { createAttribute, getAttributesList, updateAttribute } from './AttributeCRUD'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'

export const ATTRIBUTE_REQUEST = 'ATTRIBUTE_REQUEST'
export const ATTRIBUTE_REQUEST_SUCCESS = 'ATTRIBUTE_REQUEST_SUCCESS'
export const ATTRIBUTE_REQUEST_FAIL = 'ATTRIBUTE_REQUEST_FAIL'
export const CHANGE_ATTRIBUTE_FILTER = 'CHANGE_ATTRIBUTE_FILTER'

export const ATTRIBUTE_CREATE_REQUEST = 'ATTRIBUTE_CREATE_REQUEST'
export const ATTRIBUTE_CREATE_SUCCESS = 'ATTRIBUTE_CREATE_SUCCESS'
export const ATTRIBUTE_CREATE_FAIL = 'ATTRIBUTE_CREATE_FAIL'

export const ATTRIBUTE_UPDATE_REQUEST = 'ATTRIBUTE_UPDATE_REQUEST'
export const ATTRIBUTE_UPDATE_SUCCESS = 'ATTRIBUTE_UPDATE_SUCCESS'
export const ATTRIBUTE_UPDATE_FAIL = 'ATTRIBUTE_UPDATE_FAIL'

export interface IAttributeState {
    attributes: IAttribute[]
    loading: boolean
    error: boolean
    filter: string
    createAttributeError?: boolean
    attributeIsCreated?: boolean
    updateAttributeError?: boolean
    attributeIsUpdated?: boolean
}

const initialState: IAttributeState = {
    attributes: [],
    loading: false,
    error: false,
    filter: 'sort=name',
    updateAttributeError: false,
    attributeIsUpdated: false,
}

export const reducer = (
    state: IAttributeState = initialState,
    action: ActionWithPayload<IAttributeState>
) => {
    switch (action.type) {
        case ATTRIBUTE_REQUEST:
            return { ...state, loading: true }
        case ATTRIBUTE_REQUEST_SUCCESS:
            return {
                ...state,
                attributes: action.payload?.attributes || [],
                loading: false,
                error: false,
            }
        case ATTRIBUTE_REQUEST_FAIL:
            return { ...state, loading: false, error: true }
        case CHANGE_ATTRIBUTE_FILTER:
            return { ...state, filter: action.payload?.filter || state.filter }
        case ATTRIBUTE_CREATE_REQUEST:
        case ATTRIBUTE_UPDATE_REQUEST:
            return { ...state, loading: true }
        case ATTRIBUTE_CREATE_SUCCESS:
        case ATTRIBUTE_UPDATE_SUCCESS:
            return { ...state, loading: false, error: false }
        case ATTRIBUTE_CREATE_FAIL:
        case ATTRIBUTE_UPDATE_FAIL:
            return { ...state, loading: false, error: true }
        case 'RESET_ATTRIBUTE_CREATION':
            return { ...state, createAttributeError: false, attributeIsCreated: false }
        case 'RESET_ATTRIBUTE_UPDATE':
            return { ...state, updateAttributeError: false, attributeIsUpdated: false }
        default:
            return state
    }
}

export const actions = {
    requestAttributes: () => ({ type: ATTRIBUTE_REQUEST }),
    requestAttributesSuccess: (attributes: IAttribute[]) => ({
        type: ATTRIBUTE_REQUEST_SUCCESS,
        payload: { attributes },
    }),
    requestAttributeFail: () => ({ type: ATTRIBUTE_REQUEST_FAIL }),

    changeAttributeFilter: (newFilter: string) => ({
        type: CHANGE_ATTRIBUTE_FILTER,
        payload: { filter: newFilter },
    }),

    createAttribute: (attribute: IAttribute) => ({
        type: ATTRIBUTE_CREATE_REQUEST,
        payload: attribute,
    }),
    createAttributeSuccess: () => ({ type: ATTRIBUTE_CREATE_SUCCESS }),
    createAttributeFail: () => ({ type: ATTRIBUTE_CREATE_FAIL }),

    updateAttribute: (id: number, attribute: Partial<IAttribute>) => ({
        type: ATTRIBUTE_UPDATE_REQUEST,
        payload: { id, attribute },
    }),
    updateAttributeSuccess: () => ({ type: ATTRIBUTE_UPDATE_SUCCESS }),
    updateAttributeFail: () => ({ type: ATTRIBUTE_UPDATE_FAIL }),
}

export const selectors = {
    getAttributes: (state: RootState) => state.attributes.attributes,
    getFilter: (state: RootState) => state.attributes.filter,
}

function* fetchAttributes(): SagaIterator {
    try {
        const filter: string = yield select(selectors.getFilter)

        const response = yield call(getAttributesList, filter)
        const data = response.data

        const attributesArray: IAttribute[] = data.data || data

        yield put(actions.requestAttributesSuccess(attributesArray))
    } catch (error) {
        yield put(actions.requestAttributeFail())
    }
}

function* createAttributeSaga(action: PayloadAction<IAttribute>): SagaIterator {
    if (!action.payload) return
    try {
        yield call(() => createAttribute(action.payload))
        yield put(actions.createAttributeSuccess())
        yield put(actions.requestAttributes())
    } catch (error) {
        yield put(actions.createAttributeFail())
    }
}

function* updateAttributeSaga(
    action: PayloadAction<{ id: number; attribute: Partial<IAttribute> }>
): SagaIterator {
    if (!action.payload || !action.payload.id || !action.payload.attribute) return
    try {
        yield call(() => updateAttribute(action.payload.id, action.payload.attribute))
        yield put(actions.updateAttributeSuccess())
        yield put(actions.requestAttributes())
    } catch (error) {
        yield put(actions.updateAttributeFail())
    }
}

export function* saga(): SagaIterator {
    yield takeLatest(ATTRIBUTE_REQUEST, fetchAttributes)
    yield takeLatest(ATTRIBUTE_CREATE_REQUEST, createAttributeSaga)
    yield takeLatest(ATTRIBUTE_UPDATE_REQUEST, updateAttributeSaga)
}