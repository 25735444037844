import axios from 'axios'
import { IPrompt } from '../../models/Prompt'

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const PROMPT_API = `${API_URL}/feedgen/prompt`

export async function getPromptsList(filter: string) {
    const url = `${PROMPT_API}?${filter}`
    return await axios.get(url)
}

export async function createPrompt(prompt: IPrompt) {
    return await axios.post(PROMPT_API, prompt)
}

export async function updatePrompt(id: number, prompt: Partial<IPrompt>) {
    return await axios.put(`${PROMPT_API}/${id}`, prompt)
}

export async function deletePrompt(id: number) {
    return await axios.delete(`${PROMPT_API}/${id}`)
}
