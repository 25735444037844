import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup'
import {INegativeKeyword} from '../models/NegativeKeyword'
import {
  createNegativeKeyword,
  deleteNegativeKeyword,
  getNegativeKeywordById,
  getNegativeKeywordsList,
  updateNegativeKeyword,
} from './NegativeKeywordsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const NEGATIVE_KEYWORD_REQUEST = 'NEGATIVE_KEYWORD_REQUEST'
export const NEGATIVE_KEYWORD_REQUEST_SUCCESS = 'NEGATIVE_KEYWORD_REQUEST_SUCCESS'
export const NEGATIVE_KEYWORD_REQUEST_FAIL = 'NEGATIVE_KEYWORD_REQUEST_FAIL'

export const NEGATIVE_CODE_DELETE = 'NEGATIVE_CODE_DELETE'
export const NEGATIVE_CODE_DELETE_SUCCESS = 'NEGATIVE_CODE_DELETE_SUCCESS'
export const NEGATIVE_CODE_DELETE_FAIL = 'NEGATIVE_CODE_DELETE_ERROR'

export const NEGATIVE_CODE_CREATE_REQUEST = 'NEGATIVE_CODE_CREATE_REQUEST'
export const NEGATIVE_CODE_CREATE_SUCCESS = 'NEGATIVE_CODE_CREATE_SUCCESS'
export const NEGATIVE_CODE_CREATE_FAIL = 'NEGATIVE_CODE_CREATE_FAIL'

export const GET_NEGATIVE_CODE_TO_UPDATE = 'GET_NEGATIVE_CODE_TO_UPDATE'
export const GET_NEGATIVE_CODE_TO_UPDATE_REQUEST = 'GET_NEGATIVE_CODE_TO_UPDATE_REQUEST'
export const GET_NEGATIVE_CODE_TO_UPDATE_FAIL = 'GET_NEGATIVE_CODE_TO_UPDATE_FAIL'

export const PUT_NEGATIVE_CODE_UPDATE_REQUEST = 'PUT_NEGATIVE_CODE_UPDATE_REQUEST'
export const PUT_NEGATIVE_CODE_UPDATE_SUCCESS = 'PUT_NEGATIVE_CODE_UPDATE_SUCCESS'
export const PUT_NEGATIVE_CODE_UPDATE_FAIL = 'PUT_NEGATIVE_CODE_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'
export const CHANGE_LANGUAGE_FILTER = 'CHANGE_LANGUAGE_FILTER'

export interface INegativeKeywordState {
  getNegativeKeywords?: INegativeKeyword[]
  getNegativeKeywordsLoading: boolean
  getNegativeKeywordsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  language_code: string
  languageFilter: string

  //create NegativeKeyword
  createNewNegativeKeyword?: INegativeKeyword
  createNegativeKeywordLoading: boolean
  createNegativeKeywordError: boolean
  negativeKeywordIsCreate: boolean
  createNegativeKeywordErrorMessage?: string[][]

  //get NegativeKeyword to update
  getNegativeKeywordLoading: boolean
  getNegativeKeywordError: boolean
  getNegativeKeywordToUpdateId?: number
  getNegativeKeywordToUpdateData?: INegativeKeyword

  //NegativeKeyword update
  negativeKeywordUpdateData?: INegativeKeyword
  negativeKeywordUpdateLoading: boolean
  negativeKeywordUpdateError: boolean
  negativeKeywordIsUpdate: boolean
  negativeKeywordUpdateErrorMessage?: string[][]

  //delete NegativeKeyword
  negativeKeywordIdToDelete?: number
  negativeKeywordDeleteError: boolean
}

const initialNegativeKeywordState: INegativeKeywordState = {
  getNegativeKeywords: [],
  getNegativeKeywordsLoading: false,
  getNegativeKeywordsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
  language_code: '',
  languageFilter: '',

  createNewNegativeKeyword: undefined,
  createNegativeKeywordLoading: false,
  createNegativeKeywordError: false,
  negativeKeywordIsCreate: false,
  createNegativeKeywordErrorMessage: undefined,

  getNegativeKeywordLoading: false,
  getNegativeKeywordError: false,
  getNegativeKeywordToUpdateId: undefined,
  getNegativeKeywordToUpdateData: undefined,

  negativeKeywordUpdateData: undefined,
  negativeKeywordUpdateLoading: false,
  negativeKeywordUpdateError: false,
  negativeKeywordIsUpdate: false,
  negativeKeywordUpdateErrorMessage: undefined,

  negativeKeywordIdToDelete: undefined,
  negativeKeywordDeleteError: false,
}

export const reducer = (
  state: INegativeKeywordState = initialNegativeKeywordState,
  action: ActionWithPayload<INegativeKeywordState>
) => {
  switch (action.type) {
    case NEGATIVE_KEYWORD_REQUEST:
      return {...state, getNegativeKeywordsLoading: true}
    case NEGATIVE_KEYWORD_REQUEST_SUCCESS:
      const negativeKeyword = action.payload?.getNegativeKeywords
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getNegativeKeywords: negativeKeyword,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getNegativeKeywordsLoading: false,
        getNegativeKeywordsError: false,
        negativeKeywordIsCreate: false,
        negativeKeywordIsUpdate: false,
        negativeKeywordUpdateError: false,
      }
    case NEGATIVE_KEYWORD_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const changeFilter = action.payload?.filter
      return {...state, filter: changeFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //NegativeKeyword delete
    case NEGATIVE_CODE_DELETE:
      const negativeKeywordIdToDelete = action.payload?.negativeKeywordIdToDelete
      return {...state, negativeKeywordIdToDelete}
    case NEGATIVE_CODE_DELETE_SUCCESS:
      const listAfterDel = state.getNegativeKeywords?.filter(
        (item) => item.id !== action.payload?.negativeKeywordIdToDelete
      )
      return {...state, getNegativeKeywords: listAfterDel}
    case NEGATIVE_CODE_DELETE_FAIL:
      return {...state, negativeKeywordDeleteError: true}

    case NEGATIVE_CODE_CREATE_REQUEST:
      const createNewNegativeKeyword = action.payload?.createNewNegativeKeyword
      return {
        ...state,
        createNegativeKeywordLoading: true,
        createNewNegativeKeyword: createNewNegativeKeyword,
        negativeKeywordIsCreate: false,
        createNegativeKeywordError: false,
      }
    case NEGATIVE_CODE_CREATE_SUCCESS:
      return {...state, createNegativeKeywordLoading: false, negativeKeywordIsCreate: true}
    case NEGATIVE_CODE_CREATE_FAIL:
      const negativeKeywordErrorMessage = action.payload?.createNegativeKeywordErrorMessage
      return {
        ...state,
        createNegativeKeywordErrorMessage: negativeKeywordErrorMessage,
        createNegativeKeywordError: true,
        createNegativeKeywordLoading: false,
        negativeKeywordIsCreate: false,
      }
    //get NegativeKeyword to update
    case GET_NEGATIVE_CODE_TO_UPDATE:
      const negativeKeywordIdToUpdate = action.payload?.getNegativeKeywordToUpdateId
      return {
        ...state,
        getNegativeKeywordToUpdateId: negativeKeywordIdToUpdate,
        getNegativeKeywordToUpdateData: undefined,
        getNegativeKeywordLoading: true,
      }
    case GET_NEGATIVE_CODE_TO_UPDATE_REQUEST:
      const negativeKeywordToUpdateData = action.payload?.getNegativeKeywordToUpdateData
      return {
        ...state,
        getNegativeKeywordToUpdateData: negativeKeywordToUpdateData,
        getNegativeKeywordLoading: false,
      }
    case GET_NEGATIVE_CODE_TO_UPDATE_FAIL:
      return {
        ...state,
        getNegativeKeywordLoading: false,
        getNegativeKeywordError: true,
      }
    //put NegativeKeyword update
    case PUT_NEGATIVE_CODE_UPDATE_REQUEST:
      const negativeKeywordDataUpd = action.payload?.negativeKeywordUpdateData
      return {
        ...state,
        negativeKeywordUpdateLoading: true,
        negativeKeywordUpdateData: negativeKeywordDataUpd,
        negativeKeywordIsUpdate: false,
        negativeKeywordUpdateError: false,
      }
    case PUT_NEGATIVE_CODE_UPDATE_SUCCESS:
      return {
        ...state,
        negativeKeywordUpdateLoading: false,
        negativeKeywordIsUpdate: true,
      }
    case PUT_NEGATIVE_CODE_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.negativeKeywordUpdateErrorMessage
      return {
        ...state,
        negativeKeywordUpdateLoading: false,
        negativeKeywordUpdateError: true,
        negativeKeywordIsUpdate: false,
        negativeKeywordUpdateErrorMessage: errorUpdMessage,
      }
    case CHANGE_LANGUAGE_FILTER:
      return {
        ...state,
        languageFilter: action.payload?.languageFilter ?? state.languageFilter,
      }

    default:
      return state
  }
}

export const actions = {
  requestNegativeKeywords: (languageCode?: string) => ({
    type: NEGATIVE_KEYWORD_REQUEST,
    payload: { languageCode },
  }),
  requestNegativeKeywordsSuccess: (
    negativeKeyword: INegativeKeyword[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: NEGATIVE_KEYWORD_REQUEST_SUCCESS,
    payload: {getNegativeKeywords: negativeKeyword, currentPage, totalPages, perPage, totalItem},
  }),

  requestNegativeKeywordFail: () => ({type: NEGATIVE_KEYWORD_REQUEST_FAIL}),

  createNegativeKeywordRequest: (createNegativeKeyword: INegativeKeyword) => ({
    type: NEGATIVE_CODE_CREATE_REQUEST,
    payload: {createNewNegativeKeyword: createNegativeKeyword},
  }),

  createNewNegativeKeyword: () => ({
    type: NEGATIVE_CODE_CREATE_SUCCESS,
  }),

  createNegativeKeywordFail: (err: any) => ({
    type: NEGATIVE_CODE_CREATE_FAIL,
    payload: {createNegativeKeywordErrorMessage: err},
  }),

  changeNegativeKeywordToUpdateId: (negativeKeywordId: number) => ({
    type: GET_NEGATIVE_CODE_TO_UPDATE,
    payload: {getNegativeKeywordToUpdateId: negativeKeywordId},
  }),

  requestNegativeKeywordToUpdateData: (getNegativeKeywordToUpdateData: INegativeKeyword) => ({
    type: GET_NEGATIVE_CODE_TO_UPDATE_REQUEST,
    payload: {getNegativeKeywordToUpdateData},
  }),

  requestNegativeKeywordToUpdateDataFail: () => ({
    type: GET_NEGATIVE_CODE_TO_UPDATE_FAIL,
  }),

  updateNegativeKeywordData: (negativeKeywordData: INegativeKeyword) => ({
    type: PUT_NEGATIVE_CODE_UPDATE_REQUEST,
    payload: {negativeKeywordUpdateData: negativeKeywordData},
  }),

  updateNegativeKeywordDataSuccess: () => ({
    type: PUT_NEGATIVE_CODE_UPDATE_SUCCESS,
  }),

  requestNegativeKeywordToUpdateFail: (err: any) => ({
    type: PUT_NEGATIVE_CODE_UPDATE_FAIL,
    payload: {negativeKeywordUpdateErrorMessage: err},
  }),

  negativeKeywordToDelete: (negativeKeywordToDelete: number) => ({
    type: NEGATIVE_CODE_DELETE,
    payload: {negativeKeywordIdToDelete: negativeKeywordToDelete},
  }),

  negativeKeywordToDeleteSuccces: (negativeKeywordIdToDelete: number) => ({
    type: NEGATIVE_CODE_DELETE_SUCCESS,
    payload: {negativeKeywordIdToDelete},
  }),

  negativeKeywordToDeleteFail: () => ({type: NEGATIVE_CODE_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getNegativeKeywords: (state: RootState) => state.negativeKeyword,
  getCurrentPage: (state: RootState) => state.negativeKeyword.currentPage,
  getLimitPerPage: (state: RootState) => state.negativeKeyword.perPage,
  getFilter: (state: RootState) => state.negativeKeyword.filter,
  getTotalItem: (state: RootState) => state.negativeKeyword.totalItem,
  getSearchText: (state: RootState) => state.negativeKeyword.searchText,
  getNegativeKeywordIDToDelete: (state: RootState) =>
    state.negativeKeyword.negativeKeywordIdToDelete,
  getNegativeKeywordToCreate: (state: RootState) => state.negativeKeyword.createNewNegativeKeyword,
  getCreateNegativeKeywordData: (state: RootState) => {
    const {
      createNegativeKeywordLoading,
      createNegativeKeywordError,
      createNegativeKeywordErrorMessage,
      negativeKeywordIsCreate,
    } = state.negativeKeyword
    return {
      createNegativeKeywordLoading,
      createNegativeKeywordError,
      createNegativeKeywordErrorMessage,
      negativeKeywordIsCreate,
    }
  },
  getUpdateNegativeKeywordData: (state: RootState) => {
    const {
      negativeKeywordUpdateLoading,
      negativeKeywordUpdateError,
      negativeKeywordUpdateErrorMessage,
      negativeKeywordIsUpdate,
    } = state.negativeKeyword
    return {
      negativeKeywordUpdateLoading,
      negativeKeywordUpdateError,
      negativeKeywordUpdateErrorMessage,
      negativeKeywordIsUpdate,
    }
  },
  getNegativeKeywordToUpdateId: (state: RootState) =>
    state.negativeKeyword.getNegativeKeywordToUpdateId,
  getNegativeKeywordToUpdateData: (state: RootState) =>
    state.negativeKeyword.getNegativeKeywordToUpdateData,
  getNegativeKeywordDataToUpdate: (state: RootState) =>
    state.negativeKeyword.negativeKeywordUpdateData,
}

function* listUpdate(languageCode?: string) {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getNegativeKeywordsList(page, limit, filter, searchValue, languageCode)
  return {data}
}

export function* saga() {
  yield takeLatest(NEGATIVE_KEYWORD_REQUEST, function* getNegativeKeywordsSaga(
      action: ActionWithPayload<{ languageCode?: string }>
  ) {
    try {
      const {data} = yield listUpdate(action.payload?.languageCode)
      yield put(
        actions.requestNegativeKeywordsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestNegativeKeywordFail())
    }
  })

  yield takeLatest(NEGATIVE_CODE_DELETE, function* deleteNegativeKeywordSaga() {
    try {
      const delNegativeKeyword: number = yield select(selectors.getNegativeKeywordIDToDelete)
      yield deleteNegativeKeyword(delNegativeKeyword)
      yield put(actions.negativeKeywordToDeleteSuccces(delNegativeKeyword))
    } catch (error) {
      yield put(actions.negativeKeywordToDeleteFail())
    }
  })

  yield takeLatest(NEGATIVE_CODE_CREATE_REQUEST, function* createNegativeKeywordSaga() {
    const newNegativeKeyword: INegativeKeyword = yield select(selectors.getNegativeKeywordToCreate)
    const {res, err} = yield createNegativeKeyword(newNegativeKeyword)
    if (res) {
      yield put(actions.createNewNegativeKeyword())
      const {data} = yield listUpdate()
      yield put(
        actions.requestNegativeKeywordsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createNegativeKeywordFail(errResArray))
      } else {
        yield put(actions.createNegativeKeywordFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_NEGATIVE_CODE_TO_UPDATE, function* getUpdateNegativeKeywordSaga() {
    try {
      const updNegativeKeywordId: number = yield select(selectors.getNegativeKeywordToUpdateId)
      const {data} = yield getNegativeKeywordById(updNegativeKeywordId)
      yield put(actions.requestNegativeKeywordToUpdateData(data))
    } catch (err) {
      yield put(actions.requestNegativeKeywordToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_NEGATIVE_CODE_UPDATE_REQUEST, function* updateNegativeKeywordSaga() {
    const negativeKeyword: INegativeKeyword = yield select(selectors.getNegativeKeywordDataToUpdate)
    const {res, err} = yield updateNegativeKeyword(negativeKeyword)
    if (res) {
      yield put(actions.updateNegativeKeywordDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestNegativeKeywordsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestNegativeKeywordToUpdateFail(errResArray))
      } else {
        yield put(actions.requestNegativeKeywordToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
