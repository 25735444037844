export const PROMPT_LIST_FILTERS = [
  { value: 'sort=-created_at', label: 'CLIENTS.LIST.SORT.CREATE_DOWN' },
  { value: 'sort=created_at', label: 'CLIENTS.LIST.SORT.CREATE_UP' },
]

export interface IPrompt {
  id?: number
  industry_id: number | null
  attribute_id: number
  prompt_text: string
  created_at?: string
  updated_at?: string
  global: boolean
  attribute?: {
    id: number
    name: string
  }
  industry?: {
    id: number
    name: string
  }
}
