import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IAdvertisingChannel} from '../models/AdvertisingChannel'
import {
  createAdvertisingChannel,
  deleteAdvertisingChannel,
  getAdvertisingChannelById,
  getAdvertisingChannelsList,
  updateAdvertisingChannel,
} from './AdvertisingChannelsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const ADVERTISING_CHANNELS_REQUEST = 'ADVERTISING_CHANNELS_REQUEST'
export const ADVERTISING_CHANNELS_REQUEST_SUCCESS = 'ADVERTISING_CHANNELS_REQUEST_SUCCESS'
export const ADVERTISING_CHANNELS_REQUEST_FAIL = 'ADVERTISING_CHANNELS_REQUEST_FAIL'

export const ADVERTISING_CHANNEL_DELETE = 'ADVERTISING_CHANNEL_DELETE'
export const ADVERTISING_CHANNEL_DELETE_SUCCESS = 'ADVERTISING_CHANNEL_DELETE_SUCCESS'
export const ADVERTISING_CHANNEL_DELETE_FAIL = 'ADVERTISING_CHANNEL_DELETE_ERROR'

export const ADVERTISING_CHANNEL_CREATE_REQUEST = 'ADVERTISING_CHANNEL_CREATE_REQUEST'
export const ADVERTISING_CHANNEL_CREATE_SUCCESS = 'ADVERTISING_CHANNEL_CREATE_SUCCESS'
export const ADVERTISING_CHANNEL_CREATE_FAIL = 'ADVERTISING_CHANNEL_CREATE_FAIL'

export const GET_ADVERTISING_CHANNEL_TO_UPDATE = 'GET_ADVERTISING_CHANNEL_TO_UPDATE'
export const GET_ADVERTISING_CHANNEL_TO_UPDATE_REQUEST = 'GET_ADVERTISING_CHANNEL_TO_UPDATE_REQUEST'
export const GET_ADVERTISING_CHANNEL_TO_UPDATE_FAIL = 'GET_ADVERTISING_CHANNEL_TO_UPDATE_FAIL'

export const PUT_ADVERTISING_CHANNEL_UPDATE_REQUEST = 'PUT_ADVERTISING_CHANNEL_UPDATE_REQUEST'
export const PUT_ADVERTISING_CHANNEL_UPDATE_SUCCESS = 'PUT_ADVERTISING_CHANNEL_UPDATE_SUCCESS'
export const PUT_ADVERTISING_CHANNEL_UPDATE_FAIL = 'PUT_ADVERTISING_CHANNEL_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IAdvertisingChannelsState {
  getAdvertisingChannels?: IAdvertisingChannel[]
  getAdvertisingChannelsLoading: boolean
  getAdvertisingChannelsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create advertisingChannel
  createNewAdvertisingChannel?: IAdvertisingChannel
  createAdvertisingChannelLoading: boolean
  createAdvertisingChannelError: boolean
  advertisingChannelIsCreate: boolean
  createAdvertisingChannelErrorMessage?: string[][]
  //get advertisingChannel to update
  getAdvertisingChannelLoading: boolean
  getAdvertisingChannelError: boolean
  getAdvertisingChannelToUpdateId?: number
  getAdvertisingChannelToUpdateData?: IAdvertisingChannel
  //advertisingChannel update
  advertisingChannelUpdateData?: IAdvertisingChannel
  advertisingChannelUpdateLoading: boolean
  advertisingChannelUpdateError: boolean
  advertisingChannelIsUpdate: boolean
  advertisingChannelUpdateErrorMessage?: string[][]
  //delete advertisingChannel
  advertisingChannelIdToDelete?: number
  advertisingChannelDeleteError: boolean
}

const initialAdvertisingChannelsState: IAdvertisingChannelsState = {
  getAdvertisingChannels: [],
  getAdvertisingChannelsLoading: false,
  getAdvertisingChannelsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  createNewAdvertisingChannel: undefined,
  createAdvertisingChannelLoading: false,
  createAdvertisingChannelError: false,
  advertisingChannelIsCreate: false,
  createAdvertisingChannelErrorMessage: undefined,

  getAdvertisingChannelLoading: false,
  getAdvertisingChannelError: false,
  getAdvertisingChannelToUpdateId: undefined,
  getAdvertisingChannelToUpdateData: undefined,

  advertisingChannelUpdateData: undefined,
  advertisingChannelUpdateLoading: false,
  advertisingChannelUpdateError: false,
  advertisingChannelIsUpdate: false,
  advertisingChannelUpdateErrorMessage: undefined,

  advertisingChannelIdToDelete: undefined,
  advertisingChannelDeleteError: false,
}

export const reducer = (
  state: IAdvertisingChannelsState = initialAdvertisingChannelsState,
  action: ActionWithPayload<IAdvertisingChannelsState>
) => {
  switch (action.type) {
    case ADVERTISING_CHANNELS_REQUEST:
      return {...state, getAdvertisingChannelsLoading: true}
    case ADVERTISING_CHANNELS_REQUEST_SUCCESS:
      const advertisingChannel = action.payload?.getAdvertisingChannels
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getAdvertisingChannels: advertisingChannel,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getAdvertisingChannelsLoading: false,
        getAdvertisingChannelsError: false,
      }
    case ADVERTISING_CHANNELS_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const changeFilter = action.payload?.filter
      return {...state, filter: changeFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //advertisingChannel delete
    case ADVERTISING_CHANNEL_DELETE:
      const advertisingChannelIdToDelete = action.payload?.advertisingChannelIdToDelete
      return {...state, advertisingChannelIdToDelete}
    case ADVERTISING_CHANNEL_DELETE_SUCCESS:
      const listAfterDel = state.getAdvertisingChannels?.filter(
        (item) => item.id !== action.payload?.advertisingChannelIdToDelete
      )
      return {...state, getAdvertisingChannels: listAfterDel}
    case ADVERTISING_CHANNEL_DELETE_FAIL:
      return {...state, advertisingChannelDeleteError: true}

    //advertisingChannel create
    case ADVERTISING_CHANNEL_CREATE_REQUEST:
      const createNewAdvertisingChannel = action.payload?.createNewAdvertisingChannel
      return {
        ...state,
        createAdvertisingChannelLoading: true,
        createNewAdvertisingChannel: createNewAdvertisingChannel,
        advertisingChannelIsCreate: false,
        createAdvertisingChannelError: false,
      }
    case ADVERTISING_CHANNEL_CREATE_SUCCESS:
      return {...state, createAdvertisingChannelLoading: false, advertisingChannelIsCreate: true}
    case ADVERTISING_CHANNEL_CREATE_FAIL:
      const advertisingChannelErrorMessage = action.payload?.createAdvertisingChannelErrorMessage
      return {
        ...state,
        createAdvertisingChannelErrorMessage: advertisingChannelErrorMessage,
        createAdvertisingChannelError: true,
        createAdvertisingChannelLoading: false,
        advertisingChannelIsCreate: false,
      }
    //get advertisingChannel to update
    case GET_ADVERTISING_CHANNEL_TO_UPDATE:
      const advertisingChannelIdToUpdate = action.payload?.getAdvertisingChannelToUpdateId
      return {
        ...state,
        getAdvertisingChannelToUpdateId: advertisingChannelIdToUpdate,
        getAdvertisingChannelToUpdateData: undefined,
        getAdvertisingChannelLoading: true,
      }
    case GET_ADVERTISING_CHANNEL_TO_UPDATE_REQUEST:
      const advertisingChannelToUpdateData = action.payload?.getAdvertisingChannelToUpdateData
      return {
        ...state,
        getAdvertisingChannelToUpdateData: advertisingChannelToUpdateData,
        getAdvertisingChannelLoading: false,
      }
    case GET_ADVERTISING_CHANNEL_TO_UPDATE_FAIL:
      return {
        ...state,
        getAdvertisingChannelLoading: false,
        getAdvertisingChannelError: true,
      }
    //put advertisingChannel update
    case PUT_ADVERTISING_CHANNEL_UPDATE_REQUEST:
      const advertisingChannelDataUpd = action.payload?.advertisingChannelUpdateData
      return {
        ...state,
        advertisingChannelUpdateLoading: true,
        advertisingChannelUpdateData: advertisingChannelDataUpd,
        advertisingChannelIsUpdate: false,
        advertisingChannelUpdateError: false,
      }
    case PUT_ADVERTISING_CHANNEL_UPDATE_SUCCESS:
      return {
        ...state,
        advertisingChannelUpdateLoading: false,
        advertisingChannelIsUpdate: true,
      }
    case PUT_ADVERTISING_CHANNEL_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.advertisingChannelUpdateErrorMessage
      return {
        ...state,
        advertisingChannelUpdateLoading: false,
        advertisingChannelUpdateError: true,
        advertisingChannelIsUpdate: false,
        advertisingChannelUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestAdvertisingChannels: () => ({type: ADVERTISING_CHANNELS_REQUEST}),
  requestAdvertisingChannelsSuccess: (
    advertisingChannel: IAdvertisingChannel[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: ADVERTISING_CHANNELS_REQUEST_SUCCESS,
    payload: {
      getAdvertisingChannels: advertisingChannel,
      currentPage,
      totalPages,
      perPage,
      totalItem,
    },
  }),

  requestAdvertisingChannelFail: () => ({type: ADVERTISING_CHANNELS_REQUEST_FAIL}),

  createAdvertisingChannelRequest: (createAdvertisingChannel: IAdvertisingChannel) => ({
    type: ADVERTISING_CHANNEL_CREATE_REQUEST,
    payload: {createNewAdvertisingChannel: createAdvertisingChannel},
  }),

  createNewAdvertisingChannel: () => ({
    type: ADVERTISING_CHANNEL_CREATE_SUCCESS,
  }),

  createAdvertisingChannelFail: (err: any) => ({
    type: ADVERTISING_CHANNEL_CREATE_FAIL,
    payload: {createAdvertisingChannelErrorMessage: err},
  }),

  changeAdvertisingChannelToUpdateId: (advertisingChannelId: number) => ({
    type: GET_ADVERTISING_CHANNEL_TO_UPDATE,
    payload: {getAdvertisingChannelToUpdateId: advertisingChannelId},
  }),

  requestAdvertisingChannelToUpdateData: (
    getAdvertisingChannelToUpdateData: IAdvertisingChannel
  ) => ({
    type: GET_ADVERTISING_CHANNEL_TO_UPDATE_REQUEST,
    payload: {getAdvertisingChannelToUpdateData},
  }),

  requestAdvertisingChannelToUpdateDataFail: () => ({
    type: GET_ADVERTISING_CHANNEL_TO_UPDATE_FAIL,
  }),

  updateAdvertisingChannelData: (advertisingChannelData: IAdvertisingChannel) => ({
    type: PUT_ADVERTISING_CHANNEL_UPDATE_REQUEST,
    payload: {advertisingChannelUpdateData: advertisingChannelData},
  }),

  updateAdvertisingChannelDataSuccess: () => ({
    type: PUT_ADVERTISING_CHANNEL_UPDATE_SUCCESS,
  }),

  requestAdvertisingChannelToUpdateFail: (err: any) => ({
    type: PUT_ADVERTISING_CHANNEL_UPDATE_FAIL,
    payload: {advertisingChannelUpdateErrorMessage: err},
  }),

  advertisingChannelToDelete: (advertisingChannelToDelete: number) => ({
    type: ADVERTISING_CHANNEL_DELETE,
    payload: {advertisingChannelIdToDelete: advertisingChannelToDelete},
  }),

  advertisingChannelToDeleteSuccces: (advertisingChannelIdToDelete: number) => ({
    type: ADVERTISING_CHANNEL_DELETE_SUCCESS,
    payload: {advertisingChannelIdToDelete},
  }),

  advertisingChannelToDeleteFail: () => ({type: ADVERTISING_CHANNEL_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getAdvertisingChannels: (state: RootState) => state.advertisingChannel,
  getCurrentPage: (state: RootState) => state.advertisingChannel.currentPage,
  getLimitPerPage: (state: RootState) => state.advertisingChannel.perPage,
  getFilter: (state: RootState) => state.advertisingChannel.filter,
  getTotalItem: (state: RootState) => state.advertisingChannel.totalItem,
  getSearchText: (state: RootState) => state.advertisingChannel.searchText,
  getAdvertisingChannelIDToDelete: (state: RootState) =>
    state.advertisingChannel.advertisingChannelIdToDelete,
  getAdvertisingChannelToCreate: (state: RootState) =>
    state.advertisingChannel.createNewAdvertisingChannel,
  getCreateAdvertisingChannelData: (state: RootState) => {
    const {
      createAdvertisingChannelLoading,
      createAdvertisingChannelError,
      createAdvertisingChannelErrorMessage,
      advertisingChannelIsCreate,
    } = state.advertisingChannel
    return {
      createAdvertisingChannelLoading,
      createAdvertisingChannelError,
      createAdvertisingChannelErrorMessage,
      advertisingChannelIsCreate,
    }
  },
  getUpdateAdvertisingChannelData: (state: RootState) => {
    const {
      advertisingChannelUpdateLoading,
      advertisingChannelUpdateError,
      advertisingChannelUpdateErrorMessage,
      advertisingChannelIsUpdate,
    } = state.advertisingChannel
    return {
      advertisingChannelUpdateLoading,
      advertisingChannelUpdateError,
      advertisingChannelUpdateErrorMessage,
      advertisingChannelIsUpdate,
    }
  },
  getAdvertisingChannelToUpdateId: (state: RootState) =>
    state.advertisingChannel.getAdvertisingChannelToUpdateId,
  getAdvertisingChannelToUpdateData: (state: RootState) =>
    state.advertisingChannel.getAdvertisingChannelToUpdateData,
  getAdvertisingChannelDataToUpdate: (state: RootState) =>
    state.advertisingChannel.advertisingChannelUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getAdvertisingChannelsList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(ADVERTISING_CHANNELS_REQUEST, function* getAdvertisingChannelsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestAdvertisingChannelsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestAdvertisingChannelFail())
    }
  })

  yield takeLatest(ADVERTISING_CHANNEL_DELETE, function* deleteAdvertisingChannelSaga() {
    try {
      const delAdvertisingChannel: number = yield select(selectors.getAdvertisingChannelIDToDelete)
      yield deleteAdvertisingChannel(delAdvertisingChannel)
      yield put(actions.advertisingChannelToDeleteSuccces(delAdvertisingChannel))
    } catch (error) {
      yield put(actions.advertisingChannelToDeleteFail())
    }
  })

  yield takeLatest(ADVERTISING_CHANNEL_CREATE_REQUEST, function* createAdvertisingChannelSaga() {
    const newAdvertisingChannel: IAdvertisingChannel = yield select(
      selectors.getAdvertisingChannelToCreate
    )
    const {res, err} = yield createAdvertisingChannel(newAdvertisingChannel)
    if (res) {
      yield put(actions.createNewAdvertisingChannel())
      const {data} = yield listUpdate()
      yield put(
        actions.requestAdvertisingChannelsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createAdvertisingChannelFail(errResArray))
      } else {
        yield put(actions.createAdvertisingChannelFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_ADVERTISING_CHANNEL_TO_UPDATE, function* getUpdateAdvertisingChannelSaga() {
    try {
      const updAdvertisingChannelId: number = yield select(
        selectors.getAdvertisingChannelToUpdateId
      )
      const {data} = yield getAdvertisingChannelById(updAdvertisingChannelId)
      yield put(actions.requestAdvertisingChannelToUpdateData(data))
    } catch (err) {
      yield put(actions.requestAdvertisingChannelToUpdateDataFail())
    }
  })

  yield takeLatest(
    PUT_ADVERTISING_CHANNEL_UPDATE_REQUEST,
    function* updateAdvertisingChannelSaga() {
      const advertisingChannel: IAdvertisingChannel = yield select(
        selectors.getAdvertisingChannelDataToUpdate
      )
      const {res, err} = yield updateAdvertisingChannel(advertisingChannel)
      if (res) {
        yield put(actions.updateAdvertisingChannelDataSuccess())
        const {data} = yield listUpdate()
        yield put(
          actions.requestAdvertisingChannelsSuccess(
            data.data,
            data.current_page,
            data.last_page,
            data.per_page,
            data.total
          )
        )
      } else if (err) {
        if (err.response.status === 422) {
          const errorArray: any = Object.values(err.response.data)
          const errRes: IErrorResponse = errorArray[1]
          const errResArray = Object.values(errRes)
          yield put(actions.requestAdvertisingChannelToUpdateFail(errResArray))
        } else {
          yield put(actions.requestAdvertisingChannelToUpdateFail([[err.response.data.message]]))
        }
      }
    }
  )
}
