import axios from 'axios'
import {IPlatform} from '../models/Platform'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const PLATFORMS_API = `${API_URL}/platforms`

export async function getPlatformsList(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${PLATFORMS_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${PLATFORMS_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getPlatformById(platformId: number) {
  return await axios.get(`${PLATFORMS_API}/${platformId}`)
}

export async function deletePlatform(platformId: number) {
  return await axios.delete(`${PLATFORMS_API}/${platformId}`)
}

export async function updatePlatform(updatePlatform: IPlatform) {
  return await axios
    .put(`${PLATFORMS_API}/${updatePlatform.id}`, updatePlatform)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createPlatform(newPlatform: IPlatform) {
  return await axios
    .post(`${PLATFORMS_API}`, newPlatform)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
