import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export const MenuInner: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {/* other menu item */}
    </>
  )
}
