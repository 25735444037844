import { IAttribute } from './Attribute'

export const GOOGLE_CATEGORY_LIST_FILTERS = [
  { value: 'sort=-created_at', label: 'CLIENTS.LIST.SORT.CREATE_DOWN' },
  { value: 'sort=created_at', label: 'CLIENTS.LIST.SORT.CREATE_UP' },
  { value: 'sort=name', label: 'PLATFORM.LIST.FILTER.NAME_UP' },
  { value: 'sort=-name', label: 'PLATFORM.LIST.FILTER.NAME_DOWN' },
]

export interface IGoogleCategory {
  id?: number
  name: string
  created_at: string
  updated_at: string
  attributes?: IAttribute[]
}
