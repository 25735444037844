import { IGoogleCategory } from './GoogleCategory'

export const ATTRIBUTE_LIST_FILTERS = [
  { value: 'sort=-created_at', label: 'CLIENTS.LIST.SORT.CREATE_DOWN' },
  { value: 'sort=created_at', label: 'CLIENTS.LIST.SORT.CREATE_UP' },
  { value: 'sort=-name', label: 'PLATFORM.LIST.FILTER.NAME_DOWN' },
  { value: 'sort=name', label: 'PLATFORM.LIST.FILTER.NAME_UP' }
]

export interface IAttribute {
  id?: number
  name: string
  description: string
  created_at?: string
  global: boolean
  google_category_ids?: number[]
  google_categories?: IGoogleCategory[]
}