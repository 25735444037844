import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/me`
export const LOGIN_URL = `${API_URL}/login`

// Server should return AuthModel
export function login(email: string, password: string) {
  const login = axios.post(LOGIN_URL, {email, password})
  return login
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
