import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {ISubscription} from '../models/Subscription'
import {
  deleteSubscription,
  getSubscriptionById,
  getSubscriptionsList,
  updateSubscription,
} from './SubscriptionsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST'
export const SUBSCRIPTION_REQUEST_SUCCESS = 'SUBSCRIPTION_REQUEST_SUCCESS'
export const SUBSCRIPTION_REQUEST_FAIL = 'SUBSCRIPTION_REQUEST_FAIL'

export const subscription_DELETE = 'subscription_DELETE'
export const subscription_DELETE_SUCCESS = 'subscription_DELETE_SUCCESS'
export const subscription_DELETE_FAIL = 'subscription_DELETE_ERROR'

export const GET_SUBSCRIPTION_TO_UPDATE = 'GET_SUBSCRIPTION_TO_UPDATE'
export const GET_SUBSCRIPTION_TO_UPDATE_REQUEST = 'GET_SUBSCRIPTION_TO_UPDATE_REQUEST'
export const GET_SUBSCRIPTION_TO_UPDATE_FAIL = 'GET_SUBSCRIPTION_TO_UPDATE_FAIL'

export const PUT_SUBSCRIPTION_UPDATE_REQUEST = 'PUT_SUBSCRIPTION_UPDATE_REQUEST'
export const PUT_SUBSCRIPTION_UPDATE_SUCCESS = 'PUT_SUBSCRIPTION_UPDATE_SUCCESS'
export const PUT_SUBSCRIPTION_UPDATE_FAIL = 'PUT_SUBSCRIPTION_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface ISubscriptionsState {
  getSubscriptions?: ISubscription[]
  getSubscriptionsLoading: boolean
  getSubscriptionsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //get subscription to update
  getSubscriptionLoading: boolean
  getSubscriptionError: boolean
  getSubscriptionToUpdateId?: number
  getSubscriptionToUpdateData?: ISubscription
  //subscription update
  subscriptionUpdateData?: ISubscription
  subscriptionUpdateLoading: boolean
  subscriptionUpdateError: boolean
  subscriptionIsUpdate: boolean
  subscriptionUpdateErrorMessage?: string[][]
  //delete subscription
  subscriptionIdToDelete?: number
  subscriptionDeleteError: boolean
}

const initialSubscriptionsState: ISubscriptionsState = {
  getSubscriptions: [],
  getSubscriptionsLoading: false,
  getSubscriptionsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  getSubscriptionLoading: false,
  getSubscriptionError: false,
  getSubscriptionToUpdateId: undefined,
  getSubscriptionToUpdateData: undefined,

  subscriptionUpdateData: undefined,
  subscriptionUpdateLoading: false,
  subscriptionUpdateError: false,
  subscriptionIsUpdate: false,
  subscriptionUpdateErrorMessage: undefined,

  subscriptionIdToDelete: undefined,
  subscriptionDeleteError: false,
}

export const reducer = (
  state: ISubscriptionsState = initialSubscriptionsState,
  action: ActionWithPayload<ISubscriptionsState>
) => {
  switch (action.type) {
    case SUBSCRIPTION_REQUEST:
      return {...state, getSubscriptionsLoading: true}
    case SUBSCRIPTION_REQUEST_SUCCESS:
      const subscriptions = action.payload?.getSubscriptions
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getSubscriptions: subscriptions,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getSubscriptionsLoading: false,
        getSubscriptionsError: false,
      }
    case SUBSCRIPTION_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //subscription delete
    case subscription_DELETE:
      const subscriptionIdToDelete = action.payload?.subscriptionIdToDelete
      return {...state, subscriptionIdToDelete}
    case subscription_DELETE_SUCCESS:
      const listAfterDel = state.getSubscriptions?.filter(
        (item) => item.id !== action.payload?.subscriptionIdToDelete
      )
      return {...state, getSubscriptions: listAfterDel}
    case subscription_DELETE_FAIL:
      return {...state, subscriptionDeleteError: true}

    //get subscription to update
    case GET_SUBSCRIPTION_TO_UPDATE:
      const subscriptionIdToUpdate = action.payload?.getSubscriptionToUpdateId
      return {
        ...state,
        getSubscriptionToUpdateId: subscriptionIdToUpdate,
        getSubscriptionToUpdateData: undefined,
        getSubscriptionLoading: true,
      }
    case GET_SUBSCRIPTION_TO_UPDATE_REQUEST:
      const subscriptionToUpdateData = action.payload?.getSubscriptionToUpdateData
      return {
        ...state,
        getSubscriptionToUpdateData: subscriptionToUpdateData,
        getSubscriptionLoading: false,
      }
    case GET_SUBSCRIPTION_TO_UPDATE_FAIL:
      return {
        ...state,
        getSubscriptionLoading: false,
        getSubscriptionError: true,
      }
    //put subscription update
    case PUT_SUBSCRIPTION_UPDATE_REQUEST:
      const subscriptionDataUpd = action.payload?.subscriptionUpdateData
      return {
        ...state,
        subscriptionUpdateLoading: true,
        subscriptionUpdateData: subscriptionDataUpd,
        subscriptionIsUpdate: false,
        subscriptionUpdateError: false,
      }
    case PUT_SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        subscriptionUpdateLoading: false,
        subscriptionIsUpdate: true,
      }
    case PUT_SUBSCRIPTION_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.subscriptionUpdateErrorMessage
      return {
        ...state,
        subscriptionUpdateLoading: false,
        subscriptionUpdateError: true,
        subscriptionIsUpdate: false,
        subscriptionUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestSubscriptions: () => ({type: SUBSCRIPTION_REQUEST}),
  requestSubscriptionsSuccess: (
    subscriptions: ISubscription[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: SUBSCRIPTION_REQUEST_SUCCESS,
    payload: {getSubscriptions: subscriptions, currentPage, totalPages, perPage, totalItem},
  }),

  requestSubscriptionFail: () => ({type: SUBSCRIPTION_REQUEST_FAIL}),

  changeSubscriptionToUpdateId: (subscriptionId: string) => ({
    type: GET_SUBSCRIPTION_TO_UPDATE,
    payload: {getSubscriptionToUpdateId: subscriptionId},
  }),

  requestSubscriptionToUpdateData: (getSubscriptionToUpdateData: ISubscription) => ({
    type: GET_SUBSCRIPTION_TO_UPDATE_REQUEST,
    payload: {getSubscriptionToUpdateData},
  }),

  requestSubscriptionToUpdateDataFail: () => ({
    type: GET_SUBSCRIPTION_TO_UPDATE_FAIL,
  }),

  updateSubscriptionData: (subscriptionData: ISubscription) => ({
    type: PUT_SUBSCRIPTION_UPDATE_REQUEST,
    payload: {subscriptionUpdateData: subscriptionData},
  }),

  updateSubscriptionDataSuccess: () => ({
    type: PUT_SUBSCRIPTION_UPDATE_SUCCESS,
  }),

  requestSubscriptionToUpdateFail: (err: any) => ({
    type: PUT_SUBSCRIPTION_UPDATE_FAIL,
    payload: {subscriptionUpdateErrorMessage: err},
  }),

  subscriptionToDelete: (subscriptionToDelete: string) => ({
    type: subscription_DELETE,
    payload: {subscriptionIdToDelete: subscriptionToDelete},
  }),

  subscriptionToDeleteSuccces: (subscriptionIdToDelete: string) => ({
    type: subscription_DELETE_SUCCESS,
    payload: {subscriptionIdToDelete},
  }),

  subscriptionToDeleteFail: () => ({type: subscription_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getSubscriptions: (state: RootState) => state.subscriptions,
  getCurrentPage: (state: RootState) => state.subscriptions.currentPage,
  getLimitPerPage: (state: RootState) => state.subscriptions.perPage,
  getFilter: (state: RootState) => state.subscriptions.filter,
  getTotalItem: (state: RootState) => state.subscriptions.totalItem,
  getSearchText: (state: RootState) => state.subscriptions.searchText,
  getSubscriptionIDToDelete: (state: RootState) => state.subscriptions.subscriptionIdToDelete,
  getUpdateSubscriptionData: (state: RootState) => {
    const {
      subscriptionUpdateLoading,
      subscriptionUpdateError,
      subscriptionUpdateErrorMessage,
      subscriptionIsUpdate,
    } = state.subscriptions
    return {
      subscriptionUpdateLoading,
      subscriptionUpdateError,
      subscriptionUpdateErrorMessage,
      subscriptionIsUpdate,
    }
  },
  getSubscriptionToUpdateId: (state: RootState) => state.subscriptions.getSubscriptionToUpdateId,
  getSubscriptionToUpdateData: (state: RootState) =>
    state.subscriptions.getSubscriptionToUpdateData,
  getSubscriptionDataToUpdate: (state: RootState) => state.subscriptions.subscriptionUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getSubscriptionsList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(SUBSCRIPTION_REQUEST, function* getSubscriptionsSage() {
    try {
      const {data} = yield listUpdate()

      yield put(
        actions.requestSubscriptionsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestSubscriptionFail())
    }
  })

  yield takeLatest(subscription_DELETE, function* deleteSubscriptionSaga() {
    try {
      const delSubscription: string = yield select(selectors.getSubscriptionIDToDelete)
      yield deleteSubscription(delSubscription)
      yield put(actions.subscriptionToDeleteSuccces(delSubscription))
    } catch (error) {
      yield put(actions.subscriptionToDeleteFail())
    }
  })

  yield takeLatest(GET_SUBSCRIPTION_TO_UPDATE, function* getUpdateSubscriptionSaga() {
    try {
      const updSubscriptionId: string = yield select(selectors.getSubscriptionToUpdateId)
      const {data} = yield getSubscriptionById(updSubscriptionId)
      yield put(actions.requestSubscriptionToUpdateData(data.subscription))
    } catch (err) {
      yield put(actions.requestSubscriptionToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_SUBSCRIPTION_UPDATE_REQUEST, function* updateSubscriptionSaga() {
    const subscription: ISubscription = yield select(selectors.getSubscriptionDataToUpdate)
    const {res, err} = yield updateSubscription(subscription)
    if (res) {
      yield put(actions.updateSubscriptionDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestSubscriptionsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestSubscriptionToUpdateFail(errResArray))
      } else {
        yield put(actions.requestSubscriptionToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
