import React from 'react'
import './Spinner.scss'

const Spinner: React.FC = () => {
  return (
    <div className='overlay-layer rounded bg-white bg-opacity-5'>
      <div className='lds-roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Spinner
