import React, {useContext, useEffect, useState} from 'react'
import {Formik, FormikValues} from 'formik'
import {IBudgetForecast} from '../models/BudgetForecast'
import {validationCreateSchema, initialValues} from '../models/BudgetForecastFormCreate'
import {useDispatch, useSelector} from 'react-redux'
import {actions, selectors} from '../redux/BudgetForecastsRedux'
import {Button} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {AlertContext} from '../../../../_metronic/layout/core/AlertContext'

interface CreateBudgetForecastFormProps {
  closeModal: () => void
}

const CreateBudgetForecastForm: React.FC<CreateBudgetForecastFormProps> = ({closeModal}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const {showAlert} = useContext(AlertContext)
  const {
    createBudgetForecastLoading,
    createBudgetForecastError,
    createBudgetForecastErrorMessage,
    budgetForecastIsCreate,
  } = useSelector(selectors.getCreateBudgetForecastData)
  const [isAlert, setIsAlert] = useState<boolean>(false)

  useEffect(() => {
    setIsAlert(false)
  }, [])

  const onSubmit = (values: FormikValues) => {
    dispatch(actions.createBudgetForecastRequest(values as IBudgetForecast))
    setIsAlert(true)
  }

  useEffect(() => {
    if (createBudgetForecastError && isAlert) {
      if (createBudgetForecastErrorMessage)
        showAlert(createBudgetForecastErrorMessage, 'danger', true)
      else showAlert([['Error']], 'danger', true)
      setIsAlert(false)
    } else if (!createBudgetForecastError && budgetForecastIsCreate && isAlert) {
      showAlert([[]], 'success', true)
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBudgetForecastError, budgetForecastIsCreate, isAlert])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationCreateSchema}
        onSubmit={onSubmit}
      >
        {({handleSubmit, getFieldProps, errors, touched, resetForm}) => (
          <div className='card mb-5 mb-xl-10'>
            <div id='kt_account_profile_details' className='collapse show'>
              <form onSubmit={handleSubmit} className='form'>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.INDUSTRY'})}
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.INDUSTRY'})}
                            {...getFieldProps('industry')}
                          />
                          {touched.industry && errors.industry && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {intl.formatMessage({id: errors.industry})}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL'})}
                    </label>

                    <div className='col-lg-6 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        {...getFieldProps('channel')}
                      >
                        <option>{intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL'})}</option>
                        <option value='google'>
                          {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL.GOOGLE'})}
                        </option>
                        <option value='bing'>
                          {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL.BING'})}
                        </option>
                      </select>
                      {touched.channel && errors.channel && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.channel})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CPC'})}
                    </label>

                    <div className='col-lg-2 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CPC'})}
                        {...getFieldProps('cpc')}
                      />
                      {touched.cpc && errors.cpc && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.cpc})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CTR'})}
                    </label>

                    <div className='col-lg-2 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CTR'})}
                        {...getFieldProps('ctr')}
                      />
                      {touched.ctr && errors.ctr && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.ctr})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_RATE'})}
                    </label>

                    <div className='col-lg-2 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_RATE'})}
                        {...getFieldProps('conversion_rate')}
                      />
                      {touched.conversion_rate && errors.conversion_rate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.conversion_rate})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_VALUE'})}
                    </label>

                    <div className='col-lg-2 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_VALUE'})}
                        {...getFieldProps('conversion_value')}
                      />
                      {touched.conversion_value && errors.conversion_value && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.conversion_value})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_NUMBER'})}
                    </label>

                    <div className='col-lg-2 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_NUMBER'})}
                        {...getFieldProps('conversion_number')}
                      />
                      {touched.conversion_number && errors.conversion_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.conversion_number})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CART_VALUE'})}
                    </label>

                    <div className='col-lg-2 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'BUDGET_FORECASTS.CART_VALUE'})}
                        {...getFieldProps('cart_value')}
                      />
                      {touched.cart_value && errors.cart_value && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {intl.formatMessage({id: errors.cart_value})}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                  <Button
                    type='submit'
                    className='btn btn-primary me-4'
                    disabled={createBudgetForecastLoading}
                  >
                    {!createBudgetForecastLoading && intl.formatMessage({id: 'CREATE'})}
                    {createBudgetForecastLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'WAIT'})}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      resetForm()
                      closeModal()
                    }}
                  >
                    {intl.formatMessage({id: 'CLOSE'})}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

export {CreateBudgetForecastForm}
