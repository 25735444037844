/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {CreateBudgetForecastForm} from './CreateBudgetForecastForm'

interface CreatePlatformModalProps {
  showCreateModal: boolean
  handleCloseCreate: () => void
}

const CreateBudgetForecastModal: React.FC<CreatePlatformModalProps> = ({
  showCreateModal,
  handleCloseCreate,
}) => {
  const intl = useIntl()
  return (
    <Modal size={'xl'} show={showCreateModal} onHide={handleCloseCreate}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'ADVERTISING_CHANNEL.NEW'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateBudgetForecastForm closeModal={handleCloseCreate} />
      </Modal.Body>
    </Modal>
  )
}

export {CreateBudgetForecastModal}
