import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IDiscountCode} from '../models/DiscountCode'
import {
  createDiscountCode,
  deleteDiscountCode,
  getDiscountCodeById,
  getDiscountCodesList,
  updateDiscountCode,
} from './DiscountCodesCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const DISCOUNT_CODES_REQUEST = 'DISCOUNT_CODES_REQUEST'
export const DISCOUNT_CODES_REQUEST_SUCCESS = 'DISCOUNT_CODES_REQUEST_SUCCESS'
export const DISCOUNT_CODES_REQUEST_FAIL = 'DISCOUNT_CODES_REQUEST_FAIL'

export const DISCOUNT_CODE_DELETE = 'DISCOUNT_CODE_DELETE'
export const DISCOUNT_CODE_DELETE_SUCCESS = 'DISCOUNT_CODE_DELETE_SUCCESS'
export const DISCOUNT_CODE_DELETE_FAIL = 'DISCOUNT_CODE_DELETE_ERROR'

export const DISCOUNT_CODE_CREATE_REQUEST = 'DISCOUNT_CODE_CREATE_REQUEST'
export const DISCOUNT_CODE_CREATE_SUCCESS = 'DISCOUNT_CODE_CREATE_SUCCESS'
export const DISCOUNT_CODE_CREATE_FAIL = 'DISCOUNT_CODE_CREATE_FAIL'

export const GET_DISCOUNT_CODE_TO_UPDATE = 'GET_DISCOUNT_CODE_TO_UPDATE'
export const GET_DISCOUNT_CODE_TO_UPDATE_REQUEST = 'GET_DISCOUNT_CODE_TO_UPDATE_REQUEST'
export const GET_DISCOUNT_CODE_TO_UPDATE_FAIL = 'GET_DISCOUNT_CODE_TO_UPDATE_FAIL'

export const PUT_DISCOUNT_CODE_UPDATE_REQUEST = 'PUT_DISCOUNT_CODE_UPDATE_REQUEST'
export const PUT_DISCOUNT_CODE_UPDATE_SUCCESS = 'PUT_DISCOUNT_CODE_UPDATE_SUCCESS'
export const PUT_DISCOUNT_CODE_UPDATE_FAIL = 'PUT_DISCOUNT_CODE_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IDiscountCodesState {
  getDiscountCodes?: IDiscountCode[]
  getDiscountCodesLoading: boolean
  getDiscountCodesError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create DiscountCode
  createNewDiscountCode?: IDiscountCode
  createDiscountCodeLoading: boolean
  createDiscountCodeError: boolean
  discountCodeIsCreate: boolean
  createDiscountCodeErrorMessage?: string[][]
  //get DiscountCode to update
  getDiscountCodeLoading: boolean
  getDiscountCodeError: boolean
  getDiscountCodeToUpdateId?: number
  getDiscountCodeToUpdateData?: IDiscountCode
  //DiscountCode update
  discountCodeUpdateData?: IDiscountCode
  discountCodeUpdateLoading: boolean
  discountCodeUpdateError: boolean
  discountCodeIsUpdate: boolean
  discountCodeUpdateErrorMessage?: string[][]
  //delete DiscountCode
  discountCodeIdToDelete?: number
  discountCodeDeleteError: boolean
}

const initialDiscountCodesState: IDiscountCodesState = {
  getDiscountCodes: [],
  getDiscountCodesLoading: false,
  getDiscountCodesError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  createNewDiscountCode: undefined,
  createDiscountCodeLoading: false,
  createDiscountCodeError: false,
  discountCodeIsCreate: false,
  createDiscountCodeErrorMessage: undefined,

  getDiscountCodeLoading: false,
  getDiscountCodeError: false,
  getDiscountCodeToUpdateId: undefined,
  getDiscountCodeToUpdateData: undefined,

  discountCodeUpdateData: undefined,
  discountCodeUpdateLoading: false,
  discountCodeUpdateError: false,
  discountCodeIsUpdate: false,
  discountCodeUpdateErrorMessage: undefined,

  discountCodeIdToDelete: undefined,
  discountCodeDeleteError: false,
}

export const reducer = (
  state: IDiscountCodesState = initialDiscountCodesState,
  action: ActionWithPayload<IDiscountCodesState>
) => {
  switch (action.type) {
    case DISCOUNT_CODES_REQUEST:
      return {...state, getDiscountCodesLoading: true}
    case DISCOUNT_CODES_REQUEST_SUCCESS:
      const discountCode = action.payload?.getDiscountCodes
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getDiscountCodes: discountCode,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getDiscountCodesLoading: false,
        getDiscountCodesError: false,
      }
    case DISCOUNT_CODES_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const changeFilter = action.payload?.filter
      return {...state, filter: changeFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //DiscountCode delete
    case DISCOUNT_CODE_DELETE:
      const discountCodeIdToDelete = action.payload?.discountCodeIdToDelete
      return {...state, discountCodeIdToDelete}
    case DISCOUNT_CODE_DELETE_SUCCESS:
      const listAfterDel = state.getDiscountCodes?.filter(
        (item) => item.id !== action.payload?.discountCodeIdToDelete
      )
      return {...state, getDiscountCodes: listAfterDel}
    case DISCOUNT_CODE_DELETE_FAIL:
      return {...state, discountCodeDeleteError: true}

    case DISCOUNT_CODE_CREATE_REQUEST:
      const createNewDiscountCode = action.payload?.createNewDiscountCode
      return {
        ...state,
        createDiscountCodeLoading: true,
        createNewDiscountCode: createNewDiscountCode,
        discountCodeIsCreate: false,
        createDiscountCodeError: false,
      }
    case DISCOUNT_CODE_CREATE_SUCCESS:
      return {...state, createDiscountCodeLoading: false, discountCodeIsCreate: true}
    case DISCOUNT_CODE_CREATE_FAIL:
      const discountCodeErrorMessage = action.payload?.createDiscountCodeErrorMessage
      return {
        ...state,
        createDiscountCodeErrorMessage: discountCodeErrorMessage,
        createDiscountCodeError: true,
        createDiscountCodeLoading: false,
        discountCodeIsCreate: false,
      }
    //get DiscountCode to update
    case GET_DISCOUNT_CODE_TO_UPDATE:
      const discountCodeIdToUpdate = action.payload?.getDiscountCodeToUpdateId
      return {
        ...state,
        getDiscountCodeToUpdateId: discountCodeIdToUpdate,
        getDiscountCodeToUpdateData: undefined,
        getDiscountCodeLoading: true,
      }
    case GET_DISCOUNT_CODE_TO_UPDATE_REQUEST:
      const discountCodeToUpdateData = action.payload?.getDiscountCodeToUpdateData
      return {
        ...state,
        getDiscountCodeToUpdateData: discountCodeToUpdateData,
        getDiscountCodeLoading: false,
      }
    case GET_DISCOUNT_CODE_TO_UPDATE_FAIL:
      return {
        ...state,
        getDiscountCodeLoading: false,
        getDiscountCodeError: true,
      }
    //put DiscountCode update
    case PUT_DISCOUNT_CODE_UPDATE_REQUEST:
      const discountCodeDataUpd = action.payload?.discountCodeUpdateData
      return {
        ...state,
        discountCodeUpdateLoading: true,
        discountCodeUpdateData: discountCodeDataUpd,
        discountCodeIsUpdate: false,
        discountCodeUpdateError: false,
      }
    case PUT_DISCOUNT_CODE_UPDATE_SUCCESS:
      return {
        ...state,
        discountCodeUpdateLoading: false,
        discountCodeIsUpdate: true,
      }
    case PUT_DISCOUNT_CODE_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.discountCodeUpdateErrorMessage
      return {
        ...state,
        discountCodeUpdateLoading: false,
        discountCodeUpdateError: true,
        discountCodeIsUpdate: false,
        discountCodeUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestDiscountCodes: () => ({type: DISCOUNT_CODES_REQUEST}),
  requestDiscountCodesSuccess: (
    discountCode: IDiscountCode[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: DISCOUNT_CODES_REQUEST_SUCCESS,
    payload: {getDiscountCodes: discountCode, currentPage, totalPages, perPage, totalItem},
  }),

  requestDiscountCodeFail: () => ({type: DISCOUNT_CODES_REQUEST_FAIL}),

  createDiscountCodeRequest: (createDiscountCode: IDiscountCode) => ({
    type: DISCOUNT_CODE_CREATE_REQUEST,
    payload: {createNewDiscountCode: createDiscountCode},
  }),

  createNewDiscountCode: () => ({
    type: DISCOUNT_CODE_CREATE_SUCCESS,
  }),

  createDiscountCodeFail: (err: any) => ({
    type: DISCOUNT_CODE_CREATE_FAIL,
    payload: {createDiscountCodeErrorMessage: err},
  }),

  changeDiscountCodeToUpdateId: (discountCodeId: number) => ({
    type: GET_DISCOUNT_CODE_TO_UPDATE,
    payload: {getDiscountCodeToUpdateId: discountCodeId},
  }),

  requestDiscountCodeToUpdateData: (getDiscountCodeToUpdateData: IDiscountCode) => ({
    type: GET_DISCOUNT_CODE_TO_UPDATE_REQUEST,
    payload: {getDiscountCodeToUpdateData},
  }),

  requestDiscountCodeToUpdateDataFail: () => ({
    type: GET_DISCOUNT_CODE_TO_UPDATE_FAIL,
  }),

  updateDiscountCodeData: (discountCodeData: IDiscountCode) => ({
    type: PUT_DISCOUNT_CODE_UPDATE_REQUEST,
    payload: {discountCodeUpdateData: discountCodeData},
  }),

  updateDiscountCodeDataSuccess: () => ({
    type: PUT_DISCOUNT_CODE_UPDATE_SUCCESS,
  }),

  requestDiscountCodeToUpdateFail: (err: any) => ({
    type: PUT_DISCOUNT_CODE_UPDATE_FAIL,
    payload: {discountCodeUpdateErrorMessage: err},
  }),

  discountCodeToDelete: (discountCodeToDelete: number) => ({
    type: DISCOUNT_CODE_DELETE,
    payload: {discountCodeIdToDelete: discountCodeToDelete},
  }),

  discountCodeToDeleteSuccces: (discountCodeIdToDelete: number) => ({
    type: DISCOUNT_CODE_DELETE_SUCCESS,
    payload: {discountCodeIdToDelete},
  }),

  discountCodeToDeleteFail: () => ({type: DISCOUNT_CODE_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getDiscountCodes: (state: RootState) => state.discountCode,
  getCurrentPage: (state: RootState) => state.discountCode.currentPage,
  getLimitPerPage: (state: RootState) => state.discountCode.perPage,
  getFilter: (state: RootState) => state.discountCode.filter,
  getTotalItem: (state: RootState) => state.discountCode.totalItem,
  getSearchText: (state: RootState) => state.discountCode.searchText,
  getDiscountCodeIDToDelete: (state: RootState) => state.discountCode.discountCodeIdToDelete,
  getDiscountCodeToCreate: (state: RootState) => state.discountCode.createNewDiscountCode,
  getCreateDiscountCodeData: (state: RootState) => {
    const {
      createDiscountCodeLoading,
      createDiscountCodeError,
      createDiscountCodeErrorMessage,
      discountCodeIsCreate,
    } = state.discountCode
    return {
      createDiscountCodeLoading,
      createDiscountCodeError,
      createDiscountCodeErrorMessage,
      discountCodeIsCreate,
    }
  },
  getUpdateDiscountCodeData: (state: RootState) => {
    const {
      discountCodeUpdateLoading,
      discountCodeUpdateError,
      discountCodeUpdateErrorMessage,
      discountCodeIsUpdate,
    } = state.discountCode
    return {
      discountCodeUpdateLoading,
      discountCodeUpdateError,
      discountCodeUpdateErrorMessage,
      discountCodeIsUpdate,
    }
  },
  getDiscountCodeToUpdateId: (state: RootState) => state.discountCode.getDiscountCodeToUpdateId,
  getDiscountCodeToUpdateData: (state: RootState) => state.discountCode.getDiscountCodeToUpdateData,
  getDiscountCodeDataToUpdate: (state: RootState) => state.discountCode.discountCodeUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getDiscountCodesList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(DISCOUNT_CODES_REQUEST, function* getDiscountCodesSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestDiscountCodesSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestDiscountCodeFail())
    }
  })

  yield takeLatest(DISCOUNT_CODE_DELETE, function* deleteDiscountCodeSaga() {
    try {
      const delDiscountCode: number = yield select(selectors.getDiscountCodeIDToDelete)
      yield deleteDiscountCode(delDiscountCode)
      yield put(actions.discountCodeToDeleteSuccces(delDiscountCode))
    } catch (error) {
      yield put(actions.discountCodeToDeleteFail())
    }
  })

  yield takeLatest(DISCOUNT_CODE_CREATE_REQUEST, function* createDiscountCodeSaga() {
    const newDiscountCode: IDiscountCode = yield select(selectors.getDiscountCodeToCreate)
    const {res, err} = yield createDiscountCode(newDiscountCode)
    if (res) {
      yield put(actions.createNewDiscountCode())
      const {data} = yield listUpdate()
      yield put(
        actions.requestDiscountCodesSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createDiscountCodeFail(errResArray))
      } else {
        yield put(actions.createDiscountCodeFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_DISCOUNT_CODE_TO_UPDATE, function* getUpdateDiscountCodeSaga() {
    try {
      const updDiscountCodeId: number = yield select(selectors.getDiscountCodeToUpdateId)
      const {data} = yield getDiscountCodeById(updDiscountCodeId)
      yield put(actions.requestDiscountCodeToUpdateData(data))
    } catch (err) {
      yield put(actions.requestDiscountCodeToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_DISCOUNT_CODE_UPDATE_REQUEST, function* updateDiscountCodeSaga() {
    const discountCode: IDiscountCode = yield select(selectors.getDiscountCodeDataToUpdate)
    const {res, err} = yield updateDiscountCode(discountCode)
    if (res) {
      yield put(actions.updateDiscountCodeDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestDiscountCodesSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestDiscountCodeToUpdateFail(errResArray))
      } else {
        yield put(actions.requestDiscountCodeToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
