import {ActionWithDefinePayload, ActionWithPayload, RootState} from '../../../../setup'
import {getCampaignsList} from './CampaignsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'
import {ICampaign} from '../models/Campaign'

export const SET_CLIENT = 'SET_CLIENT'
export const CAMPAIGN_REQUEST = 'CAMPAIGN_REQUEST'
export const CAMPAIGN_REQUEST_SUCCESS = 'CAMPAIGN_REQUEST_SUCCESS'
export const CAMPAIGN_REQUEST_FAIL = 'CAMPAIGN_REQUEST_FAIL'

export const CHANGE_CAMPAIGN_TYPE_REQUEST = 'CHANGE_CAMPAIGN_TYPE_REQUEST'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface ICampaignsState {
  getCampaigns?: ICampaign[]
  getCampaignsLoading: boolean
  getCampaignsError: boolean

  campaignUpdateData?: ICampaign
  client: string
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
}

const initialCampaignsState: ICampaignsState = {
  getCampaigns: [],
  getCampaignsLoading: false,
  getCampaignsError: false,

  campaignUpdateData: undefined,
  client: '',
  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
}

export const reducer = (
  state: ICampaignsState = initialCampaignsState,
  action: ActionWithPayload<ICampaignsState>
) => {
  switch (action.type) {
    case CAMPAIGN_REQUEST:
      return {...state, getCampaignsLoading: true}
    case CAMPAIGN_REQUEST_SUCCESS:
      const campaigns = action.payload?.getCampaigns
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getCampaigns: campaigns,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getCampaignsLoading: false,
        getCampaignsError: false,
      }
    case CAMPAIGN_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CAMPAIGN_TYPE_REQUEST:
      const type = action.payload?.currentPage
      return {...state, type: type}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    default:
      return state
  }
}

export const actions = {
  requestCampaigns: (clientId: string) => ({
    type: CAMPAIGN_REQUEST,
    payload: {clientId},
  }),
  requestCampaignsSuccess: (
    campaigns: ICampaign[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: CAMPAIGN_REQUEST_SUCCESS,
    payload: {getCampaigns: campaigns, currentPage, totalPages, perPage, totalItem},
  }),

  requestCampaignFail: () => ({type: CAMPAIGN_REQUEST_FAIL}),

  setClient: (client: string) => ({
    type: SET_CLIENT,
    payload: {client: client},
  }),

  changeCampaignType: (type: string) => ({
    type: CHANGE_CAMPAIGN_TYPE_REQUEST,
    payload: {type: type},
  }),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getCampaigns: (state: RootState) => state.campaigns,
  getCurrentPage: (state: RootState) => state.campaigns.currentPage,
  getLimitPerPage: (state: RootState) => state.campaigns.perPage,
  getFilter: (state: RootState) => state.campaigns.filter,
  getClient: (state: RootState) => state.campaigns.client,
  getTotalItem: (state: RootState) => state.campaigns.totalItem,
  getSearchText: (state: RootState) => state.campaigns.searchText,
}

function* listUpdate(clientId: string) {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getCampaignsList(clientId, page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(
    CAMPAIGN_REQUEST,
    function* getCampaignsSage(action: ActionWithDefinePayload<{clientId: string}>) {
      try {
        const {data} = yield listUpdate(action.payload.clientId)

        yield put(
          actions.requestCampaignsSuccess(
            data.data,
            data.current_page,
            data.last_page,
            data.per_page,
            data.total
          )
        )
      } catch (error) {
        yield put(actions.requestCampaignFail())
      }
    }
  )

  yield takeLatest(CHANGE_CAMPAIGN_TYPE_REQUEST, function* changeCampaignTypeSaga() {
    const clientId: string = yield select(selectors.getClient)
    yield put(actions.changeCampaignType(clientId))
  })
}
