import { Action } from 'redux';
import { getSubscriptionList } from "./SubscriptionCRUD";
import { ISubscription } from "../models/Subscription";
import { put, takeLatest, call } from 'redux-saga/effects';
import { RootState } from '../../../../setup/redux/RootReducer';
import { SagaIterator } from 'redux-saga';

export const SUBSCRIPTION_REQUEST = 'paymentSubscription/SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_REQUEST_SUCCESS = 'paymentSubscription/SUBSCRIPTION_REQUEST_SUCCESS';
export const SUBSCRIPTION_REQUEST_FAIL = 'paymentSubscription/SUBSCRIPTION_REQUEST_FAIL';

export interface ISubscriptionState {
    subscriptions: ISubscription[],
    loading: boolean,
    error: boolean
}

const initialState: ISubscriptionState = {
    subscriptions: [],
    loading: false,
    error: false,
}

interface SubscriptionRequestAction extends Action<typeof SUBSCRIPTION_REQUEST> {}
interface SubscriptionRequestSuccessAction extends Action<typeof SUBSCRIPTION_REQUEST_SUCCESS> {
    payload: { subscriptions: ISubscription[] }
}
interface SubscriptionRequestFailAction extends Action<typeof SUBSCRIPTION_REQUEST_FAIL> {}

type SubscriptionActionTypes =
    | SubscriptionRequestAction
    | SubscriptionRequestSuccessAction
    | SubscriptionRequestFailAction;

export const reducer = (
    state: ISubscriptionState = initialState,
    action: SubscriptionActionTypes
): ISubscriptionState => {
    switch (action.type) {
        case SUBSCRIPTION_REQUEST:
            console.log('Reducer action:', action.type);
            return { ...state, loading: true, error: false };
        case SUBSCRIPTION_REQUEST_SUCCESS:
            console.log('Reducer action:', action.type, action.payload);
            return {
                ...state,
                loading: false,
                subscriptions: action.payload.subscriptions
            };
        case SUBSCRIPTION_REQUEST_FAIL:
            console.log('Reducer action:', action.type);
            return { ...state, loading: false, error: true };
        default:
            return state;
    }
}

export const actions = {
    requestSubscriptions: (): SubscriptionRequestAction => ({ type: SUBSCRIPTION_REQUEST }),
    requestSubscriptionSuccess: (
        subscriptions: ISubscription[],
    ): SubscriptionRequestSuccessAction => ({
        type: SUBSCRIPTION_REQUEST_SUCCESS,
        payload: { subscriptions },
    }),
    requestSubscriptionsFail: (): SubscriptionRequestFailAction => ({ type: SUBSCRIPTION_REQUEST_FAIL }),
}

export const selectors = {
    getSubscriptionsState: (state: RootState) => state.paymentSubscription,
}

export function* saga(): SagaIterator {
    yield takeLatest(SUBSCRIPTION_REQUEST, fetchSubscriptionSaga);
}

function* fetchSubscriptionSaga(): SagaIterator {
    try {
        console.log('Fetching subscriptions...');
        const response = yield call(getSubscriptionList);
        const data: ISubscription[] = response.data;
        console.log('Fetched subscriptions:', data);
        yield put(actions.requestSubscriptionSuccess(data));
    } catch (error) {
        console.error('Error fetching subscriptions:', error);
        yield put(actions.requestSubscriptionsFail());
    }
}
