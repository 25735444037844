import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IClient} from '../models/Client'
import {deleteClient, getClientById, getClientsList, updateClient} from './ClientsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const CLIENT_REQUEST = 'CLIENT_REQUEST'
export const CLIENT_REQUEST_SUCCESS = 'CLIENT_REQUEST_SUCCESS'
export const CLIENT_REQUEST_FAIL = 'CLIENT_REQUEST_FAIL'

export const CLIENT_DELETE = 'CLIENT_DELETE'
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS'
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_ERROR'

export const GET_CLIENT_TO_UPDATE = 'GET_CLIENT_TO_UPDATE'
export const GET_CLIENT_TO_UPDATE_REQUEST = 'GET_CLIENT_TO_UPDATE_REQUEST'
export const GET_CLIENT_TO_UPDATE_FAIL = 'GET_CLIENT_TO_UPDATE_FAIL'

export const PUT_CLIENT_UPDATE_REQUEST = 'PUT_CLIENT_UPDATE_REQUEST'
export const PUT_CLIENT_UPDATE_SUCCESS = 'PUT_CLIENT_UPDATE_SUCCESS'
export const PUT_CLIENT_UPDATE_FAIL = 'PUT_CLIENT_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IClientsState {
  getClients?: IClient[]
  getClientsLoading: boolean
  getClientsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //get client to update
  getClientLoading: boolean
  getClientError: boolean
  getClientToUpdateId?: number
  getClientToUpdateData?: IClient
  //client update
  clientUpdateData?: IClient
  clientUpdateLoading: boolean
  clientUpdateError: boolean
  clientIsUpdate: boolean
  clientUpdateErrorMessage?: string[][]
  //delete client
  clientIdToDelete?: number
  clientDeleteError: boolean
}

const initialClientsState: IClientsState = {
  getClients: [],
  getClientsLoading: false,
  getClientsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  getClientLoading: false,
  getClientError: false,
  getClientToUpdateId: undefined,
  getClientToUpdateData: undefined,

  clientUpdateData: undefined,
  clientUpdateLoading: false,
  clientUpdateError: false,
  clientIsUpdate: false,
  clientUpdateErrorMessage: undefined,

  clientIdToDelete: undefined,
  clientDeleteError: false,
}

export const reducer = (
  state: IClientsState = initialClientsState,
  action: ActionWithPayload<IClientsState>
) => {
  switch (action.type) {
    case CLIENT_REQUEST:
      return {...state, getClientsLoading: true}
    case CLIENT_REQUEST_SUCCESS:
      const clients = action.payload?.getClients
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getClients: clients,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getClientsLoading: false,
        getClientsError: false,
      }
    case CLIENT_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //client delete
    case CLIENT_DELETE:
      const clientIdToDelete = action.payload?.clientIdToDelete
      return {...state, clientIdToDelete}
    case CLIENT_DELETE_SUCCESS:
      const listAfterDel = state.getClients?.filter(
        (item) => item.id !== action.payload?.clientIdToDelete
      )
      return {...state, getClients: listAfterDel}
    case CLIENT_DELETE_FAIL:
      return {...state, clientDeleteError: true}

    //get client to update
    case GET_CLIENT_TO_UPDATE:
      const clientIdToUpdate = action.payload?.getClientToUpdateId
      return {
        ...state,
        getClientToUpdateId: clientIdToUpdate,
        getClientToUpdateData: undefined,
        getClientLoading: true,
      }
    case GET_CLIENT_TO_UPDATE_REQUEST:
      const clientToUpdateData = action.payload?.getClientToUpdateData
      return {
        ...state,
        getClientToUpdateData: clientToUpdateData,
        getClientLoading: false,
      }
    case GET_CLIENT_TO_UPDATE_FAIL:
      return {
        ...state,
        getClientLoading: false,
        getClientError: true,
      }
    //put client update
    case PUT_CLIENT_UPDATE_REQUEST:
      const clientDataUpd = action.payload?.clientUpdateData
      return {
        ...state,
        clientUpdateLoading: true,
        clientUpdateData: clientDataUpd,
        clientIsUpdate: false,
        clientUpdateError: false,
      }
    case PUT_CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        clientUpdateLoading: false,
        clientIsUpdate: true,
      }
    case PUT_CLIENT_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.clientUpdateErrorMessage
      return {
        ...state,
        clientUpdateLoading: false,
        clientUpdateError: true,
        clientIsUpdate: false,
        clientUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestClients: () => ({type: CLIENT_REQUEST}),
  requestClientsSuccess: (
    clients: IClient[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: CLIENT_REQUEST_SUCCESS,
    payload: {getClients: clients, currentPage, totalPages, perPage, totalItem},
  }),

  requestClientFail: () => ({type: CLIENT_REQUEST_FAIL}),

  changeClientToUpdateId: (clientId: number) => ({
    type: GET_CLIENT_TO_UPDATE,
    payload: {getClientToUpdateId: clientId},
  }),

  requestClientToUpdateData: (getClientToUpdateData: IClient) => ({
    type: GET_CLIENT_TO_UPDATE_REQUEST,
    payload: {getClientToUpdateData},
  }),

  requestClientToUpdateDataFail: () => ({
    type: GET_CLIENT_TO_UPDATE_FAIL,
  }),

  updateClientData: (clientData: IClient) => ({
    type: PUT_CLIENT_UPDATE_REQUEST,
    payload: {clientUpdateData: clientData},
  }),

  updateClientDataSuccess: () => ({
    type: PUT_CLIENT_UPDATE_SUCCESS,
  }),

  requestClientToUpdateFail: (err: any) => ({
    type: PUT_CLIENT_UPDATE_FAIL,
    payload: {clientUpdateErrorMessage: err},
  }),

  clientToDelete: (clientToDelete: number) => ({
    type: CLIENT_DELETE,
    payload: {clientIdToDelete: clientToDelete},
  }),

  clientToDeleteSuccces: (clientIdToDelete: number) => ({
    type: CLIENT_DELETE_SUCCESS,
    payload: {clientIdToDelete},
  }),

  clientToDeleteFail: () => ({type: CLIENT_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getClients: (state: RootState) => state.clients,
  getCurrentPage: (state: RootState) => state.clients.currentPage,
  getLimitPerPage: (state: RootState) => state.clients.perPage,
  getFilter: (state: RootState) => state.clients.filter,
  getTotalItem: (state: RootState) => state.clients.totalItem,
  getSearchText: (state: RootState) => state.clients.searchText,
  getClientIDToDelete: (state: RootState) => state.clients.clientIdToDelete,
  getUpdateClientData: (state: RootState) => {
    const {clientUpdateLoading, clientUpdateError, clientUpdateErrorMessage, clientIsUpdate} =
      state.clients
    return {clientUpdateLoading, clientUpdateError, clientUpdateErrorMessage, clientIsUpdate}
  },
  getClientToUpdateId: (state: RootState) => state.clients.getClientToUpdateId,
  getClientToUpdateData: (state: RootState) => state.clients.getClientToUpdateData,
  getClientDataToUpdate: (state: RootState) => state.clients.clientUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getClientsList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(CLIENT_REQUEST, function* getClientsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestClientsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestClientFail())
    }
  })

  yield takeLatest(CLIENT_DELETE, function* deleteClientSaga() {
    try {
      const delClient: number = yield select(selectors.getClientIDToDelete)
      yield deleteClient(delClient)
      yield put(actions.clientToDeleteSuccces(delClient))
    } catch (error) {
      yield put(actions.clientToDeleteFail())
    }
  })

  yield takeLatest(GET_CLIENT_TO_UPDATE, function* getUpdateClientSaga() {
    try {
      const updClientId: number = yield select(selectors.getClientToUpdateId)
      const {data} = yield getClientById(updClientId)
      yield put(actions.requestClientToUpdateData(data))
    } catch (err) {
      yield put(actions.requestClientToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_CLIENT_UPDATE_REQUEST, function* updateClientSaga() {
    const client: IClient = yield select(selectors.getClientDataToUpdate)
    const {res, err} = yield updateClient(client)
    if (res) {
      yield put(actions.updateClientDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestClientsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestClientToUpdateFail(errResArray))
      } else {
        yield put(actions.requestClientToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
