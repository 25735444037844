import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IPriceList} from '../models/PriceList'
import {
  createPriceList,
  deletePriceList,
  getPriceListById,
  getPriceLists,
  updatePriceList,
} from './PriceListsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const PRICE_LIST_REQUEST = 'PRICE_LIST_REQUEST'
export const PRICE_LIST_REQUEST_SUCCESS = 'PRICE_LIST_REQUEST_SUCCESS'
export const PRICE_LIST_REQUEST_FAIL = 'PRICE_LIST_REQUEST_FAIL'

export const PRICE_LIST_DELETE = 'PRICE_LIST_DELETE'
export const PRICE_LIST_DELETE_SUCCESS = 'PRICE_LIST_DELETE_SUCCESS'
export const PRICE_LIST_DELETE_FAIL = 'PRICE_LIST_DELETE_ERROR'

export const PRICE_LIST_CREATE_REQUEST = 'PRICE_LIST_CREATE_REQUEST'
export const PRICE_LIST_CREATE_SUCCESS = 'PRICE_LIST_CREATE_SUCCESS'
export const PRICE_LIST_CREATE_FAIL = 'PRICE_LIST_CREATE_FAIL'

export const GET_PRICE_LIST_TO_UPDATE = 'GET_PRICE_LIST_TO_UPDATE'
export const GET_PRICE_LIST_TO_UPDATE_REQUEST = 'GET_PRICE_LIST_TO_UPDATE_REQUEST'
export const GET_PRICE_LIST_TO_UPDATE_FAIL = 'GET_PRICE_LIST_TO_UPDATE_FAIL'

export const PUT_PRICE_LIST_UPDATE_REQUEST = 'PUT_PRICE_LIST_UPDATE_REQUEST'
export const PUT_PRICE_LIST_UPDATE_SUCCESS = 'PUT_PRICE_LIST_UPDATE_SUCCESS'
export const PUT_PRICE_LIST_UPDATE_FAIL = 'PUT_PRICE_LIST_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IPriceListsState {
  getPriceLists?: IPriceList[]
  getPriceListsLoading: boolean
  getPriceListsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create priceList
  createNewPriceList?: IPriceList
  createPriceListLoading: boolean
  createPriceListError: boolean
  priceListIsCreate: boolean
  createPriceListErrorMessage?: string[][]
  //get priceList to update
  getPriceListLoading: boolean
  getPriceListError: boolean
  getPriceListToUpdateId?: number
  getPriceListToUpdateData?: IPriceList
  //priceList update
  priceListUpdateData?: IPriceList
  priceListUpdateLoading: boolean
  priceListUpdateError: boolean
  priceListIsUpdate: boolean
  priceListUpdateErrorMessage?: string[][]
  //delete priceList
  priceListIdToDelete?: number
  priceListDeleteError: boolean
}

const initialPriceListsState: IPriceListsState = {
  getPriceLists: [],
  getPriceListsLoading: false,
  getPriceListsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'title',
  totalItem: 0,
  searchText: undefined,

  createNewPriceList: undefined,
  createPriceListLoading: false,
  createPriceListError: false,
  priceListIsCreate: false,
  createPriceListErrorMessage: undefined,

  getPriceListLoading: false,
  getPriceListError: false,
  getPriceListToUpdateId: undefined,
  getPriceListToUpdateData: undefined,

  priceListUpdateData: undefined,
  priceListUpdateLoading: false,
  priceListUpdateError: false,
  priceListIsUpdate: false,
  priceListUpdateErrorMessage: undefined,

  priceListIdToDelete: undefined,
  priceListDeleteError: false,
}

export const reducer = (
  state: IPriceListsState = initialPriceListsState,
  action: ActionWithPayload<IPriceListsState>
) => {
  switch (action.type) {
    case PRICE_LIST_REQUEST:
      return {...state, getPriceListsLoading: true}
    case PRICE_LIST_REQUEST_SUCCESS:
      const priceLists = action.payload?.getPriceLists
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getPriceLists: priceLists,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getPriceListsLoading: false,
        getPriceListsError: false,
      }
    case PRICE_LIST_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //priceList delete
    case PRICE_LIST_DELETE:
      const priceListIdToDelete = action.payload?.priceListIdToDelete
      return {...state, priceListIdToDelete}
    case PRICE_LIST_DELETE_SUCCESS:
      const listAfterDel = state.getPriceLists?.filter(
        (item) => item.id !== action.payload?.priceListIdToDelete
      )
      return {...state, getPriceLists: listAfterDel}
    case PRICE_LIST_DELETE_FAIL:
      return {...state, priceListDeleteError: true}

    //priceList create
    case PRICE_LIST_CREATE_REQUEST:
      const createNewPriceList = action.payload?.createNewPriceList
      return {
        ...state,
        createPriceListLoading: true,
        createNewPriceList: createNewPriceList,
        priceListIsCreate: false,
        createPriceListError: false,
      }
    case PRICE_LIST_CREATE_SUCCESS:
      return {...state, createPriceListLoading: false, priceListIsCreate: true}
    case PRICE_LIST_CREATE_FAIL:
      const priceListErrorMessage = action.payload?.createPriceListErrorMessage
      return {
        ...state,
        createPriceListErrorMessage: priceListErrorMessage,
        createPriceListError: true,
        createPriceListLoading: false,
        priceListIsCreate: false,
      }
    //get priceList to update
    case GET_PRICE_LIST_TO_UPDATE:
      const priceListIdToUpdate = action.payload?.getPriceListToUpdateId
      return {
        ...state,
        getPriceListToUpdateId: priceListIdToUpdate,
        getPriceListToUpdateData: undefined,
        getPriceListLoading: true,
      }
    case GET_PRICE_LIST_TO_UPDATE_REQUEST:
      const priceListToUpdateData = action.payload?.getPriceListToUpdateData
      return {
        ...state,
        getPriceListToUpdateData: priceListToUpdateData,
        getPriceListLoading: false,
      }
    case GET_PRICE_LIST_TO_UPDATE_FAIL:
      return {
        ...state,
        getPriceListLoading: false,
        getPriceListError: true,
      }
    //put priceList update
    case PUT_PRICE_LIST_UPDATE_REQUEST:
      const priceListDataUpd = action.payload?.priceListUpdateData
      return {
        ...state,
        priceListUpdateLoading: true,
        priceListUpdateData: priceListDataUpd,
        priceListIsUpdate: false,
        priceListUpdateError: false,
      }
    case PUT_PRICE_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        priceListUpdateLoading: false,
        priceListIsUpdate: true,
      }
    case PUT_PRICE_LIST_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.priceListUpdateErrorMessage
      return {
        ...state,
        priceListUpdateLoading: false,
        priceListUpdateError: true,
        priceListIsUpdate: false,
        priceListUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestPriceLists: () => ({type: PRICE_LIST_REQUEST}),
  requestPriceListsSuccess: (
    priceLists: IPriceList[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: PRICE_LIST_REQUEST_SUCCESS,
    payload: {getPriceLists: priceLists, currentPage, totalPages, perPage, totalItem},
  }),

  requestPriceListFail: () => ({type: PRICE_LIST_REQUEST_FAIL}),

  createPriceListRequest: (createPriceList: IPriceList) => ({
    type: PRICE_LIST_CREATE_REQUEST,
    payload: {createNewPriceList: createPriceList},
  }),

  createNewPriceList: () => ({
    type: PRICE_LIST_CREATE_SUCCESS,
  }),

  createPriceListFail: (err: any) => ({
    type: PRICE_LIST_CREATE_FAIL,
    payload: {createPriceListErrorMessage: err},
  }),

  changePriceListToUpdateId: (priceListId: number) => ({
    type: GET_PRICE_LIST_TO_UPDATE,
    payload: {getPriceListToUpdateId: priceListId},
  }),

  requestPriceListToUpdateData: (getPriceListToUpdateData: IPriceList) => ({
    type: GET_PRICE_LIST_TO_UPDATE_REQUEST,
    payload: {getPriceListToUpdateData},
  }),

  requestPriceListToUpdateDataFail: () => ({
    type: GET_PRICE_LIST_TO_UPDATE_FAIL,
  }),

  updatePriceListData: (priceListData: IPriceList) => ({
    type: PUT_PRICE_LIST_UPDATE_REQUEST,
    payload: {priceListUpdateData: priceListData},
  }),

  updatePriceListDataSuccess: () => ({
    type: PUT_PRICE_LIST_UPDATE_SUCCESS,
  }),

  requestPriceListToUpdateFail: (err: any) => ({
    type: PUT_PRICE_LIST_UPDATE_FAIL,
    payload: {priceListUpdateErrorMessage: err},
  }),

  priceListToDelete: (priceListToDelete: number) => ({
    type: PRICE_LIST_DELETE,
    payload: {priceListIdToDelete: priceListToDelete},
  }),

  priceListToDeleteSuccces: (priceListIdToDelete: number) => ({
    type: PRICE_LIST_DELETE_SUCCESS,
    payload: {priceListIdToDelete},
  }),

  priceListToDeleteFail: () => ({type: PRICE_LIST_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getPriceLists: (state: RootState) => state.priceLists,
  getCurrentPage: (state: RootState) => state.priceLists.currentPage,
  getLimitPerPage: (state: RootState) => state.priceLists.perPage,
  getFilter: (state: RootState) => state.priceLists.filter,
  getTotalItem: (state: RootState) => state.priceLists.totalItem,
  getSearchText: (state: RootState) => state.priceLists.searchText,
  getPriceListIDToDelete: (state: RootState) => state.priceLists.priceListIdToDelete,
  getPriceListToCreate: (state: RootState) => state.priceLists.createNewPriceList,
  getCreatePriceListData: (state: RootState) => {
    const {
      createPriceListLoading,
      createPriceListError,
      createPriceListErrorMessage,
      priceListIsCreate,
    } = state.priceLists
    return {
      createPriceListLoading,
      createPriceListError,
      createPriceListErrorMessage,
      priceListIsCreate,
    }
  },
  getUpdatePriceListData: (state: RootState) => {
    const {
      priceListUpdateLoading,
      priceListUpdateError,
      priceListUpdateErrorMessage,
      priceListIsUpdate,
    } = state.priceLists
    return {
      priceListUpdateLoading,
      priceListUpdateError,
      priceListUpdateErrorMessage,
      priceListIsUpdate,
    }
  },
  getPriceListToUpdateId: (state: RootState) => state.priceLists.getPriceListToUpdateId,
  getPriceListToUpdateData: (state: RootState) => state.priceLists.getPriceListToUpdateData,
  getPriceListDataToUpdate: (state: RootState) => state.priceLists.priceListUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getPriceLists(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(PRICE_LIST_REQUEST, function* getPriceListsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestPriceListsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestPriceListFail())
    }
  })

  yield takeLatest(PRICE_LIST_DELETE, function* deletePriceListSaga() {
    try {
      const delPriceList: number = yield select(selectors.getPriceListIDToDelete)
      yield deletePriceList(delPriceList)
      yield put(actions.priceListToDeleteSuccces(delPriceList))
    } catch (error) {
      yield put(actions.priceListToDeleteFail())
    }
  })

  yield takeLatest(PRICE_LIST_CREATE_REQUEST, function* createPriceListSaga() {
    const newPriceList: IPriceList = yield select(selectors.getPriceListToCreate)
    const {res, err} = yield createPriceList(newPriceList)
    if (res) {
      yield put(actions.createNewPriceList())
      const {data} = yield listUpdate()
      yield put(
        actions.requestPriceListsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createPriceListFail(errResArray))
      } else {
        yield put(actions.createPriceListFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_PRICE_LIST_TO_UPDATE, function* getUpdatePriceListSaga() {
    try {
      const updPriceListId: number = yield select(selectors.getPriceListToUpdateId)
      const {data} = yield getPriceListById(updPriceListId)
      yield put(actions.requestPriceListToUpdateData(data))
    } catch (err) {
      yield put(actions.requestPriceListToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_PRICE_LIST_UPDATE_REQUEST, function* updatePriceListSaga() {
    const priceList: IPriceList = yield select(selectors.getPriceListDataToUpdate)
    const {res, err} = yield updatePriceList(priceList)
    if (res) {
      yield put(actions.updatePriceListDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestPriceListsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestPriceListToUpdateFail(errResArray))
      } else {
        yield put(actions.requestPriceListToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
