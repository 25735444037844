import axios from 'axios'
import { IAttribute } from '../../models/Attribute'

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const ATTRIBUTE_API = `${API_URL}/feedgen/attribute`

export async function getAttributesList(filter: string) {
    return await axios.get(`${ATTRIBUTE_API}?${filter}`)
}

export async function createAttribute(attribute: IAttribute) {
    return await axios.post(ATTRIBUTE_API, attribute)
}

export async function updateAttribute(id: number, attribute: Partial<IAttribute>) {
    return await axios.put(`${ATTRIBUTE_API}/${id}`, attribute)
}

export async function getAttribute(id: number) {
    return await axios.get(`${ATTRIBUTE_API}/${id}`)
}

export async function deleteAttribute(id: number) {
    return await axios.delete(`${ATTRIBUTE_API}/${id}`)
}
