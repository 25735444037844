import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IProject} from '../models/Project'
import {
  createProject,
  deleteProject,
  getProjectById,
  getProjectsList,
  updateProject,
} from './ProjectsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const PROJECT_REQUEST = 'PROJECT_REQUEST'
export const PROJECT_REQUEST_SUCCESS = 'PROJECT_REQUEST_SUCCESS'
export const PROJECT_REQUEST_FAIL = 'PROJECT_REQUEST_FAIL'

export const project_DELETE = 'project_DELETE'
export const project_DELETE_SUCCESS = 'project_DELETE_SUCCESS'
export const project_DELETE_FAIL = 'project_DELETE_ERROR'

export const project_CREATE_REQUEST = 'project_CREATE_REQUEST'
export const project_CREATE_SUCCESS = 'project_CREATE_SUCCESS'
export const project_CREATE_FAIL = 'project_CREATE_FAIL'

export const GET_project_TO_UPDATE = 'GET_project_TO_UPDATE'
export const GET_project_TO_UPDATE_REQUEST = 'GET_project_TO_UPDATE_REQUEST'
export const GET_project_TO_UPDATE_FAIL = 'GET_project_TO_UPDATE_FAIL'

export const PUT_project_UPDATE_REQUEST = 'PUT_project_UPDATE_REQUEST'
export const PUT_project_UPDATE_SUCCESS = 'PUT_project_UPDATE_SUCCESS'
export const PUT_project_UPDATE_FAIL = 'PUT_project_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IProjectsState {
  getProjects?: IProject[]
  getProjectsLoading: boolean
  getProjectsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create project
  createNewProject?: IProject
  createProjectLoading: boolean
  createProjectError: boolean
  projectIsCreate: boolean
  createProjectErrorMessage?: string[][]
  //get project to update
  getProjectLoading: boolean
  getProjectError: boolean
  getProjectToUpdateId?: number
  getProjectToUpdateData?: IProject
  //project update
  projectUpdateData?: IProject
  projectUpdateLoading: boolean
  projectUpdateError: boolean
  projectIsUpdate: boolean
  projectUpdateErrorMessage?: string[][]
  //delete project
  projectIdToDelete?: string
  projectDeleteError: boolean
}

const initialProjectsState: IProjectsState = {
  getProjects: [],
  getProjectsLoading: false,
  getProjectsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  createNewProject: undefined,
  createProjectLoading: false,
  createProjectError: false,
  projectIsCreate: false,
  createProjectErrorMessage: undefined,

  getProjectLoading: false,
  getProjectError: false,
  getProjectToUpdateId: undefined,
  getProjectToUpdateData: undefined,

  projectUpdateData: undefined,
  projectUpdateLoading: false,
  projectUpdateError: false,
  projectIsUpdate: false,
  projectUpdateErrorMessage: undefined,

  projectIdToDelete: undefined,
  projectDeleteError: false,
}

export const reducer = (
  state: IProjectsState = initialProjectsState,
  action: ActionWithPayload<IProjectsState>
) => {
  switch (action.type) {
    case PROJECT_REQUEST:
      return {...state, getProjectsLoading: true}
    case PROJECT_REQUEST_SUCCESS:
      const projects = action.payload?.getProjects
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getProjects: projects,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getProjectsLoading: false,
        getProjectsError: false,
      }
    case PROJECT_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //project delete
    case project_DELETE:
      const projectIdToDelete = action.payload?.projectIdToDelete
      return {...state, projectIdToDelete}
    case project_DELETE_SUCCESS:
      const listAfterDel = state.getProjects?.filter(
        (item) => item.id !== action.payload?.projectIdToDelete
      )
      return {...state, getProjects: listAfterDel}
    case project_DELETE_FAIL:
      return {...state, projectDeleteError: true}

    //project create
    case project_CREATE_REQUEST:
      const createNewProject = action.payload?.createNewProject
      return {
        ...state,
        createProjectLoading: true,
        createNewProject: createNewProject,
        projectIsCreate: false,
        createProjectError: false,
      }
    case project_CREATE_SUCCESS:
      return {...state, createProjectLoading: false, projectIsCreate: true}
    case project_CREATE_FAIL:
      const projectErrorMessage = action.payload?.createProjectErrorMessage
      return {
        ...state,
        createProjectErrorMessage: projectErrorMessage,
        createProjectError: true,
        createProjectLoading: false,
        projectIsCreate: false,
      }
    //get project to update
    case GET_project_TO_UPDATE:
      const projectIdToUpdate = action.payload?.getProjectToUpdateId
      return {
        ...state,
        getProjectToUpdateId: projectIdToUpdate,
        getProjectToUpdateData: undefined,
        getProjectLoading: true,
      }
    case GET_project_TO_UPDATE_REQUEST:
      const projectToUpdateData = action.payload?.getProjectToUpdateData
      return {
        ...state,
        getProjectToUpdateData: projectToUpdateData,
        getProjectLoading: false,
      }
    case GET_project_TO_UPDATE_FAIL:
      return {
        ...state,
        getProjectLoading: false,
        getProjectError: true,
      }
    //put project update
    case PUT_project_UPDATE_REQUEST:
      const projectDataUpd = action.payload?.projectUpdateData
      return {
        ...state,
        projectUpdateLoading: true,
        projectUpdateData: projectDataUpd,
        projectIsUpdate: false,
        projectUpdateError: false,
      }
    case PUT_project_UPDATE_SUCCESS:
      return {
        ...state,
        projectUpdateLoading: false,
        projectIsUpdate: true,
      }
    case PUT_project_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.projectUpdateErrorMessage
      return {
        ...state,
        projectUpdateLoading: false,
        projectUpdateError: true,
        projectIsUpdate: false,
        projectUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestProjects: () => ({type: PROJECT_REQUEST}),
  requestProjectsSuccess: (
    projects: IProject[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: PROJECT_REQUEST_SUCCESS,
    payload: {getProjects: projects, currentPage, totalPages, perPage, totalItem},
  }),

  requestProjectFail: () => ({type: PROJECT_REQUEST_FAIL}),

  createProjectRequest: (createProject: IProject) => ({
    type: project_CREATE_REQUEST,
    payload: {createNewProject: createProject},
  }),

  createNewProject: () => ({
    type: project_CREATE_SUCCESS,
  }),

  createProjectFail: (err: any) => ({
    type: project_CREATE_FAIL,
    payload: {createProjectErrorMessage: err},
  }),

  changeProjectToUpdateId: (projectId: string) => ({
    type: GET_project_TO_UPDATE,
    payload: {getProjectToUpdateId: projectId},
  }),

  requestProjectToUpdateData: (getProjectToUpdateData: IProject) => ({
    type: GET_project_TO_UPDATE_REQUEST,
    payload: {getProjectToUpdateData},
  }),

  requestProjectToUpdateDataFail: () => ({
    type: GET_project_TO_UPDATE_FAIL,
  }),

  updateProjectData: (projectData: IProject) => ({
    type: PUT_project_UPDATE_REQUEST,
    payload: {projectUpdateData: projectData},
  }),

  updateProjectDataSuccess: () => ({
    type: PUT_project_UPDATE_SUCCESS,
  }),

  requestProjectToUpdateFail: (err: any) => ({
    type: PUT_project_UPDATE_FAIL,
    payload: {projectUpdateErrorMessage: err},
  }),

  projectToDelete: (projectToDelete: string) => ({
    type: project_DELETE,
    payload: {projectIdToDelete: projectToDelete},
  }),

  projectToDeleteSuccces: (projectIdToDelete: string) => ({
    type: project_DELETE_SUCCESS,
    payload: {projectIdToDelete},
  }),

  projectToDeleteFail: () => ({type: project_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getProjects: (state: RootState) => state.projects,
  getCurrentPage: (state: RootState) => state.projects.currentPage,
  getLimitPerPage: (state: RootState) => state.projects.perPage,
  getFilter: (state: RootState) => state.projects.filter,
  getTotalItem: (state: RootState) => state.projects.totalItem,
  getSearchText: (state: RootState) => state.projects.searchText,
  getProjectIDToDelete: (state: RootState) => state.projects.projectIdToDelete,
  getProjectToCreate: (state: RootState) => state.projects.createNewProject,
  getUpdateProjectData: (state: RootState) => {
    const {projectUpdateLoading, projectUpdateError, projectUpdateErrorMessage, projectIsUpdate} =
      state.projects
    return {projectUpdateLoading, projectUpdateError, projectUpdateErrorMessage, projectIsUpdate}
  },
  getProjectToUpdateId: (state: RootState) => state.projects.getProjectToUpdateId,
  getProjectToUpdateData: (state: RootState) => state.projects.getProjectToUpdateData,
  getProjectDataToUpdate: (state: RootState) => state.projects.projectUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getProjectsList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(PROJECT_REQUEST, function* getProjectsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestProjectsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestProjectFail())
    }
  })

  yield takeLatest(project_DELETE, function* deleteProjectSaga() {
    try {
      const delProject: string = yield select(selectors.getProjectIDToDelete)
      yield deleteProject(delProject)
      yield put(actions.projectToDeleteSuccces(delProject))
    } catch (error) {
      yield put(actions.projectToDeleteFail())
    }
  })

  yield takeLatest(project_CREATE_REQUEST, function* createProjectSaga() {
    const newProject: IProject = yield select(selectors.getProjectToCreate)
    const {res, err} = yield createProject(newProject)
    if (res) {
      yield put(actions.createNewProject())
      const {data} = yield listUpdate()
      yield put(
        actions.requestProjectsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createProjectFail(errResArray))
      } else {
        yield put(actions.createProjectFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_project_TO_UPDATE, function* getUpdateProjectSaga() {
    try {
      const updProjectId: string = yield select(selectors.getProjectToUpdateId)
      const {data} = yield getProjectById(updProjectId)
      yield put(actions.requestProjectToUpdateData(data))
    } catch (err) {
      yield put(actions.requestProjectToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_project_UPDATE_REQUEST, function* updateProjectSaga() {
    const project: IProject = yield select(selectors.getProjectDataToUpdate)
    const {res, err} = yield updateProject(project)
    if (res) {
      yield put(actions.updateProjectDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestProjectsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestProjectToUpdateFail(errResArray))
      } else {
        yield put(actions.requestProjectToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
