import axios from 'axios'
import { ICountry } from "../models/Country"

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const COUNTRY_API = `${API_URL}/country`

export async function createCountry(newCountry: ICountry) {
    try {
        const res = await axios.post(`${COUNTRY_API}`, {
            ...newCountry,
            languages: JSON.stringify(newCountry.languages)
        })
        return res.data
    } catch (err) {
        throw new Error(`Error creating country: ${err}`)
    }
}

export async function updateCountry(id: number, updatedCountry: ICountry) {
    try {
        const res = await axios.put(`${COUNTRY_API}/${id}`, updatedCountry)
        return res.data
    } catch (err) {
        throw new Error(`Error updating country: ${err}`)
    }
}

export async function fetchCountries(page: number, limit: number) {
    try {
        const res = await axios.get(`${COUNTRY_API}?page=${page}&limit=${limit}`)
        return res.data
    } catch (err) {
        throw new Error(`Error fetching countries: ${err}`)
    }
}

export async function fetchCountryById(id: number) {
    try {
        const res = await axios.get(`${COUNTRY_API}/${id}`)
        return res.data
    } catch (err) {
        throw new Error(`Error fetching country: ${err}`)
    }
}

export async function deleteCountry(id: number) {
    try {
        await axios.delete(`${COUNTRY_API}/${id}`)
    } catch (err) {
        throw new Error(`Error deleting country: ${err}`)
    }
}