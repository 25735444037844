import axios from 'axios'
import {ICampaign} from '../models/Campaign'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const CAMPAIGN_API = `${API_URL}/campaigns`

export async function getCampaignsList(
  clientId: string,
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${CAMPAIGN_API}?client_id=${clientId}&search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(
    `${CAMPAIGN_API}?client_id=${clientId}&page=${page}&per-page=${perPage}&${filter}`
  )
}

export async function changeCampaignType(campaignData: ICampaign) {
  return await axios.put(`${CAMPAIGN_API}/change/type`, campaignData)
}

export async function changeCampaignStatus(campaignData: ICampaign) {
  return await axios.put(`${CAMPAIGN_API}/change/status`, campaignData)
}
