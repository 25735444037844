import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const GOOGLE_CATEGORY_API = `${API_URL}/feedgen/google-category`

export async function getGoogleCategoriesList(filter: string) {
    const url = `${GOOGLE_CATEGORY_API}?${filter}`
    return await axios.get(url)
}

export async function getGoogleCategory(id: number) {
    return await axios.get(`${GOOGLE_CATEGORY_API}/${id}`)
}

export async function updateGoogleCategory(industry_id: number, attribute_ids: number[]) {
    return await axios.post(`${GOOGLE_CATEGORY_API}`, { industry_id, attribute_ids })
}
