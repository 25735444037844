import { IPrompt } from '../../models/Prompt'
import { ActionWithPayload, RootState } from '../../../../../setup'
import { createPrompt, getPromptsList, updatePrompt } from './PromptCRUD'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'

export const PROMPT_REQUEST = 'PROMPT_REQUEST'
export const PROMPT_REQUEST_SUCCESS = 'PROMPT_REQUEST_SUCCESS'
export const PROMPT_REQUEST_FAIL = 'PROMPT_REQUEST_FAIL'
export const CHANGE_PROMPT_FILTER = 'CHANGE_PROMPT_FILTER'

export const PROMPT_CREATE_REQUEST = 'PROMPT_CREATE_REQUEST'
export const PROMPT_CREATE_SUCCESS = 'PROMPT_CREATE_SUCCESS'
export const PROMPT_CREATE_FAIL = 'PROMPT_CREATE_FAIL'

export const PROMPT_UPDATE_REQUEST = 'PROMPT_UPDATE_REQUEST'
export const PROMPT_UPDATE_SUCCESS = 'PROMPT_UPDATE_SUCCESS'
export const PROMPT_UPDATE_FAIL = 'PROMPT_UPDATE_FAIL'

export interface IPromptState {
    prompts: IPrompt[]
    loading: boolean
    error: boolean
    filter: string
    createPromptError?: boolean
    promptIsCreated?: boolean
    updatePromptError?: boolean
    promptIsUpdated?: boolean
}

const initialState: IPromptState = {
    prompts: [],
    loading: false,
    error: false,
    filter: 'sort=industry.name',
    updatePromptError: false,
    promptIsUpdated: false,
}

export const reducer = (
    state: IPromptState = initialState,
    action: ActionWithPayload<IPromptState>
) => {
    switch (action.type) {
        case PROMPT_REQUEST:
            return { ...state, loading: true }
        case PROMPT_REQUEST_SUCCESS:
            return {
                ...state,
                prompts: action.payload?.prompts || [],
                loading: false,
                error: false,
            }
        case PROMPT_REQUEST_FAIL:
            return { ...state, loading: false, error: true }
        case CHANGE_PROMPT_FILTER:
            return { ...state, filter: action.payload?.filter || state.filter }
        case PROMPT_CREATE_REQUEST:
        case PROMPT_UPDATE_REQUEST:
            return { ...state, loading: true }
        case PROMPT_CREATE_SUCCESS:
        case PROMPT_UPDATE_SUCCESS:
            return { ...state, loading: false, error: false }
        case PROMPT_CREATE_FAIL:
        case PROMPT_UPDATE_FAIL:
            return { ...state, loading: false, error: true }
        default:
            return state
    }
}

export const actions = {
    requestPrompts: () => ({ type: PROMPT_REQUEST }),
    requestPromptsSuccess: (prompts: IPrompt[]) => ({
        type: PROMPT_REQUEST_SUCCESS,
        payload: { prompts },
    }),
    requestPromptFail: () => ({ type: PROMPT_REQUEST_FAIL }),

    changePromptFilter: (newFilter: string) => ({
        type: CHANGE_PROMPT_FILTER,
        payload: { filter: newFilter },
    }),

    createPrompt: (prompt: IPrompt) => ({
        type: PROMPT_CREATE_REQUEST,
        payload: prompt,
    }),
    createPromptSuccess: () => ({ type: PROMPT_CREATE_SUCCESS }),
    createPromptFail: () => ({ type: PROMPT_CREATE_FAIL }),

    updatePrompt: (id: number, prompt: Partial<IPrompt>) => ({
        type: PROMPT_UPDATE_REQUEST,
        payload: { id, prompt },
    }),
    updatePromptSuccess: () => ({ type: PROMPT_UPDATE_SUCCESS }),
    updatePromptFail: () => ({ type: PROMPT_UPDATE_FAIL }),
}

export const selectors = {
    getPrompts: (state: RootState) => state.prompts.prompts,
    getFilter: (state: RootState) => state.prompts.filter,
}

function* fetchPrompts(): SagaIterator {
    try {
        const filter: string = yield select(selectors.getFilter)

        const response = yield call(getPromptsList, filter)
        const data = response.data

        const promptsArray: IPrompt[] = data.data || data

        yield put(actions.requestPromptsSuccess(promptsArray))
    } catch (error) {
        yield put(actions.requestPromptFail())
    }
}

function* createPromptSaga(action: PayloadAction<IPrompt>): SagaIterator {
    if (!action.payload) return
    try {
        yield call(() => createPrompt(action.payload))
        yield put(actions.createPromptSuccess())
        yield put(actions.requestPrompts())
    } catch (error) {
        yield put(actions.createPromptFail())
    }
}

function* updatePromptSaga(action: PayloadAction<{ id: number; prompt: Partial<IPrompt> }>): SagaIterator {
    if (!action.payload || !action.payload.id || !action.payload.prompt) return
    try {
        yield call(() => updatePrompt(action.payload.id, action.payload.prompt))
        yield put(actions.updatePromptSuccess())
        yield put(actions.requestPrompts())
    } catch (error) {
        yield put(actions.updatePromptFail())
    }
}

export function* saga(): SagaIterator {
    yield takeLatest(PROMPT_REQUEST, fetchPrompts)
    yield takeLatest(PROMPT_CREATE_REQUEST, createPromptSaga)
    yield takeLatest(PROMPT_UPDATE_REQUEST, updatePromptSaga)
}
