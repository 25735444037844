import axios from 'axios'
import {IBudgetForecast} from '../models/BudgetForecast'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const BUDGET_FORECAST_API = `${API_URL}/budgetForecasts`

export async function getBudgetForecastsList(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${BUDGET_FORECAST_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${BUDGET_FORECAST_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getBudgetForecastById(budgetForecastId: number) {
  return await axios.get(`${BUDGET_FORECAST_API}/${budgetForecastId}`)
}

export async function deleteBudgetForecast(budgetForecastId: number) {
  return await axios.delete(`${BUDGET_FORECAST_API}/${budgetForecastId}`)
}

export async function updateBudgetForecast(updateBudgetForecast: IBudgetForecast) {
  return await axios
    .put(`${BUDGET_FORECAST_API}/${updateBudgetForecast.id}`, updateBudgetForecast)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createBudgetForecast(newBudgetForecast: IBudgetForecast) {
  return await axios
    .post(`${BUDGET_FORECAST_API}`, newBudgetForecast)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
