import axios from 'axios'
import {ISubscription} from '../models/Subscription'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const SUBSCRIPTION_API = `${API_URL}/subscriptions`

export async function getSubscriptionsList(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${SUBSCRIPTION_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${SUBSCRIPTION_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getSubscriptionById(subcriptionId: string) {
  return await axios.get(`${SUBSCRIPTION_API}/${subcriptionId}`)
}

export async function deleteSubscription(subcriptionId: string) {
  return await axios.delete(`${SUBSCRIPTION_API}/${subcriptionId}`)
}

export async function updateSubscription(updateSubscription: ISubscription) {
  return await axios
    .put(`${SUBSCRIPTION_API}/${updateSubscription.id}`, updateSubscription)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
