import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {ISetting} from '../models/Setting'
import {
  createSetting,
  deleteSetting,
  getSettingById,
  getSettings,
  updateSetting,
} from './SettingsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const SETTING_REQUEST = 'SETTING_REQUEST'
export const SETTING_REQUEST_SUCCESS = 'SETTING_REQUEST_SUCCESS'
export const SETTING_REQUEST_FAIL = 'SETTING_REQUEST_FAIL'

export const SETTING_DELETE = 'SETTING_DELETE'
export const SETTING_DELETE_SUCCESS = 'SETTING_DELETE_SUCCESS'
export const SETTING_DELETE_FAIL = 'SETTING_DELETE_ERROR'

export const SETTING_CREATE_REQUEST = 'SETTING_CREATE_REQUEST'
export const SETTING_CREATE_SUCCESS = 'SETTING_CREATE_SUCCESS'
export const SETTING_CREATE_FAIL = 'SETTING_CREATE_FAIL'

export const GET_SETTING_TO_UPDATE = 'GET_SETTING_TO_UPDATE'
export const GET_SETTING_TO_UPDATE_REQUEST = 'GET_SETTING_TO_UPDATE_REQUEST'
export const GET_SETTING_TO_UPDATE_FAIL = 'GET_SETTING_TO_UPDATE_FAIL'

export const PUT_SETTING_UPDATE_REQUEST = 'PUT_SETTING_UPDATE_REQUEST'
export const PUT_SETTING_UPDATE_SUCCESS = 'PUT_SETTING_UPDATE_SUCCESS'
export const PUT_SETTING_UPDATE_FAIL = 'PUT_SETTING_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface ISettingsState {
  getSettings?: ISetting[]
  getSettingsLoading: boolean
  getSettingsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create setting
  createNewSetting?: ISetting
  createSettingLoading: boolean
  createSettingError: boolean
  settingIsCreate: boolean
  createSettingErrorMessage?: string[][]
  //get setting to update
  getSettingLoading: boolean
  getSettingError: boolean
  getSettingToUpdateId?: number
  getSettingToUpdateData?: ISetting
  //setting update
  settingUpdateData?: ISetting
  settingUpdateLoading: boolean
  settingUpdateError: boolean
  settingIsUpdate: boolean
  settingUpdateErrorMessage?: string[][]
  //delete setting
  settingIdToDelete?: number
  settingDeleteError: boolean
}

const initialSettingsState: ISettingsState = {
  getSettings: [],
  getSettingsLoading: false,
  getSettingsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'title',
  totalItem: 0,
  searchText: undefined,

  createNewSetting: undefined,
  createSettingLoading: false,
  createSettingError: false,
  settingIsCreate: false,
  createSettingErrorMessage: undefined,

  getSettingLoading: false,
  getSettingError: false,
  getSettingToUpdateId: undefined,
  getSettingToUpdateData: undefined,

  settingUpdateData: undefined,
  settingUpdateLoading: false,
  settingUpdateError: false,
  settingIsUpdate: false,
  settingUpdateErrorMessage: undefined,

  settingIdToDelete: undefined,
  settingDeleteError: false,
}

export const reducer = (
  state: ISettingsState = initialSettingsState,
  action: ActionWithPayload<ISettingsState>
) => {
  switch (action.type) {
    case SETTING_REQUEST:
      return {...state, getSettingsLoading: true}
    case SETTING_REQUEST_SUCCESS:
      const settings = action.payload?.getSettings
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getSettings: settings,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getSettingsLoading: false,
        getSettingsError: false,
      }
    case SETTING_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //setting delete
    case SETTING_DELETE:
      const settingIdToDelete = action.payload?.settingIdToDelete
      return {...state, settingIdToDelete}
    case SETTING_DELETE_SUCCESS:
      const listAfterDel = state.getSettings?.filter(
        (item) => item.id !== action.payload?.settingIdToDelete
      )
      return {...state, getSettings: listAfterDel}
    case SETTING_DELETE_FAIL:
      return {...state, settingDeleteError: true}

    //setting create
    case SETTING_CREATE_REQUEST:
      const createNewSetting = action.payload?.createNewSetting
      return {
        ...state,
        createSettingLoading: true,
        createNewSetting: createNewSetting,
        settingIsCreate: false,
        createSettingError: false,
      }
    case SETTING_CREATE_SUCCESS:
      return {...state, createSettingLoading: false, settingIsCreate: true}
    case SETTING_CREATE_FAIL:
      const settingErrorMessage = action.payload?.createSettingErrorMessage
      return {
        ...state,
        createSettingErrorMessage: settingErrorMessage,
        createSettingError: true,
        createSettingLoading: false,
        settingIsCreate: false,
      }
    //get setting to update
    case GET_SETTING_TO_UPDATE:
      const settingIdToUpdate = action.payload?.getSettingToUpdateId
      return {
        ...state,
        getSettingToUpdateId: settingIdToUpdate,
        getSettingToUpdateData: undefined,
        getSettingLoading: true,
      }
    case GET_SETTING_TO_UPDATE_REQUEST:
      const settingToUpdateData = action.payload?.getSettingToUpdateData
      return {
        ...state,
        getSettingToUpdateData: settingToUpdateData,
        getSettingLoading: false,
      }
    case GET_SETTING_TO_UPDATE_FAIL:
      return {
        ...state,
        getSettingLoading: false,
        getSettingError: true,
      }
    //put setting update
    case PUT_SETTING_UPDATE_REQUEST:
      const settingDataUpd = action.payload?.settingUpdateData
      return {
        ...state,
        settingUpdateLoading: true,
        settingUpdateData: settingDataUpd,
        settingIsUpdate: false,
        settingUpdateError: false,
      }
    case PUT_SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        settingUpdateLoading: false,
        settingIsUpdate: true,
      }
    case PUT_SETTING_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.settingUpdateErrorMessage
      return {
        ...state,
        settingUpdateLoading: false,
        settingUpdateError: true,
        settingIsUpdate: false,
        settingUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestSettings: () => ({type: SETTING_REQUEST}),
  requestSettingsSuccess: (
    settings: ISetting[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: SETTING_REQUEST_SUCCESS,
    payload: {getSettings: settings, currentPage, totalPages, perPage, totalItem},
  }),

  requestSettingFail: () => ({type: SETTING_REQUEST_FAIL}),

  createSettingRequest: (createSetting: ISetting) => ({
    type: SETTING_CREATE_REQUEST,
    payload: {createNewSetting: createSetting},
  }),

  createNewSetting: () => ({
    type: SETTING_CREATE_SUCCESS,
  }),

  createSettingFail: (err: any) => ({
    type: SETTING_CREATE_FAIL,
    payload: {createSettingErrorMessage: err},
  }),

  changeSettingToUpdateId: (settingId: number) => ({
    type: GET_SETTING_TO_UPDATE,
    payload: {getSettingToUpdateId: settingId},
  }),

  requestSettingToUpdateData: (getSettingToUpdateData: ISetting) => ({
    type: GET_SETTING_TO_UPDATE_REQUEST,
    payload: {getSettingToUpdateData},
  }),

  requestSettingToUpdateDataFail: () => ({
    type: GET_SETTING_TO_UPDATE_FAIL,
  }),

  updateSettingData: (settingData: ISetting) => ({
    type: PUT_SETTING_UPDATE_REQUEST,
    payload: {settingUpdateData: settingData},
  }),

  updateSettingDataSuccess: () => ({
    type: PUT_SETTING_UPDATE_SUCCESS,
  }),

  requestSettingToUpdateFail: (err: any) => ({
    type: PUT_SETTING_UPDATE_FAIL,
    payload: {settingUpdateErrorMessage: err},
  }),

  settingToDelete: (settingToDelete: number) => ({
    type: SETTING_DELETE,
    payload: {settingIdToDelete: settingToDelete},
  }),

  settingToDeleteSuccces: (settingIdToDelete: number) => ({
    type: SETTING_DELETE_SUCCESS,
    payload: {settingIdToDelete},
  }),

  settingToDeleteFail: () => ({type: SETTING_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getSettings: (state: RootState) => state.settings,
  getCurrentPage: (state: RootState) => state.settings.currentPage,
  getLimitPerPage: (state: RootState) => state.settings.perPage,
  getFilter: (state: RootState) => state.settings.filter,
  getTotalItem: (state: RootState) => state.settings.totalItem,
  getSearchText: (state: RootState) => state.settings.searchText,
  getSettingIDToDelete: (state: RootState) => state.settings.settingIdToDelete,
  getSettingToCreate: (state: RootState) => state.settings.createNewSetting,
  getCreateSettingData: (state: RootState) => {
    const {createSettingLoading, createSettingError, createSettingErrorMessage, settingIsCreate} =
      state.settings
    return {
      createSettingLoading,
      createSettingError,
      createSettingErrorMessage,
      settingIsCreate,
    }
  },
  getUpdateSettingData: (state: RootState) => {
    const {settingUpdateLoading, settingUpdateError, settingUpdateErrorMessage, settingIsUpdate} =
      state.settings
    return {
      settingUpdateLoading,
      settingUpdateError,
      settingUpdateErrorMessage,
      settingIsUpdate,
    }
  },
  getSettingToUpdateId: (state: RootState) => state.settings.getSettingToUpdateId,
  getSettingToUpdateData: (state: RootState) => state.settings.getSettingToUpdateData,
  getSettingDataToUpdate: (state: RootState) => state.settings.settingUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getSettings(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(SETTING_REQUEST, function* getSettingsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestSettingsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestSettingFail())
    }
  })

  yield takeLatest(SETTING_DELETE, function* deleteSettingSaga() {
    try {
      const delSetting: number = yield select(selectors.getSettingIDToDelete)
      yield deleteSetting(delSetting)
      yield put(actions.settingToDeleteSuccces(delSetting))
    } catch (error) {
      yield put(actions.settingToDeleteFail())
    }
  })

  yield takeLatest(SETTING_CREATE_REQUEST, function* createSettingSaga() {
    const newSetting: ISetting = yield select(selectors.getSettingToCreate)
    const {res, err} = yield createSetting(newSetting)
    if (res) {
      yield put(actions.createNewSetting())
      const {data} = yield listUpdate()
      yield put(
        actions.requestSettingsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createSettingFail(errResArray))
      } else {
        yield put(actions.createSettingFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_SETTING_TO_UPDATE, function* getUpdateSettingSaga() {
    try {
      const updSettingId: number = yield select(selectors.getSettingToUpdateId)
      const {data} = yield getSettingById(updSettingId)
      yield put(actions.requestSettingToUpdateData(data))
    } catch (err) {
      yield put(actions.requestSettingToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_SETTING_UPDATE_REQUEST, function* updateSettingSaga() {
    const setting: ISetting = yield select(selectors.getSettingDataToUpdate)
    const {res, err} = yield updateSetting(setting)
    if (res) {
      yield put(actions.updateSettingDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestSettingsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestSettingToUpdateFail(errResArray))
      } else {
        yield put(actions.requestSettingToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
