import axios from 'axios'
import {IUserWithPassword} from '../models/User'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const USERS_API = `${API_URL}/users`

export async function getUsersList(page: number, perPage: number, filter: string, search?: string) {
  if (search)
    return await axios.get(
      `${USERS_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${USERS_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getUserById(userId: number) {
  return await axios.get(`${USERS_API}/${userId}`)
}

export async function deleteUser(userId: number) {
  return await axios.delete(`${USERS_API}/${userId}`)
}

export async function updateUser(updateUser: IUserWithPassword) {
  return await axios
    .put(`${USERS_API}/${updateUser.id}`, updateUser)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createUser(newUser: IUserWithPassword) {
  return await axios
    .post(`${USERS_API}`, newUser)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
