import axios from 'axios'
import {IUserPayment} from '../models/UserPayment'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const PAYMENTS_API = `${API_URL}/payments`

export async function getUserPaymentsList(clientId: string) {
  return await axios.get(`${PAYMENTS_API}?clientId=${clientId}`)
}

export async function getPaymentById(clientId: string) {
  return await axios.get(`${PAYMENTS_API}/${clientId}`)
}

export async function updatePayment(updatePayment: IUserPayment) {
  return await axios
    .put(`${PAYMENTS_API}/${updatePayment.id}`, updatePayment)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
