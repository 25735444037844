import axios from 'axios'
import {IDiscountCode} from '../models/DiscountCode'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const DISCOUNT_CODE_API = `${API_URL}/discountCodes`

export async function getDiscountCodesList(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${DISCOUNT_CODE_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${DISCOUNT_CODE_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getDiscountCodeById(discountCodeId: number) {
  return await axios.get(`${DISCOUNT_CODE_API}/${discountCodeId}`)
}

export async function deleteDiscountCode(discountCodeId: number) {
  return await axios.delete(`${DISCOUNT_CODE_API}/${discountCodeId}`)
}

export async function updateDiscountCode(updateDiscountCode: IDiscountCode) {
  return await axios
    .put(`${DISCOUNT_CODE_API}/${updateDiscountCode.id}`, updateDiscountCode)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createDiscountCode(newDiscountCode: IDiscountCode) {
  return await axios
    .post(`${DISCOUNT_CODE_API}`, newDiscountCode)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
