import axios from 'axios'
import {IUserWithPassword} from '../../../../users/models/User'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const PROFILE_API = `${API_URL}/me`

export async function updateProfile(user: IUserWithPassword, errCallback: any) {
  return await axios
    .post(PROFILE_API, user)
    .then((res) => ({res}))
    .catch((err) => {
      if ({err} && err.response.data && typeof errCallback === 'function') {
        errCallback(err.response.data)
      }
      return {err}
    })
}
