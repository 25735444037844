/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import EditBudgetForecastForm from './EditBudgetForecastForm'
import {IBudgetForecast} from '../models/BudgetForecast'

interface EditBudgetForecastModalProps {
  showEditModal: boolean
  handleCloseEdit: () => void
  budgetForecast: IBudgetForecast
}

const EditBudgetForecastModal: React.FC<EditBudgetForecastModalProps> = ({
  showEditModal,
  handleCloseEdit,
  budgetForecast,
}) => {
  const intl = useIntl()
  return (
    <Modal size={'xl'} show={showEditModal} onHide={handleCloseEdit}>
      <Modal.Header closeButton>
        <Modal.Title>{`${intl.formatMessage({id: 'ADVERTISING_CHANNEL.EDIT'})}: ${
          budgetForecast.industry
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditBudgetForecastForm
          closeModal={handleCloseEdit}
          budgetForecastId={budgetForecast.id as number}
        />
      </Modal.Body>
    </Modal>
  )
}

export {EditBudgetForecastModal}
