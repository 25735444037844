import axios from 'axios'
import {IAdvertisingChannel} from '../models/AdvertisingChannel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const ADVERTISING_CHANNEL_API = `${API_URL}/advertisingChannels`

export async function getAdvertisingChannelsList(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${ADVERTISING_CHANNEL_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${ADVERTISING_CHANNEL_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getAdvertisingChannelById(advertisingChannelId: number) {
  return await axios.get(`${ADVERTISING_CHANNEL_API}/${advertisingChannelId}`)
}

export async function deleteAdvertisingChannel(advertisingChannelId: number) {
  return await axios.delete(`${ADVERTISING_CHANNEL_API}/${advertisingChannelId}`)
}

export async function updateAdvertisingChannel(updateAdvertisingChannel: IAdvertisingChannel) {
  return await axios
    .put(`${ADVERTISING_CHANNEL_API}/${updateAdvertisingChannel.id}`, updateAdvertisingChannel)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createAdvertisingChannel(newAdvertisingChannel: IAdvertisingChannel) {
  return await axios
    .post(`${ADVERTISING_CHANNEL_API}`, newAdvertisingChannel)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
