import {ISelectValue} from '../../common/PageItemsSelect/PageItemsSelect'

export interface IUser {
  id: number
  photo_path: string
  first_name: string
  last_name: string
  email: string
  city: string
  role: number
}

export interface IAvatar {
  filename: string
  content: string
}

export interface IUserWithPassword {
  id?: number
  first_name: string
  last_name: string
  city: string
  email: string
  role: string
  password?: string
  avatar?: IAvatar
  photo_path?: string
  passwordConfirmation: string
}

export enum UserRoles {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  SUPPORT = 3,
  PAYMENT = 4,
  SPECIALIST = 5,
}

export const ITEM_PER_PAGE: ISelectValue[] = [
  {value: 10, label: 10},
  {value: 20, label: 20},
  {value: 50, label: 50},
  {value: 100, label: 100},
]
export const LIST_FILTERS = [
  {value: 'sort=last_name', label: 'USER.LIST.FILTER.LASTNAME_UP'},
  {value: 'sort=-last_name', label: 'USER.LIST.FILTER.LASTNAME_DOWN'},
  {value: 'sort=first_name', label: 'USER.LIST.FILTER.FIRSTNAME_UP'},
  {value: 'sort=-first_name', label: 'USER.LIST.FILTER.FIRSTNAME_DOWN'},
  {value: 'sort=email', label: 'USER.LIST.FILTER.EMAIL_UP'},
  {value: 'sort=-email', label: 'USER.LIST.FILTER.EMAIL_DOWN'},
  {value: 'sort=city', label: 'USER.LIST.FILTER.CITY_UP'},
  {value: 'sort=-city', label: 'USER.LIST.FILTER.CITY_DOWN'},
  {value: 'filter[role]=2', label: 'USER.LIST.FILTER.ROLE_UP'},
  {value: 'filter[role]=1', label: 'USER.LIST.FILTER.ROLE_DOWN'},
]
