import React, {ChangeEvent} from 'react'
import {useIntl} from 'react-intl'

export interface ISelectValue {
  value: string | number
  label: string | number
}

interface PageItemsSelectProps {
  defaultValue?: string | number
  options: ISelectValue[]
  handle: (event: ChangeEvent) => void
  className?: string
}

const PageItemsSelect: React.FC<PageItemsSelectProps> = ({
  defaultValue,
  options,
  handle,
  className = 'form-select form-select-solid',
}) => {
  const intl = useIntl()
  return (
    <select
      className={className}
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      defaultValue={defaultValue}
      onChange={handle}
    >
      {options.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {typeof item.label === 'number' ? item.label : intl.formatMessage({id: item.label})}
          </option>
        )
      })}
    </select>
  )
}

export default PageItemsSelect
