import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { actions as promptActions, selectors as promptSelectors } from '../../redux/prompts/PromptRedux'
import { actions as googleCategoryActions } from '../../redux/googleCategories/GoogleCategoryRedux'
import { actions as attributeActions } from '../../redux/attributes/AttributeRedux'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Button, Table } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import AddPromptModal from './AddPromptModal'
import EditPromptModal from './EditPromptModal'
import DeletePromptModal from './DeletePromptModal'
import Pagination from '../../../common/Pagination/Pagination'
import { IPrompt, PROMPT_LIST_FILTERS } from '../../models/Prompt'
import PageItemsSelect from '../../../common/PageItemsSelect/PageItemsSelect'
import { DateUtil } from "../../../../../_metronic/assets/ts/_utils"

const Prompts: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const prompts = useSelector((state: RootState) => promptSelectors.getPrompts(state))
    const loading = useSelector((state: RootState) => state.prompts.loading)
    const error = useSelector((state: RootState) => state.prompts.error)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [showOnlyGlobal, setShowOnlyGlobal] = useState<boolean>(false)
    const [showOnlyNonGlobal, setShowOnlyNonGlobal] = useState<boolean>(false)
    const [filteredPrompts, setFilteredPrompts] = useState<IPrompt[]>([])

    const [selectedPromptId, setSelectedPromptId] = useState<number | null>(null)
    const [initialIndustryId, setInitialIndustryId] = useState<number | null>(null)
    const [initialPromptText, setInitialPromptText] = useState<string>('')
    const [initialAttributeId, setInitialAttributeId] = useState<number | null>(null)
    const [initialGlobal, setInitialGlobal] = useState<boolean>(false)

    const [currentPage, setCurrentPage] = useState<number>(1)
    const itemsPerPage = 10
    const totalPages = Math.ceil(filteredPrompts.length / itemsPerPage)
    const pagedPrompts = filteredPrompts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const [listFilter, setListFilter] = useState<string>(PROMPT_LIST_FILTERS[0].value)

    const onFilterHandle = useCallback(
        (event) => {
            setListFilter(event.target.value)
            dispatch(promptActions.changePromptFilter(event.target.value))
            dispatch(promptActions.requestPrompts())
        },
        [dispatch]
    )

    const loadPrompts = useCallback(() => {
        dispatch(promptActions.requestPrompts())
        dispatch(googleCategoryActions.requestGoogleCategories())
        dispatch(attributeActions.requestAttributes())
    }, [dispatch])

    useEffect(() => {
        loadPrompts()
    }, [loadPrompts])

    useEffect(() => {
        setFilteredPrompts(
            prompts.filter((prompt) => {
                const industryName = prompt.industry?.name || '-'
                const attributeName = prompt.attribute?.name || '-'
                const promptText = prompt.prompt_text || ''
                const createdAt = DateUtil.formatDate(prompt.created_at) || ''

                const matchesSearch =
                    industryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    attributeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    promptText.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    createdAt.includes(searchQuery)

                const matchesGlobalFilter = showOnlyGlobal ? prompt.global : true
                const matchesNonGlobalFilter = showOnlyNonGlobal ? !prompt.global : true

                return matchesSearch && matchesGlobalFilter && matchesNonGlobalFilter
            })
        )
    }, [searchQuery, prompts, showOnlyGlobal, showOnlyNonGlobal])

    const handleShowCreate = useCallback(() => {
        setShowCreateModal(true)
    }, [])

    const handleCloseAddModal = () => setShowCreateModal(false)

    const fetchAndOpenEditModal = (id: number) => {
        const selectedPrompt = prompts.find((prompt) => prompt.id === id)
        if (selectedPrompt) {
            setInitialIndustryId(selectedPrompt.industry_id || null)
            setInitialPromptText(selectedPrompt.prompt_text || '')
            setInitialAttributeId(selectedPrompt.attribute_id || null)
            setInitialGlobal(selectedPrompt.global || false)
            setSelectedPromptId(id)
            setShowEditModal(true)
        }
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false)
        setSelectedPromptId(null)
        setInitialIndustryId(null)
        setInitialPromptText('')
        setInitialAttributeId(null)
        setInitialGlobal(false)
    }

    const openDeleteModal = (id: number) => {
        setSelectedPromptId(id)
        setShowDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setSelectedPromptId(null)
    }

    const handleGlobalCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlyGlobal(e.target.checked)
        if (e.target.checked) {
            setShowOnlyNonGlobal(false)
        }
    }

    const handleNonGlobalCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlyNonGlobal(e.target.checked)
        if (e.target.checked) {
            setShowOnlyGlobal(false)
        }
    }

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({ id: 'PROMPTS.LIST' })}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <PageItemsSelect
                            defaultValue={listFilter}
                            handle={onFilterHandle}
                            options={PROMPT_LIST_FILTERS}
                        />
                        <input
                            type="text"
                            placeholder={intl.formatMessage({ id: 'SEARCH' })}
                            className="form-control form-control-sm w-auto"
                            style={{ minWidth: '150px', minHeight: '40px' }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className="form-check ms-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="globalFilterCheckbox"
                                checked={showOnlyGlobal}
                                onChange={handleGlobalCheckboxChange}
                            />
                            <label className="form-check-label text-nowrap ms-1" htmlFor="globalFilterCheckbox">
                                {intl.formatMessage({ id: 'SHOW.GLOBAL.ONLY' })}
                            </label>
                        </div>
                        <div className="form-check ms-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="nonGlobalFilterCheckbox"
                                checked={showOnlyNonGlobal}
                                onChange={handleNonGlobalCheckboxChange}
                            />
                            <label className="form-check-label text-nowrap ms-1" htmlFor="nonGlobalFilterCheckbox">
                                {intl.formatMessage({ id: 'SHOW.NON_GLOBAL.ONLY' })}
                            </label>
                        </div>
                        <Button className="btn btn-sm btn-light-primary ms-2 text-nowrap" onClick={handleShowCreate}>
                            {intl.formatMessage({ id: 'ADD' }) + ' Prompt'}
                        </Button>
                    </div>
                </div>
            </div>

            {loading && <p>{intl.formatMessage({ id: 'LOADING' })}...</p>}
            {error && <p>{intl.formatMessage({ id: 'LOADING.DATA.ERROR' })}.</p>}

            {!loading && !error && pagedPrompts.length > 0 ? (
                <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>{intl.formatMessage({ id: 'ATTRIBUTE' })}</th>
                        <th>{intl.formatMessage({ id: 'INDUSTRY' })}</th>
                        <th>{intl.formatMessage({ id: 'PROMPT_TEXT' })}</th>
                        <th>{intl.formatMessage({ id: 'GLOBAL' })}</th>
                        <th>{intl.formatMessage({ id: 'PROJECT.CREATED_AT' })}</th>
                        <th>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pagedPrompts.map((prompt) => (
                        <tr key={prompt.id}>
                            <td>{prompt.id}</td>
                            <td>{prompt.attribute?.name || '-'}</td>
                            <td>{prompt.industry?.name || '-'}</td>
                            <td>{prompt.prompt_text}</td>
                            <td className={prompt.global ? 'text-success' : 'text-danger'}>
                                <strong>{intl.formatMessage({id: prompt.global ? 'YES' : 'NO'})}</strong>
                            </td>
                            <td>{DateUtil.formatDate(prompt.created_at)}</td>
                            <td>
                                <Button
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1"
                                    variant="light"
                                    onClick={() => prompt.id !== undefined && fetchAndOpenEditModal(prompt.id)}
                                >
                                    <KTSVG path="/media/icons/duotone/Communication/Write.svg" className="svg-icon-3"/>
                                </Button>
                                <Button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
                                    variant="light"
                                    onClick={() => prompt.id !== undefined && openDeleteModal(prompt.id)}
                                >
                                    <KTSVG path="/media/icons/duotone/General/Trash.svg" className="svg-icon-3"/>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                !loading && <p>{intl.formatMessage({id: 'NO_DATA'})}</p>
            )}

            <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <AddPromptModal show={showCreateModal} handleClose={handleCloseAddModal} />
            {selectedPromptId && (
                <EditPromptModal
                    show={showEditModal}
                    handleClose={handleCloseEditModal}
                    promptId={selectedPromptId}
                    initialIndustryId={initialIndustryId!}
                    initialPromptText={initialPromptText}
                    initialAttributeId={initialAttributeId!}
                    initialGlobal={initialGlobal}
                />
            )}
            {selectedPromptId && (
                <DeletePromptModal
                    showDeleteModal={showDeleteModal}
                    handleCloseDelete={handleCloseDeleteModal}
                    promptId={selectedPromptId}
                    onDeleteSuccess={loadPrompts}
                />
            )}
        </div>
    )
}

export default Prompts
