/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {IBudgetForecast} from '../models/BudgetForecast'
import {actions} from '../redux/BudgetForecastsRedux'

interface DeleteUserModalProps {
  showDeleteModal: boolean
  handleCloseDelete: () => void
  budgetForecast: IBudgetForecast
}

const DeleteBudgetForecastModal: React.FC<DeleteUserModalProps> = ({
  showDeleteModal,
  handleCloseDelete,
  budgetForecast,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const deleteUser = useCallback(() => {
    dispatch(actions.budgetForecastToDelete(budgetForecast.id as number))
    handleCloseDelete()
  }, [dispatch, budgetForecast, handleCloseDelete])

  return (
    <>
      <Modal show={showDeleteModal} onHide={handleCloseDelete} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({id: 'BUDGET_FORECASTS.DELETE'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`${intl.formatMessage({id: 'BUDGET_FORECASTS.DELETE_CONF'})}: ${
            budgetForecast.industry
          }`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDelete}>
            {intl.formatMessage({id: 'CLOSE'})}
          </Button>
          <Button variant='primary' onClick={deleteUser}>
            {intl.formatMessage({id: 'DELETE'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {DeleteBudgetForecastModal}
