import React, {useCallback, useContext} from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { deleteAttribute } from '../../redux/attributes/AttributeCRUD'
import { AlertContext } from "../../../../../_metronic/layout/core/AlertContext"

interface DeleteAttributeModalProps {
    showDeleteModal: boolean
    handleCloseDelete: () => void
    attributeId: number
    attributeName: string
    onDeleteSuccess: () => void
}

const DeleteAttributeModal: React.FC<DeleteAttributeModalProps> = ({
                                                                       showDeleteModal,
                                                                       handleCloseDelete,
                                                                       attributeId,
                                                                       attributeName,
                                                                       onDeleteSuccess,
                                                                   }) => {
    const intl = useIntl()
    const {showAlert} = useContext(AlertContext)

    const handleDelete = useCallback(async () => {
        try {
            await deleteAttribute(attributeId)
            onDeleteSuccess()
            handleCloseDelete()
            showAlert([[intl.formatMessage({ id: 'DELETE.ATTRIBUTE.SUCCESS' })]], 'success', true, intl.formatMessage({ id: 'SUCCESS' }))
        } catch (error) {
            console.error(error)
        }
    }, [attributeId, onDeleteSuccess, handleCloseDelete])

    return (
        <Modal show={showDeleteModal} onHide={handleCloseDelete} backdrop='static' keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'ATTRIBUTE.DELETE' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {`${intl.formatMessage({ id: 'ATTRIBUTE.DELETE_CONF' })}: ${attributeName}?`}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseDelete}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant='primary' onClick={handleDelete}>
                    {intl.formatMessage({ id: 'DELETE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteAttributeModal
