import {
  ActionWithDefinePayload,
  ActionWithPayload,
  RootState,
} from '../../../../setup/redux/RootReducer'
import {IInvoice} from '../models/Invoice'
import {getInvoicesList} from './InvoicesCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const SET_CLIENT = 'SET_CLIENT'
export const INVOICE_REQUEST = 'INVOICE_REQUEST'
export const INVOICE_REQUEST_SUCCESS = 'INVOICE_REQUEST_SUCCESS'
export const INVOICE_REQUEST_FAIL = 'INVOICE_REQUEST_FAIL'
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IInvoicesState {
  getInvoices?: IInvoice[]
  getInvoicesLoading: boolean
  getInvoicesError: boolean
  // list paramatrs
  client: number

  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
}

const initialInvoicesState: IInvoicesState = {
  getInvoices: [],
  getInvoicesLoading: false,
  getInvoicesError: false,

  client: 0,
  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,
}

export const reducer = (
  state: IInvoicesState = initialInvoicesState,
  action: ActionWithPayload<IInvoicesState>
) => {
  switch (action.type) {
    case INVOICE_REQUEST:
      return {...state, getInvoicesLoading: true, documentType: 'invoice'}
    case INVOICE_REQUEST_SUCCESS:
      const invoices = action.payload?.getInvoices
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getInvoices: invoices,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getInvoicesLoading: false,
        getInvoicesError: false,
      }
    case INVOICE_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    default:
      return state
  }
}

export const actions = {
  requestInvoices: (clientId: string, documentType: string) => ({
    type: INVOICE_REQUEST,
    payload: {clientId, documentType},
  }),
  requestInvoicesSuccess: (
    invoices: IInvoice[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: INVOICE_REQUEST_SUCCESS,
    payload: {getInvoices: invoices, currentPage, totalPages, perPage, totalItem},
  }),

  requestInvoiceFail: () => ({type: INVOICE_REQUEST_FAIL}),

  setClient: (client: number) => ({
    type: SET_CLIENT,
    payload: {client: client},
  }),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getInvoices: (state: RootState) => state.invoices,
  getCurrentPage: (state: RootState) => state.invoices.currentPage,
  getLimitPerPage: (state: RootState) => state.invoices.perPage,
  getFilter: (state: RootState) => state.invoices.filter,
  getClient: (state: RootState) => state.invoices.client,
  getTotalItem: (state: RootState) => state.invoices.totalItem,
  getSearchText: (state: RootState) => state.invoices.searchText,
}

function* listUpdate(clientId: number, documentType: string) {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getInvoicesList(documentType, clientId, page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(
    INVOICE_REQUEST,
    function* getInvoicesSage(
      action: ActionWithDefinePayload<{clientId: number; documentType: string}>
    ) {
      try {
        const {data} = yield listUpdate(action.payload.clientId, action.payload.documentType)

        yield put(
          actions.requestInvoicesSuccess(
            data.data,
            data.current_page,
            data.last_page,
            data.per_page,
            data.total
          )
        )
      } catch (error) {
        yield put(actions.requestInvoiceFail())
      }
    }
  )
}
