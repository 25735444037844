import axios from 'axios'
import {INegativeKeyword} from '../models/NegativeKeyword'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const NEGATIVE_KEYWORD_API = `${API_URL}/negativeKeywords`

export async function getNegativeKeywordsList(
    page: number,
    perPage: number,
    filter: string,
    search?: string,
    language_code?: string
) {
  const params: any = {
    page,
    'per-page': perPage,
    filter,
  }

  if (search) params.search = search
  if (language_code) params.language_code = language_code

  return await axios.get(`${NEGATIVE_KEYWORD_API}`, { params })
}

export async function getNegativeKeywordById(negativeKeywordId: number) {
  return await axios.get(`${NEGATIVE_KEYWORD_API}/${negativeKeywordId}`)
}

export async function deleteNegativeKeyword(negativeKeywordId: number) {
  return await axios.delete(`${NEGATIVE_KEYWORD_API}/${negativeKeywordId}`)
}

export async function updateNegativeKeyword(updateNegativeKeyword: INegativeKeyword) {
  return await axios
    .put(`${NEGATIVE_KEYWORD_API}/${updateNegativeKeyword.id}`, updateNegativeKeyword)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createNegativeKeyword(newNegativeKeyword: INegativeKeyword) {
  return await axios
    .post(`${NEGATIVE_KEYWORD_API}`, newNegativeKeyword)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
