import { IGoogleCategory } from '../../models/GoogleCategory'
import { RootState } from '../../../../../setup'
import { getGoogleCategoriesList, updateGoogleCategory } from './GoogleCategoryCRUD'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

export const GOOGLE_CATEGORY_REQUEST = 'GOOGLE_CATEGORY_REQUEST'
export const GOOGLE_CATEGORY_REQUEST_SUCCESS = 'GOOGLE_CATEGORY_REQUEST_SUCCESS'
export const GOOGLE_CATEGORY_REQUEST_FAIL = 'GOOGLE_CATEGORY_REQUEST_FAIL'
export const CHANGE_GOOGLE_CATEGORY_FILTER = 'CHANGE_GOOGLE_CATEGORY_FILTER'
export const UPDATE_GOOGLE_CATEGORY_REQUEST = 'UPDATE_GOOGLE_CATEGORY_REQUEST'
export const UPDATE_GOOGLE_CATEGORY_SUCCESS = 'UPDATE_GOOGLE_CATEGORY_SUCCESS'
export const UPDATE_GOOGLE_CATEGORY_FAIL = 'UPDATE_GOOGLE_CATEGORY_FAIL'

export interface IGoogleCategoryState {
    categories: IGoogleCategory[]
    loading: boolean
    error: boolean
    filter: string
    updateCategoryError?: boolean
    categoryIsUpdated?: boolean
}

const initialState: IGoogleCategoryState = {
    categories: [],
    loading: false,
    error: false,
    filter: 'sort=name',
    updateCategoryError: false,
    categoryIsUpdated: false,
}

export const reducer = (
    state: IGoogleCategoryState = initialState,
    action: { type: string; payload?: any }
): IGoogleCategoryState => {
    switch (action.type) {
        case GOOGLE_CATEGORY_REQUEST:
            return { ...state, loading: true }
        case GOOGLE_CATEGORY_REQUEST_SUCCESS:
            return {
                ...state,
                categories: action.payload?.categories || [],
                loading: false,
                error: false,
            }
        case GOOGLE_CATEGORY_REQUEST_FAIL:
            return { ...state, loading: false, error: true }
        case CHANGE_GOOGLE_CATEGORY_FILTER:
            return { ...state, filter: action.payload?.filter || state.filter }
        case UPDATE_GOOGLE_CATEGORY_REQUEST:
            return { ...state, loading: true, categoryIsUpdated: false, updateCategoryError: false }
        case UPDATE_GOOGLE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                categoryIsUpdated: true,
                updateCategoryError: false,
            }
        case UPDATE_GOOGLE_CATEGORY_FAIL:
            return { ...state, loading: false, updateCategoryError: true }
        default:
            return state
    }
}

export const actions = {
    requestGoogleCategories: () => ({ type: GOOGLE_CATEGORY_REQUEST }),
    requestGoogleCategoriesSuccess: (categories: IGoogleCategory[]) => ({
        type: GOOGLE_CATEGORY_REQUEST_SUCCESS,
        payload: { categories },
    }),
    requestGoogleCategoryFail: () => ({ type: GOOGLE_CATEGORY_REQUEST_FAIL }),

    changeGoogleCategoryFilter: (newFilter: string) => ({
        type: CHANGE_GOOGLE_CATEGORY_FILTER,
        payload: { filter: newFilter },
    }),

    updateGoogleCategory: (industry_id: number, attribute_ids: number[]) => ({
        type: UPDATE_GOOGLE_CATEGORY_REQUEST,
        payload: { industry_id, attribute_ids },
    }),
    updateGoogleCategorySuccess: () => ({
        type: UPDATE_GOOGLE_CATEGORY_SUCCESS,
    }),
    updateGoogleCategoryFail: () => ({ type: UPDATE_GOOGLE_CATEGORY_FAIL }),
}

export const selectors = {
    getGoogleCategories: (state: RootState) => state.googleCategories.categories,
    getFilter: (state: RootState) => state.googleCategories.filter,
}

function* fetchGoogleCategories(): SagaIterator {
    try {
        const filter: string = yield select(selectors.getFilter)

        const response = yield call(getGoogleCategoriesList, filter)
        const data = response.data

        const categoriesArray: IGoogleCategory[] = data.data || data

        yield put(actions.requestGoogleCategoriesSuccess(categoriesArray))
    } catch (error) {
        yield put(actions.requestGoogleCategoryFail())
    }
}

function* updateGoogleCategorySaga(
    action: { type: string; payload: { industry_id: number; attribute_ids: number[] } }
): SagaIterator {
    try {
        const { industry_id, attribute_ids } = action.payload
        yield call(updateGoogleCategory, industry_id, attribute_ids)
        yield put(actions.updateGoogleCategorySuccess())
        yield put(actions.requestGoogleCategories())
    } catch (error) {
        yield put(actions.updateGoogleCategoryFail())
    }
}

export function* saga(): SagaIterator {
    yield takeLatest(GOOGLE_CATEGORY_REQUEST, fetchGoogleCategories)
    yield takeLatest(UPDATE_GOOGLE_CATEGORY_REQUEST, updateGoogleCategorySaga)
}
