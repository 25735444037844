import * as Yup from 'yup'
import {IBudgetForecast} from './BudgetForecast'

export const validationCreateSchema = Yup.object().shape({
  industry: Yup.string().required('_REQ.FIELD'),
  channel: Yup.string().required('_REQ.FIELD'),
  cpc: Yup.number().required('_REQ.FIELD'),
  ctr: Yup.number().required('_REQ.FIELD'),
  conversion_rate: Yup.number().required('_REQ.FIELD'),
  conversion_value: Yup.number().required('_REQ.FIELD'),
  conversion_number: Yup.number().required('_REQ.FIELD'),
  cart_value: Yup.number().required('_REQ.FIELD'),
})

export const initialValues: IBudgetForecast = {
  id: undefined,
  industry: '',
  channel: '',
  cpc: 0,
  ctr: 0,
  conversion_rate: 0,
  conversion_value: 0,
  conversion_number: 0,
  cart_value: 0,
  active: true,
}
