import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { actions, selectors } from '../../redux/attributes/AttributeRedux'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Button, Table } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import AddAttributeModal from './AddAttributeModal'
import EditAttributeModal from './EditAttributeModal'
import { getAttribute } from '../../redux/attributes/AttributeCRUD'
import DeleteAttributeModal from './DeleteAttributeModal'
import Pagination from '../../../common/Pagination/Pagination'
import { IAttribute, ATTRIBUTE_LIST_FILTERS } from '../../models/Attribute'
import { IGoogleCategory } from '../../models/GoogleCategory'
import PageItemsSelect from '../../../common/PageItemsSelect/PageItemsSelect'
import { DateUtil } from "../../../../../_metronic/assets/ts/_utils"

interface OptionType {
    label: string
    value: number
}

const Attributes: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const attributes = useSelector((state: RootState) => selectors.getAttributes(state))
    const loading = useSelector((state: RootState) => state.attributes.loading)
    const error = useSelector((state: RootState) => state.attributes.error)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [selectedAttributeId, setSelectedAttributeId] = useState<number | null>(null)
    const [initialName, setInitialName] = useState<string>('')
    const [initialDescription, setInitialDescription] = useState<string>('')
    const [initialGlobal, setInitialGlobal] = useState<boolean>(false)
    const [selectedAttributeName, setSelectedAttributeName] = useState<string>('')
    const [initialGoogleCategoryIds, setInitialGoogleCategoryIds] = useState<number[]>([])
    const [selectedGoogleCategories, setSelectedGoogleCategories] = useState<OptionType[]>([])

    const [currentPage, setCurrentPage] = useState<number>(1)
    const itemsPerPage = 10

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [showOnlyGlobal, setShowOnlyGlobal] = useState<boolean>(false)
    const [showOnlyNonGlobal, setShowOnlyNonGlobal] = useState<boolean>(false)
    const [filteredAttributes, setFilteredAttributes] = useState<IAttribute[]>([])

    const totalPages = Math.ceil(filteredAttributes.length / itemsPerPage)
    const pagedAttributes = filteredAttributes.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    )

    const [listFilter, setListFilter] = useState<string>(ATTRIBUTE_LIST_FILTERS[0].value)

    const onFilterHandle = useCallback(
        (event) => {
            setListFilter(event.target.value)
            dispatch(actions.changeAttributeFilter(event.target.value))
            dispatch(actions.requestAttributes())
        },
        [dispatch]
    )

    useEffect(() => {
        dispatch(actions.requestAttributes())
    }, [dispatch])

    useEffect(() => {
        setFilteredAttributes(
            attributes.filter((attribute) => {
                const name = attribute.name || ''
                const description = attribute.description || ''
                const createdAt = attribute.created_at ? DateUtil.formatDate(attribute.created_at) : ''
                const matchesSearch =
                    name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    createdAt.toLowerCase().includes(searchQuery.toLowerCase())

                const matchesGlobalFilter = showOnlyGlobal ? attribute.global : true
                const matchesNonGlobalFilter = showOnlyNonGlobal ? !attribute.global : true

                return matchesSearch && matchesGlobalFilter && matchesNonGlobalFilter
            })
        )
    }, [searchQuery, attributes, showOnlyGlobal, showOnlyNonGlobal])

    const handleShowCreate = useCallback(() => {
        setShowCreateModal(true)
    }, [])

    const handleCloseAddModal = () => setShowCreateModal(false)

    const fetchAndOpenEditModal = async (id: number) => {
        try {
            const response = await getAttribute(id)
            const attribute: IAttribute = response.data

            setInitialName(attribute.name)
            setInitialDescription(attribute.description)
            setInitialGlobal(attribute.global)
            setSelectedAttributeId(id)

            setInitialGoogleCategoryIds(
                attribute.google_categories
                    ?.map((category) => category.id)
                    .filter((id): id is number => id !== undefined) || []
            )

            const preSelectedCategories =
                attribute.google_categories?.map((category: IGoogleCategory) => ({
                    label: category.name,
                    value: category.id!,
                })) || []

            setSelectedGoogleCategories(preSelectedCategories)
            setShowEditModal(true)
        } catch (error) {
            console.error(error)
        }
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false)
        setSelectedAttributeId(null)
        setInitialName('')
        setInitialDescription('')
        setInitialGlobal(false)
        setInitialGoogleCategoryIds([])
        setSelectedGoogleCategories([])
    }

    const openDeleteModal = (id: number, name: string) => {
        setSelectedAttributeId(id)
        setSelectedAttributeName(name)
        setShowDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setSelectedAttributeId(null)
        setSelectedAttributeName('')
    }

    const handleGlobalCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlyGlobal(e.target.checked)
        if (e.target.checked) {
            setShowOnlyNonGlobal(false)
        }
    }

    const handleNonGlobalCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlyNonGlobal(e.target.checked)
        if (e.target.checked) {
            setShowOnlyGlobal(false)
        }
    }

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({ id: 'ATTRIBUTE.LIST' })}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <PageItemsSelect
                            defaultValue={listFilter}
                            handle={onFilterHandle}
                            options={ATTRIBUTE_LIST_FILTERS}
                        />
                        <input
                            type="text"
                            placeholder={intl.formatMessage({ id: 'SEARCH' })}
                            className="form-control form-control-sm w-auto"
                            style={{ minWidth: '150px', minHeight: '40px' }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className="form-check ms-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="globalFilterCheckbox"
                                checked={showOnlyGlobal}
                                onChange={handleGlobalCheckboxChange}
                            />
                            <label
                                className="form-check-label text-nowrap ms-1"
                                htmlFor="globalFilterCheckbox"
                            >
                                {intl.formatMessage({ id: 'SHOW.GLOBAL.ONLY' })}
                            </label>
                        </div>
                        <div className="form-check ms-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="nonGlobalFilterCheckbox"
                                checked={showOnlyNonGlobal}
                                onChange={handleNonGlobalCheckboxChange}
                            />
                            <label
                                className="form-check-label text-nowrap ms-1"
                                htmlFor="nonGlobalFilterCheckbox"
                            >
                                {intl.formatMessage({ id: 'SHOW.NON_GLOBAL.ONLY' })}
                            </label>
                        </div>
                        <Button
                            className="btn btn-sm btn-light-primary ms-2 text-nowrap"
                            onClick={handleShowCreate}
                        >
                            {intl.formatMessage({ id: 'ADD' }) + ' ' + intl.formatMessage({ id: 'ATTRIBUTE' })}
                        </Button>
                    </div>
                </div>
            </div>

            {loading && <p>{intl.formatMessage({id: 'LOADING'})}...</p>}
            {error && <p>{intl.formatMessage({id: 'LOADING.DATA.ERROR'})}.</p>}

            {!loading && !error && pagedAttributes.length > 0 ? (
                <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>{intl.formatMessage({ id: 'SETTING.NAME' })}</th>
                        <th>{intl.formatMessage({ id: 'DESCRIPTION' })}</th>
                        <th>{intl.formatMessage({ id: 'GLOBAL' })}</th>
                        <th>{intl.formatMessage({ id: 'PROJECT.CREATED_AT' })}</th>
                        <th>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pagedAttributes.map((attribute) => (
                        <tr key={attribute.id}>
                            <td>{attribute.id}</td>
                            <td>{attribute.name}</td>
                            <td>{attribute.description}</td>
                            <td className={attribute.global ? 'text-success' : 'text-danger'}>
                                <strong>
                                    {intl.formatMessage({id: attribute.global ? 'YES' : 'NO'})}
                                </strong>
                            </td>
                            <td>{DateUtil.formatDate(attribute.created_at)}</td>
                            <td>
                                <Button
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1"
                                    variant="light"
                                    onClick={() =>
                                        attribute.id !== undefined && fetchAndOpenEditModal(attribute.id)
                                    }
                                >
                                    <KTSVG
                                        path="/media/icons/duotone/Communication/Write.svg"
                                        className="svg-icon-3"
                                    />
                                </Button>
                                <Button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
                                    variant="light"
                                    onClick={() =>
                                        attribute.id !== undefined && openDeleteModal(attribute.id, attribute.name)
                                    }
                                >
                                    <KTSVG
                                        path="/media/icons/duotone/General/Trash.svg"
                                        className="svg-icon-3"
                                    />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                !loading && <p>{intl.formatMessage({id: 'ATTRIBUTE.NO_ATTRIBUTES'})}</p>
            )}

            <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <AddAttributeModal show={showCreateModal} handleClose={handleCloseAddModal} />
            {selectedAttributeId && (
                <EditAttributeModal
                    show={showEditModal}
                    handleClose={handleCloseEditModal}
                    attributeId={selectedAttributeId}
                    initialName={initialName}
                    initialDescription={initialDescription}
                    initialGlobal={initialGlobal}
                    initialGoogleCategoryIds={initialGoogleCategoryIds}
                    selectedGoogleCategories={selectedGoogleCategories}
                />
            )}
            {selectedAttributeId && (
                <DeleteAttributeModal
                    showDeleteModal={showDeleteModal}
                    handleCloseDelete={handleCloseDeleteModal}
                    attributeId={selectedAttributeId}
                    attributeName={selectedAttributeName}
                    onDeleteSuccess={() => dispatch(actions.requestAttributes())}
                />
            )}
        </div>
    )
}

export default Attributes
