/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, useCallback} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import _ from 'lodash'
import {useIntl} from 'react-intl'

interface SearchInputProps {
  handle: (event: ChangeEvent) => void
}

const SearchInput: React.FC<SearchInputProps> = ({handle}) => {
  const intl = useIntl()
  const debouncedSave = useCallback(
    _.debounce((newValue) => handle(newValue), 800),
    []
  )
  return (
    <>
      <KTSVG
        path='/media/icons/duotone/General/Search.svg'
        className='svg-icon-3 position-absolute ms-3'
      />
      <input
        type='text'
        id='kt_filter_search'
        className='form-control form-control-sm w-200px ps-9'
        placeholder={intl.formatMessage({id: 'SEARCH'})}
        onChange={debouncedSave}
      />
    </>
  )
}

export default SearchInput
