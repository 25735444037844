import React, { useCallback, useContext } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { deletePrompt } from '../../redux/prompts/PromptCRUD'
import { AlertContext } from '../../../../../_metronic/layout/core/AlertContext'
import { useIntl } from "react-intl"

interface DeletePromptModalProps {
    showDeleteModal: boolean
    handleCloseDelete: () => void
    promptId: number
    onDeleteSuccess: () => void
}

const DeletePromptModal: React.FC<DeletePromptModalProps> = ({
                                                                 showDeleteModal,
                                                                 handleCloseDelete,
                                                                 promptId,
                                                                 onDeleteSuccess
                                                             }) => {
    const intl = useIntl()
    const { showAlert } = useContext(AlertContext)

    const handleDelete = useCallback(async () => {
        try {
            await deletePrompt(promptId)
            onDeleteSuccess()
            handleCloseDelete()
            showAlert([[intl.formatMessage({ id: 'DELETE.PROMPT.SUCCESS' })]], 'success', true, intl.formatMessage({ id: 'SUCCESS' }))
        } catch (error) {
            console.error(error)
        }
    }, [promptId, onDeleteSuccess, handleCloseDelete])

    return (
        <Modal show={showDeleteModal} onHide={handleCloseDelete} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'DELETING.PROMPT' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {intl.formatMessage({ id: 'DELETING.PROMPT.Q' })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDelete}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    {intl.formatMessage({ id: 'DELETE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeletePromptModal
