import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Select, { MultiValue } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { actions as attributeActions } from '../../redux/attributes/AttributeRedux'
import { actions as googleActions, selectors as googleSelectors } from '../../redux/googleCategories/GoogleCategoryRedux'
import { AlertContext } from '../../../../../_metronic/layout/core/AlertContext'
import { useIntl } from "react-intl"
import { RootState } from '../../../../../setup'
import { IGoogleCategory } from '../../models/GoogleCategory'

interface OptionType {
    label: string
    value: number
}

interface AddAttributeModalProps {
    show: boolean
    handleClose: () => void
}

const AddAttributeModal: React.FC<AddAttributeModalProps> = ({ show, handleClose }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const { showAlert } = useContext(AlertContext)

    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [global, setGlobal] = useState<boolean>(false)
    const [selectedGoogleCategories, setSelectedGoogleCategories] = useState<OptionType[]>([])

    const googleCategories: IGoogleCategory[] = useSelector((state: RootState) => googleSelectors.getGoogleCategories(state))

    useEffect(() => {
        if (googleCategories.length === 0) {
            dispatch(googleActions.requestGoogleCategories())
        }
    }, [dispatch, googleCategories])

    const googleCategoryOptions: OptionType[] = googleCategories
        .filter((category: IGoogleCategory) => category.id !== undefined)
        .map((category: IGoogleCategory) => ({
            label: category.name,
            value: category.id!
        }))

    const handleAddAttribute = () => {
        if (!name || !description) {
            showAlert([[!name ? intl.formatMessage({id: 'NEGATIVE_KEYWORD.NAME_REQ'}) :
                intl.formatMessage({id: 'OFFERS.VALID.DESC_REQ'})]], 'danger', true)
            return
        }

        const attributeData = {
            name,
            description,
            global,
            google_category_ids: global ? [] : selectedGoogleCategories.map(category => category.value)
        }

        dispatch(attributeActions.createAttribute(attributeData))

        setName('')
        setDescription('')
        setGlobal(false)
        setSelectedGoogleCategories([])
        showAlert([[intl.formatMessage({id: 'ADD.ATTRIBUTE.SUCCESS'})]], 'success', true, intl.formatMessage({ id: 'SUCCESS' }))
        handleClose()
    }

    const handleCategoryChange = (newValue: MultiValue<OptionType>) => {
        setSelectedGoogleCategories(newValue as OptionType[])
    }

    const handleGlobalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isGlobal = e.target.checked
        setGlobal(isGlobal)
        if (isGlobal) {
            setSelectedGoogleCategories([])
        }
    }

    const handleCloseModal = () => {
        setName('')
        setDescription('')
        setGlobal(false)
        setSelectedGoogleCategories([])
        handleClose()
    }

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'ADDING.ATTRIBUTE' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage({ id: 'SETTING.NAME' })}</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                            placeholder={intl.formatMessage({ id: 'PROVIDE.ATTRIBUTE.NAME' })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage({ id: 'DESCRIPTION' })}</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={intl.formatMessage({ id: 'PROVIDE.ATTRIBUTE.DESCRIPTION' })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGlobalCheckbox">
                        <Form.Check
                            type="checkbox"
                            label={intl.formatMessage({ id: 'GLOBAL' })}
                            checked={global}
                            onChange={handleGlobalChange}
                        />
                    </Form.Group>

                    {!global && (
                        <Form.Group className="mb-3">
                            <Form.Label>{intl.formatMessage({ id: 'CHOOSE_INDUSTRY' })}</Form.Label>
                            <Select
                                isMulti
                                options={googleCategoryOptions}
                                value={selectedGoogleCategories}
                                onChange={handleCategoryChange}
                                placeholder={intl.formatMessage({ id: 'CHOOSE_INDUSTRY' })}
                            />
                        </Form.Group>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant="primary" onClick={handleAddAttribute}>
                    {intl.formatMessage({ id: 'ADD' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddAttributeModal
