import React from 'react'
import BudgetForecastsList from './components/BudgetForecastsList'

const BudgetForecastPage: React.FC = () => {
  return (
    <>
      <BudgetForecastsList />
    </>
  )
}

export default BudgetForecastPage
