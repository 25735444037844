import {ActionWithPayload, IErrorResponse, RootState} from '../../../../setup/redux/RootReducer'
import {IPlatform} from '../models/Platform'
import {
  createPlatform,
  deletePlatform,
  getPlatformById,
  getPlatformsList,
  updatePlatform,
} from './PlatformsCRUD'
import {put, takeLatest} from 'redux-saga/effects'
import {select} from '@redux-saga/core/effects'

export const PLATFORMS_REQUEST = 'PLATFORMS_REQUEST'
export const PLATFORMS_REQUEST_SUCCESS = 'PLATFORMS_REQUEST_SUCCESS'
export const PLATFORMS_REQUEST_FAIL = 'PLATFORMS_REQUEST_FAIL'

export const PLATFORM_DELETE = 'PLATFORM_DELETE'
export const PLATFORM_DELETE_SUCCESS = 'PLATFORM_DELETE_SUCCESS'
export const PLATFORM_DELETE_FAIL = 'PLATFORM_DELETE_ERROR'

export const PLATFORM_CREATE_REQUEST = 'PLATFORM_CREATE_REQUEST'
export const PLATFORM_CREATE_SUCCESS = 'PLATFORM_CREATE_SUCCESS'
export const PLATFORM_CREATE_FAIL = 'PLATFORM_CREATE_FAIL'

export const GET_PLATFORM_TO_UPDATE = 'GET_PLATFORM_TO_UPDATE'
export const GET_PLATFORM_TO_UPDATE_REQUEST = 'GET_PLATFORM_TO_UPDATE_REQUEST'
export const GET_PLATFORM_TO_UPDATE_FAIL = 'GET_PLATFORM_TO_UPDATE_FAIL'

export const PUT_PLATFORM_UPDATE_REQUEST = 'PUT_PLATFORM_UPDATE_REQUEST'
export const PUT_PLATFORM_UPDATE_SUCCESS = 'PUT_PLATFORM_UPDATE_SUCCESS'
export const PUT_PLATFORM_UPDATE_FAIL = 'PUT_PLATFORM_UPDATE_FAIL'

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_LIMIT_PER_PAGE = 'CHANGE_LIMIT_PER_PAGE'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_TOTAL_ITEM = 'CHANGE_TOTAL_ITEM'
export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export interface IPlatformsState {
  getPlatforms?: IPlatform[]
  getPlatformsLoading: boolean
  getPlatformsError: boolean
  // list paramatrs
  currentPage?: number
  totalPages?: number
  perPage?: number
  filter?: string
  totalItem?: number
  searchText?: string
  //create platform
  createNewPlatform?: IPlatform
  createPlatformLoading: boolean
  createPlatformError: boolean
  platformIsCreate: boolean
  createPlatformErrorMessage?: string[][]
  //get platform to update
  getPlatformLoading: boolean
  getPlatformError: boolean
  getPlatformToUpdateId?: number
  getPlatformToUpdateData?: IPlatform
  //platform update
  platformUpdateData?: IPlatform
  platformUpdateLoading: boolean
  platformUpdateError: boolean
  platformIsUpdate: boolean
  platformUpdateErrorMessage?: string[][]
  //delete platform
  platformIdToDelete?: number
  platformDeleteError: boolean
}

const initialPlatformsState: IPlatformsState = {
  getPlatforms: [],
  getPlatformsLoading: false,
  getPlatformsError: false,

  currentPage: 1,
  totalPages: undefined,
  perPage: 10,
  filter: 'name',
  totalItem: 0,
  searchText: undefined,

  createNewPlatform: undefined,
  createPlatformLoading: false,
  createPlatformError: false,
  platformIsCreate: false,
  createPlatformErrorMessage: undefined,

  getPlatformLoading: false,
  getPlatformError: false,
  getPlatformToUpdateId: undefined,
  getPlatformToUpdateData: undefined,

  platformUpdateData: undefined,
  platformUpdateLoading: false,
  platformUpdateError: false,
  platformIsUpdate: false,
  platformUpdateErrorMessage: undefined,

  platformIdToDelete: undefined,
  platformDeleteError: false,
}

export const reducer = (
  state: IPlatformsState = initialPlatformsState,
  action: ActionWithPayload<IPlatformsState>
) => {
  switch (action.type) {
    case PLATFORMS_REQUEST:
      return {...state, getPlatformsLoading: true}
    case PLATFORMS_REQUEST_SUCCESS:
      const platforms = action.payload?.getPlatforms
      const currentPage = action.payload?.currentPage
      const totalPages = action.payload?.totalPages
      const perPage = action.payload?.perPage
      const totalItem = action.payload?.totalItem
      return {
        ...state,
        getPlatforms: platforms,
        currentPage,
        totalPages,
        perPage,
        totalItem,
        getPlatformsLoading: false,
        getPlatformsError: false,
      }
    case PLATFORMS_REQUEST_FAIL:
      return {...state, error: true}
    case CHANGE_CURRENT_PAGE:
      const changePage = action.payload?.currentPage
      return {...state, currentPage: changePage}
    case CHANGE_LIMIT_PER_PAGE:
      const changeLimit = action.payload?.perPage
      return {...state, perPage: changeLimit}
    case CHANGE_FILTER:
      const chnageFilter = action.payload?.filter
      return {...state, filter: chnageFilter}
    case CHANGE_TOTAL_ITEM:
      const changeTotal = action.payload?.totalItem
      return {...state, totalItem: changeTotal}
    case CHANGE_SEARCH_TEXT:
      const searchText = action.payload?.searchText
      return {...state, searchText}
    //platform delete
    case PLATFORM_DELETE:
      const platformIdToDelete = action.payload?.platformIdToDelete
      return {...state, platformIdToDelete}
    case PLATFORM_DELETE_SUCCESS:
      const listAfterDel = state.getPlatforms?.filter(
        (item) => item.id !== action.payload?.platformIdToDelete
      )
      return {...state, getPlatforms: listAfterDel}
    case PLATFORM_DELETE_FAIL:
      return {...state, platformDeleteError: true}

    //platform create
    case PLATFORM_CREATE_REQUEST:
      const createNewPlatform = action.payload?.createNewPlatform
      return {
        ...state,
        createPlatformLoading: true,
        createNewPlatform: createNewPlatform,
        platformIsCreate: false,
        createPlatformError: false,
      }
    case PLATFORM_CREATE_SUCCESS:
      return {...state, createPlatformLoading: false, platformIsCreate: true}
    case PLATFORM_CREATE_FAIL:
      const platformErrorMessage = action.payload?.createPlatformErrorMessage
      return {
        ...state,
        createPlatformErrorMessage: platformErrorMessage,
        createPlatformError: true,
        createPlatformLoading: false,
        platformIsCreate: false,
      }
    //get platform to update
    case GET_PLATFORM_TO_UPDATE:
      const platformIdToUpdate = action.payload?.getPlatformToUpdateId
      return {
        ...state,
        getPlatformToUpdateId: platformIdToUpdate,
        getPlatformToUpdateData: undefined,
        getPlatformLoading: true,
      }
    case GET_PLATFORM_TO_UPDATE_REQUEST:
      const platformToUpdateData = action.payload?.getPlatformToUpdateData
      return {
        ...state,
        getPlatformToUpdateData: platformToUpdateData,
        getPlatformLoading: false,
      }
    case GET_PLATFORM_TO_UPDATE_FAIL:
      return {
        ...state,
        getPlatformLoading: false,
        getPlatformError: true,
      }
    //put platform update
    case PUT_PLATFORM_UPDATE_REQUEST:
      const platformDataUpd = action.payload?.platformUpdateData
      return {
        ...state,
        platformUpdateLoading: true,
        platformUpdateData: platformDataUpd,
        platformIsUpdate: false,
        platformUpdateError: false,
      }
    case PUT_PLATFORM_UPDATE_SUCCESS:
      return {
        ...state,
        platformUpdateLoading: false,
        platformIsUpdate: true,
      }
    case PUT_PLATFORM_UPDATE_FAIL:
      const errorUpdMessage = action.payload?.platformUpdateErrorMessage
      return {
        ...state,
        platformUpdateLoading: false,
        platformUpdateError: true,
        platformIsUpdate: false,
        platformUpdateErrorMessage: errorUpdMessage,
      }

    default:
      return state
  }
}

export const actions = {
  requestPlatforms: () => ({type: PLATFORMS_REQUEST}),
  requestPlatformsSuccess: (
    platforms: IPlatform[],
    currentPage: number,
    totalPages: number,
    perPage: number,
    totalItem: number
  ) => ({
    type: PLATFORMS_REQUEST_SUCCESS,
    payload: {getPlatforms: platforms, currentPage, totalPages, perPage, totalItem},
  }),

  requestPlatformFail: () => ({type: PLATFORMS_REQUEST_FAIL}),

  createPlatformRequest: (createPlatform: IPlatform) => ({
    type: PLATFORM_CREATE_REQUEST,
    payload: {createNewPlatform: createPlatform},
  }),

  createNewPlatform: () => ({
    type: PLATFORM_CREATE_SUCCESS,
  }),

  createPlatformFail: (err: any) => ({
    type: PLATFORM_CREATE_FAIL,
    payload: {createPlatformErrorMessage: err},
  }),

  changePlatformToUpdateId: (platformId: number) => ({
    type: GET_PLATFORM_TO_UPDATE,
    payload: {getPlatformToUpdateId: platformId},
  }),

  requestPlatformToUpdateData: (getPlatformToUpdateData: IPlatform) => ({
    type: GET_PLATFORM_TO_UPDATE_REQUEST,
    payload: {getPlatformToUpdateData},
  }),

  requestPlatformToUpdateDataFail: () => ({
    type: GET_PLATFORM_TO_UPDATE_FAIL,
  }),

  updatePlatformData: (platformData: IPlatform) => ({
    type: PUT_PLATFORM_UPDATE_REQUEST,
    payload: {platformUpdateData: platformData},
  }),

  updatePlatformDataSuccess: () => ({
    type: PUT_PLATFORM_UPDATE_SUCCESS,
  }),

  requestPlatformToUpdateFail: (err: any) => ({
    type: PUT_PLATFORM_UPDATE_FAIL,
    payload: {platformUpdateErrorMessage: err},
  }),

  platformToDelete: (platformToDelete: number) => ({
    type: PLATFORM_DELETE,
    payload: {platformIdToDelete: platformToDelete},
  }),

  platformToDeleteSuccces: (platformIdToDelete: number) => ({
    type: PLATFORM_DELETE_SUCCESS,
    payload: {platformIdToDelete},
  }),

  platformToDeleteFail: () => ({type: PLATFORM_DELETE_FAIL}),

  changeCurrentPage: (newPage: number) => ({
    type: CHANGE_CURRENT_PAGE,
    payload: {currentPage: newPage},
  }),

  changeLimitPerPage: (newLimit: number) => ({
    type: CHANGE_LIMIT_PER_PAGE,
    payload: {perPage: newLimit},
  }),

  changeFilter: (newFilter: string) => ({
    type: CHANGE_FILTER,
    payload: {filter: newFilter},
  }),

  changeTotalItem: (newTotalItem: number) => ({
    type: CHANGE_TOTAL_ITEM,
    payload: {totalItem: newTotalItem},
  }),

  changeSearchText: (searchText: string) => ({
    type: CHANGE_SEARCH_TEXT,
    payload: {searchText},
  }),
}

export const selectors = {
  getPlatforms: (state: RootState) => state.platforms,
  getCurrentPage: (state: RootState) => state.platforms.currentPage,
  getLimitPerPage: (state: RootState) => state.platforms.perPage,
  getFilter: (state: RootState) => state.platforms.filter,
  getTotalItem: (state: RootState) => state.platforms.totalItem,
  getSearchText: (state: RootState) => state.platforms.searchText,
  getPlatformIDToDelete: (state: RootState) => state.platforms.platformIdToDelete,
  getPlatformToCreate: (state: RootState) => state.platforms.createNewPlatform,
  getCreatePlatformData: (state: RootState) => {
    const {
      createPlatformLoading,
      createPlatformError,
      createPlatformErrorMessage,
      platformIsCreate,
    } = state.platforms
    return {
      createPlatformLoading,
      createPlatformError,
      createPlatformErrorMessage,
      platformIsCreate,
    }
  },
  getUpdatePlatformData: (state: RootState) => {
    const {
      platformUpdateLoading,
      platformUpdateError,
      platformUpdateErrorMessage,
      platformIsUpdate,
    } = state.platforms
    return {
      platformUpdateLoading,
      platformUpdateError,
      platformUpdateErrorMessage,
      platformIsUpdate,
    }
  },
  getPlatformToUpdateId: (state: RootState) => state.platforms.getPlatformToUpdateId,
  getPlatformToUpdateData: (state: RootState) => state.platforms.getPlatformToUpdateData,
  getPlatformDataToUpdate: (state: RootState) => state.platforms.platformUpdateData,
}

function* listUpdate() {
  const searchValue: string = yield select(selectors.getSearchText)
  const page: number = yield select(selectors.getCurrentPage)
  const limit: number = yield select(selectors.getLimitPerPage)
  const filter: string = yield select(selectors.getFilter)
  const {data} = yield getPlatformsList(page, limit, filter, searchValue)
  return {data}
}

export function* saga() {
  yield takeLatest(PLATFORMS_REQUEST, function* getPlatformsSage() {
    try {
      const {data} = yield listUpdate()
      yield put(
        actions.requestPlatformsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } catch (error) {
      yield put(actions.requestPlatformFail())
    }
  })

  yield takeLatest(PLATFORM_DELETE, function* deletePlatformSaga() {
    try {
      const delPlatform: number = yield select(selectors.getPlatformIDToDelete)
      yield deletePlatform(delPlatform)
      yield put(actions.platformToDeleteSuccces(delPlatform))
    } catch (error) {
      yield put(actions.platformToDeleteFail())
    }
  })

  yield takeLatest(PLATFORM_CREATE_REQUEST, function* createPlatformSaga() {
    const newPlatform: IPlatform = yield select(selectors.getPlatformToCreate)
    const {res, err} = yield createPlatform(newPlatform)
    if (res) {
      yield put(actions.createNewPlatform())
      const {data} = yield listUpdate()
      yield put(
        actions.requestPlatformsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.createPlatformFail(errResArray))
      } else {
        yield put(actions.createPlatformFail([[err.response.data.message]]))
      }
    }
  })

  yield takeLatest(GET_PLATFORM_TO_UPDATE, function* getUpdatePlatformSaga() {
    try {
      const updPlatformId: number = yield select(selectors.getPlatformToUpdateId)
      const {data} = yield getPlatformById(updPlatformId)
      yield put(actions.requestPlatformToUpdateData(data))
    } catch (err) {
      yield put(actions.requestPlatformToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_PLATFORM_UPDATE_REQUEST, function* updatePlatformSaga() {
    const platform: IPlatform = yield select(selectors.getPlatformDataToUpdate)
    const {res, err} = yield updatePlatform(platform)
    if (res) {
      yield put(actions.updatePlatformDataSuccess())
      const {data} = yield listUpdate()
      yield put(
        actions.requestPlatformsSuccess(
          data.data,
          data.current_page,
          data.last_page,
          data.per_page,
          data.total
        )
      )
    } else if (err) {
      if (err.response.status === 422) {
        const errorArray: any = Object.values(err.response.data)
        const errRes: IErrorResponse = errorArray[1]
        const errResArray = Object.values(errRes)
        yield put(actions.requestPlatformToUpdateFail(errResArray))
      } else {
        yield put(actions.requestPlatformToUpdateFail([[err.response.data.message]]))
      }
    }
  })
}
