import axios from 'axios'
import {IPriceList} from '../models/PriceList'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const PRICE_LIST_API = `${API_URL}/price_lists`

export async function getPriceLists(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${PRICE_LIST_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${PRICE_LIST_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getPriceListById(priceListId: number) {
  return await axios.get(`${PRICE_LIST_API}/${priceListId}`)
}

export async function deletePriceList(priceListId: number) {
  return await axios.delete(`${PRICE_LIST_API}/${priceListId}`)
}

export async function updatePriceList(updatePriceList: IPriceList) {
  return await axios
    .put(`${PRICE_LIST_API}/${updatePriceList.id}`, updatePriceList)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createPriceList(newPriceList: IPriceList) {
  return await axios
    .post(`${PRICE_LIST_API}`, newPriceList)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
