import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import BudgetForecastPage from '../../modules/budgetForecasts/BudgetForecastPage'

const BudgetForecastsWrapper: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'BUDGET_FORECASTS'})}</PageTitle>
      <BudgetForecastPage />
    </>
  )
}

export default BudgetForecastsWrapper
