import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { actions as promptActions } from '../../redux/prompts/PromptRedux'
import { actions as googleCategoryActions, selectors as googleCategorySelectors } from '../../redux/googleCategories/GoogleCategoryRedux'
import { actions as attributeActions, selectors as attributeSelectors } from '../../redux/attributes/AttributeRedux'
import { AlertContext } from '../../../../../_metronic/layout/core/AlertContext'
import { RootState } from '../../../../../setup'
import {useIntl} from "react-intl"

interface EditPromptModalProps {
    show: boolean
    handleClose: () => void
    promptId: number
    initialPromptText: string
    initialAttributeId: number
    initialIndustryId: number | null
    initialGlobal: boolean
}

const EditPromptModal: React.FC<EditPromptModalProps> = ({
                                                             show,
                                                             handleClose,
                                                             promptId,
                                                             initialPromptText,
                                                             initialAttributeId,
                                                             initialIndustryId,
                                                             initialGlobal,
                                                         }) => {
    const dispatch = useDispatch()
    const { showAlert } = useContext(AlertContext)

    const [promptText, setPromptText] = useState<string>(initialPromptText)
    const [attributeId, setAttributeId] = useState<number | ''>(initialAttributeId)
    const [industryId, setIndustryId] = useState<number | null>(initialIndustryId)
    const [global, setGlobal] = useState<boolean>(initialGlobal)

    const googleCategories = useSelector((state: RootState) => googleCategorySelectors.getGoogleCategories(state))
    const attributes = useSelector((state: RootState) => attributeSelectors.getAttributes(state))
    const intl = useIntl()

    useEffect(() => {
        dispatch(googleCategoryActions.requestGoogleCategories())
        dispatch(attributeActions.requestAttributes())
        setIndustryId(initialIndustryId)
        setAttributeId(initialAttributeId)
        setGlobal(initialGlobal)
    }, [dispatch, initialIndustryId, initialAttributeId, initialGlobal])

    const handleUpdatePrompt = () => {
        if (!promptText || !attributeId || (!global && industryId === null)) {
            showAlert([[intl.formatMessage({ id: 'ALL_FIELDS_REQ' })]], 'danger', true)
            return
        }

        const updatedData = {
            industry_id: global ? null : industryId,
            prompt_text: promptText,
            attribute_id: attributeId,
            global,
        }
        dispatch(promptActions.updatePrompt(promptId, updatedData))
        showAlert([[intl.formatMessage({ id: 'EDIT.PROMPT.SUCCESS' })]], 'success', true, intl.formatMessage({ id: 'SUCCESS' }))
        handleClose()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'EDITING.PROMPT' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={intl.formatMessage({ id: 'GLOBAL' })}
                            checked={global}
                            onChange={(e) => {
                                setGlobal(e.target.checked)
                                if (e.target.checked) setIndustryId(null)
                            }}
                        />
                    </Form.Group>

                    {!global && (
                        <Form.Group className="mb-3">
                            <Form.Label>{intl.formatMessage({ id: 'INDUSTRY' })}</Form.Label>
                            <Form.Control
                                as="select"
                                value={industryId || ''}
                                onChange={(e) => setIndustryId(Number(e.target.value))}
                            >
                                <option value="">{intl.formatMessage({ id: 'CHOOSE_INDUSTRY' })}</option>
                                {googleCategories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage({ id: 'PROMPT_TEXT' })}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={promptText}
                            onChange={(e) => setPromptText(e.target.value)}
                            placeholder={intl.formatMessage({ id: 'PROVIDE.PROMPT.TEXT' })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage({ id: 'ATTRIBUTE' })}</Form.Label>
                        <Form.Control
                            as="select"
                            value={attributeId}
                            onChange={(e) => setAttributeId(Number(e.target.value))}
                        >
                            <option value="">{intl.formatMessage({ id: 'CHOOSE_ATTRIBUTE' })}</option>
                            {attributes.map((attribute) => (
                                <option key={attribute.id} value={attribute.id}>
                                    {attribute.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant="primary" onClick={handleUpdatePrompt}>
                    {intl.formatMessage({ id: 'SAVE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditPromptModal
