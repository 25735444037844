import {
  ActionWithDefinePayload,
  ActionWithPayload,
  RootState,
} from '../../../../setup/redux/RootReducer'
import {IProject} from '../../projects/models/Project'
import {put, takeLatest} from 'redux-saga/effects'
import {getUserProjectsList} from './UserProjectsCRUD'

export const USER_PROJECTS_REQUEST = 'USER_PROJECTS_REQUEST'
export const USER_PROJECTS_REQUEST_SUCCESS = 'USER_PROJECTS_REQUEST_SUCCESS'
export const USER_PROJECTS_REQUEST_FAIL = 'USER_PROJECTS_REQUEST_FAIL'

export interface IUserProjectState {
  getUserProjects?: IProject[]
  getUserProjectsLoading: boolean
  getUserProjectsError: boolean
  currentPage?: number
}

const initialUserProjectState: IUserProjectState = {
  getUserProjects: [],
  getUserProjectsLoading: false,
  getUserProjectsError: false,
  currentPage: 1,
}

export const reducer = (
  state: IUserProjectState = initialUserProjectState,
  action: ActionWithPayload<IUserProjectState>
) => {
  switch (action.type) {
    case USER_PROJECTS_REQUEST:
      return {
        ...state,
        getUserProjectsLoading: true,
      }
    case USER_PROJECTS_REQUEST_SUCCESS:
      const projects = action.payload?.getUserProjects
      return {
        ...state,
        getUserProjects: projects,
        getUserProjectsLoading: false,
        getUserProjectsError: false,
      }
    case USER_PROJECTS_REQUEST_FAIL:
      return {
        ...state,
        error: true,
      }
    default:
      return state
  }
}

export const actions = {
  requestUserProjects: (clientId: string) => ({
    type: USER_PROJECTS_REQUEST,
    payload: {clientId},
  }),
  requestUserProjectsSuccess: (projects: IProject[]) => ({
    type: USER_PROJECTS_REQUEST_SUCCESS,
    payload: {getUserProjects: projects},
  }),
  requestUserProjectsFail: () => ({
    type: USER_PROJECTS_REQUEST_FAIL,
  }),
}

export const selectors = {
  getUserProjects: (state: RootState) => state.userProjects,
}

function* listUpdate(clientId: string) {
  const {data} = yield getUserProjectsList(clientId)
  return {data}
}

export function* saga() {
  yield takeLatest(
    USER_PROJECTS_REQUEST,
    function* getUserProjectsSaga(action: ActionWithDefinePayload<{clientId: string}>) {
      try {
        const {data} = yield listUpdate(action.payload.clientId)

        yield put(actions.requestUserProjectsSuccess(data.data))
      } catch (error) {
        yield put(actions.requestUserProjectsFail())
      }
    }
  )
}
