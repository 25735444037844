import { ActionWithPayload, RootState } from '../../../../setup/redux/RootReducer'
import { getTransactionList } from "./TransactionCRUD";
import { ITransaction } from "../models/Transaction";
import { put, takeLatest } from 'redux-saga/effects'


export const TRANSACTION_REQUEST = 'TRANSACTION_REQUEST'
export const TRANSACTION_REQUEST_SUCCESS = 'TRANSACTION_REQUEST_SUCCESS'
export const TRANSACTION_REQUEST_FAIL = 'TRANSACTION_REQUEST_FAIL'

export interface ITransactionState {
    transactions: ITransaction[],
    loading: boolean
    error: boolean
}

const initialState: ITransactionState = {
    transactions: [],
    loading: false,
    error: false,
}

export const reducer = (
    state: ITransactionState = initialState,
    action: ActionWithPayload<ITransactionState>
) => {
    switch (action.type) {
        case TRANSACTION_REQUEST:
            return { ...state, loading: true, error: false }
        case TRANSACTION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                transactions: action.payload?.transactions || []
            }
        case TRANSACTION_REQUEST_FAIL:
            return { ...state, loading: false, error: true }
        default:
            return state
    }
}

export const actions = {
    requestTransactions: () => ({ type: TRANSACTION_REQUEST }),
    requestTransactionsSuccess: (
        transactions: ITransaction[],
    ) => ({
        type: TRANSACTION_REQUEST_SUCCESS,
        payload: { transactions },
    }),
    requestTransactionsFail: () => ({ type: TRANSACTION_REQUEST_FAIL }),
}

export const selectors = {
    getTransactionsState: (state: RootState) => state.transactions,
}

export function* saga() {
    yield takeLatest(TRANSACTION_REQUEST, function* fetchTransactionsSaga() {
        try {
            const { data } = yield getTransactionList()
            yield put(
                actions.requestTransactionsSuccess(
                    data
                )
            )
        } catch (error) {
            yield put(actions.requestTransactionsFail())
        }
    })
}