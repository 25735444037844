import React from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'

interface ErrorViewProps {}

const ErrorView: React.FC<ErrorViewProps> = () => {
  const intl = useIntl()
  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/')
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='pt-lg-10 d-flex flex-column justify-content-center align-items-center'>
        <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Ups...</h1>

        <div className='fw-bold fs-3 text-gray-400 mb-15 text-center'>
          {intl.formatMessage({id: 'ERROR.SMTH_WRONG'})}
          <br />
          {intl.formatMessage({id: 'TRY_AGAIN'})}
        </div>
        <div className='text-center'>
          <button onClick={redirectToDashboard} className='btn btn-lg btn-primary fw-bolder'>
            {intl.formatMessage({id: 'LINK_TO_HOMEPAGE'})}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ErrorView
