import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Select, { MultiValue } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { actions as attributeActions, selectors as attributeSelectors } from '../../redux/attributes/AttributeRedux'
import { IAttribute } from '../../models/Attribute'
import { useIntl } from "react-intl"

interface EditCategoryModalProps {
    show: boolean
    handleClose: () => void
    categoryId: number
    categoryName: string
    categoryAttributes: IAttribute[]
    onSave: (industry_id: number, attribute_ids: number[]) => void
}

interface OptionType {
    label: string
    value: number
}

const EditGoogleCategoryModal: React.FC<EditCategoryModalProps> = ({
                                                                       show,
                                                                       handleClose,
                                                                       categoryId,
                                                                       categoryName,
                                                                       categoryAttributes,
                                                                       onSave,
                                                                   }) => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const attributes = useSelector((state: RootState) => attributeSelectors.getAttributes(state))
    const [selectedAttributes, setSelectedAttributes] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(attributeActions.requestAttributes())
    }, [dispatch])

    useEffect(() => {
        const selectedOptions = categoryAttributes
            .filter(attr => attr.id !== undefined && !attr.global)
            .map(attr => ({ label: attr.name, value: attr.id as number }))
        setSelectedAttributes(selectedOptions)
    }, [categoryAttributes])

    const attributeOptions: OptionType[] = attributes
        .filter(attr => attr.id !== undefined && !attr.global)
        .map(attr => ({ label: attr.name, value: attr.id as number }))

    const handleSave = () => {
        const attributeIds = selectedAttributes
            .filter(attr => {
                const fullAttr = attributes.find(a => a.id === attr.value)
                return fullAttr && !fullAttr.global
            })
            .map(attr => attr.value)

        onSave(categoryId, attributeIds)
        handleClose()
    }

    const handleChange = (newValue: MultiValue<OptionType>) => {
        setSelectedAttributes(newValue as OptionType[])
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'REQUIRED.ATTRIBUTES.INDUSTRY' })}: {categoryName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage({ id: 'CHOOSE_ATTRIBUTES' })}</Form.Label>
                        <Select
                            isMulti
                            options={attributeOptions}
                            value={selectedAttributes}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({ id: 'CHOOSE_ATTRIBUTES' })}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    {intl.formatMessage({ id: 'SAVE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditGoogleCategoryModal
