import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const INVOICE_API = `${API_URL}/invoices`

export async function getInvoicesList(
  type: string,
  clientId: number,
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${INVOICE_API}?type=${type}&clientId=${clientId}&search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(
    `${INVOICE_API}?type=${type}&clientId=${clientId}&page=${page}&per-page=${perPage}&${filter}`
  )
}

export async function getPdf(invoiceId: number, documentType: string) {
  return await axios.get(`${INVOICE_API}/pdf/${invoiceId}/${documentType}`)
}

export async function createInvoiceFromProforma(proformaNumber: string, proformaId: number) {
  return await axios.get(`${INVOICE_API}/create/invoice/${proformaNumber}/${proformaId}`)
}
